import React from 'react';

const Paginate = ({ table }) => {
  let totalRows;
  const [maxRows, setMaxRows] = React.useState(10);
  const [currentPage, setCurrentPage] = React.useState(1);

  const handleMaxRowsChange = (e) => {
    setMaxRows(parseInt(e.target.value));
  };

  const handlePageChange = (e) => {
    setCurrentPage(parseInt(e.target.getAttribute('data-page')));
  };

  const getPagination = () => {
    totalRows = table.current.querySelector('tbody').children.length;
    const pageCount = Math.ceil(totalRows / maxRows);

    const pagination = [];
    for (let i = 1; i <= pageCount; i++) {
      pagination.push(
        <li key={i} data-page={i}>
          <span>{i}</span>
        </li>
      );
    }

    return pagination;
  };

  const showRowsCount = () => {
    const startRowIndex = ((maxRows * currentPage) - maxRows) + 1;
    const endRowIndex = Math.min(maxRows * currentPage, totalRows);

    return (
      <span>
        Showing {startRowIndex} to {endRowIndex} of {totalRows} entries
      </span>
    );
  };

  const render = () => {
    return (
      <div classNameName="pagination">
        <select id="maxRows" value={maxRows} onChange={handleMaxRowsChange}>
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>

        <ul>{getPagination()}</ul>

        {showRowsCount()}
      </div>
    );
  };

  return <div>{render()}</div>;
};

export default Paginate;
