import React from "react";
import { Nav } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const CheckoutSteps = ({ step1, step2, step3, step4 }) => {
  return (
    <Nav classNameName="justify-content-center mb-4">
      <Nav.Item>
        {step1 ? (
          <LinkContainer
            to="/login"
            style={{ color: "orange", fontSize: "16px" }}
          >
            <Nav.Link>Sign In</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled style={{ color: "orange", fontSize: "16px" }}>
            Sign In
          </Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step2 ? (
          <LinkContainer
            to="/shipping"
            style={{ color: "orange", fontSize: "16px" }}
          >
            <Nav.Link>Personal Details</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled style={{ fontSize: "16px" }}>
            Personal Details
          </Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step3 ? (
          <LinkContainer
            to="/payment"
            style={{ color: "orange", fontSize: "16px" }}
          >
            <Nav.Link>Payment</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled style={{ fontSize: "16px" }}>
            Payment
          </Nav.Link>
        )}
      </Nav.Item>

      <Nav.Item>
        {step4 ? (
          <LinkContainer
            to="/placeorder"
            style={{ color: "orange", fontSize: "16px" }}
          >
            <Nav.Link>Place Order</Nav.Link>
          </LinkContainer>
        ) : (
          <Nav.Link disabled style={{ fontSize: "16px" }}>
            Place Order
          </Nav.Link>
        )}
      </Nav.Item>
    </Nav>
  );
};

export default CheckoutSteps;
