import React, { useState } from "react";
import styled from "styled-components";
import Card from "./CCard"
import VisuallyHiddenText from "./VisuallyHiddenText";

export default function Carousel({ slides }) {
    const [activeSlide, setActiveSlide] = useState(0);
    const next = () => {
      activeSlide !== slides.length - 1
        ? setActiveSlide(activeSlide + 1)
        : setActiveSlide(0);
    };
    const back = () => {
      activeSlide !== 0
        ? setActiveSlide(activeSlide - 1)
        : setActiveSlide(slides.length - 1);
    };
  
    const goToSlide = (i) => {
      setActiveSlide(i);
    };
  
    return (
      <SliderWrapper role="region" aria-label="carousel">
        <ContentWrapper>
          <ButtonsWrapper>
            <VisuallyHiddenText>
              Use the buttons to go to the next or previous slide or to select a
              specific slide from the carousel of content.
            </VisuallyHiddenText>
            <PrevNextButtonsWrapper>
              <ArrowButton onClick={back}>
                <VisuallyHiddenText>Previous slide</VisuallyHiddenText>
                <LeftArrow />
              </ArrowButton>
              <ArrowButton onClick={next} label="next">
                <VisuallyHiddenText>Next slide</VisuallyHiddenText>
                <RightArrow />
              </ArrowButton>
            </PrevNextButtonsWrapper>
            <IndexButtonsWrapper>
              {slides.map((slide, index) => (
                <li key={`circle-button-${index}`}>
                  <CircleButton
                    onClick={() => goToSlide(index)}
                    activeCircle={index === activeSlide}
                    aria-current={index === activeSlide}
                  >
                    <VisuallyHiddenText>
                      Go to slide {index + 1}.
                    </VisuallyHiddenText>
                  </CircleButton>
                </li>
              ))}
            </IndexButtonsWrapper>
          </ButtonsWrapper>
          <TextWrapper>
            <h2>{slides[activeSlide].title}</h2>
            <p>{slides[activeSlide].text}</p>
          </TextWrapper>
        </ContentWrapper>
        <SlidesWrapper>
          {slides.map((slide, i) => (
            <div
              key={`slide-${i}`}
              style={{ transform: `translateX(-${activeSlide * 100}%)` }}
              aria-hidden={activeSlide !== i}
              role="group"
              aria-label={`slide ${i + 1} of ${slides.length}`}
            >
              <Card
                image={slide.image}
                url={slide.url}
                isActive={i === activeSlide}
              />
            </div>
          ))}
        </SlidesWrapper>
      </SliderWrapper>
    );
  }
  
  const SliderWrapper = styled.div`
    background-image: linear-gradient( 97.3deg,  rgba(25,50,70,0.81) 10.7%, rgba(250, 81, 9, 0.72) 39.5%, rgba(255,192,0,0.81) 69.7% );
    display: grid;
    color: white;
    padding: 2rem 0 2rem 1rem;
    @media (min-width: 850px) {
      grid-auto-flow: column;
      grid-template-columns: 2fr 3fr;
      padding: 3rem 0 3rem 5%;
    }
  `;
  
  const ContentWrapper = styled.div`
    margin-bottom: 1rem;
    @media (min-width: 850px) {
      margin-bottom: 0;
      margin-right: 2rem;
    }
  `;
  
  const ButtonsWrapper = styled.div`
    display: grid;
    @media (min-width: 850px) {
      grid-auto-flow: column;
      grid-template-columns: 1fr 1fr;
    }
  `;
  
  const PrevNextButtonsWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 200px;
    margin-bottom: 2rem;
    @media (min-width: 850px) {
      margin-bottom: 0;
    }
  `;
  
  const IndexButtonsWrapper = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-around;
    max-width: 250px;
    @media (min-width: 850px) {
      margin-left: 2rem;
    }
  `;
  
  const ArrowButton = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    background-color: orange;
    height: 60px;
    border: 3px solid white;
    border-radius: 50%;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
  `;
  
  const RightArrow = styled.i`
    display: inline-block;
    padding: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
  `;
  
  const LeftArrow = styled.i`
    display: inline-block;
    padding: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  `;
  
  const CircleButton = styled.button`
    margin-right: 20px;
    border: 3px solid purple;
    width: ${(props) => (props.activeCircle ? "30px" : "20px")};
    height: ${(props) => (props.activeCircle ? "30px" : "20px")};
    border-radius: 15px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    background-color: ${(props) => (props.activeCircle ? "orange" : "white")};
  `;
  
  const TextWrapper = styled.div`
    display: grid;
    align-items: center;
    margin-top: 1rem;
  `;
  
  const SlidesWrapper = styled.div`
    display: flex;
    flex-direction: row;
    overflow: hidden;
    align-items: center;
  
    li {
      white-space: nowrap;
      transition: transform 0.3s;
    }
  `;
  