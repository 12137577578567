import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { toast, Toaster } from "react-hot-toast";
import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Services,
} from "../containers";
import assets from "../testdata"
import { CTA, Brand, Navbar, Minerals } from "../components";
import { useUserContext } from "../context/userContext";
import Loader from "../components/Loader";
import GrowthSeeking from "../components/growthSeeking/growthSeeking";
import "./css/garlpex.css";
import closebutton from '../assets/close_button.png';
import { useAuth0 } from '@auth0/auth0-react';

// import gpt3Logo from '../assets/logo1.png';


const VideoCard = ({ videoSrc, videoTitle, videoDescription }) => {
  return (
    <div className="videocard">
      <div className="video">
        <div className="video__container">
          <iframe className="video__embed" src={videoSrc} frameborder="0" allowfullscreen></iframe>
          <a href={videoSrc} className="video__placeholder" target="_blank"></a>
        </div>
      </div>
      <div className="videocard__info">
        <h1 className="videocard__title">{videoTitle}</h1>
        <p className="videocard__description">{videoDescription}</p>
      </div>
    </div>
  );
};

//Cards
const TruncatedCard = ({ imageUrl, title, price, content }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <li className="expandablecards_item">
      <div className="expandablecard">
        <div className="expandablecard_image"><img src={imageUrl} className="expandableImage" alt={title} /></div>
        <div className="expandablecard_content">
          <h2 className="expandablecard_title">{title} <span className="orange" style={{ color: "#496989" }}>&#x2022; {price}</span></h2>
          <div className="expandablecard_text">
            {isExpanded ? (
              <div style={{ color: "#444444" }}>{content}</div>
            ) : (
              <p style={{ color: "#444444" }}>{content.slice(0, 135)}...</p>
            )}
          </div>
          <button className="expandablecard_btn orange" onClick={toggleExpand} style={{ color: "#496989" }}>
            {isExpanded ? 'See less -' : 'See more +'}
          </button>
        </div>
      </div>
    </li>
  );
};


function SocialMediaLinks() {
  const socialMediaItems = [
    { name: 'Facebook', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Twitter', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Instagram', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Snapchat', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Skype', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'LinkedIn', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Periscope', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
    { name: 'Reddit', iconUrl: 'https://img.freepik.com/free-vector/document-vector-colorful-design_341269-1262.jpg?t=st=1712942230~exp=1712945830~hmac=0aec210c1bfa868eca16efe8b8f0fc62610f71db6d4f09360010b96d506431e5&w=1060' },
  ];

  return (
    <div className="gsacontainer">
      {socialMediaItems.map((item, index) => (
        <div className="gsaitem" key={index}>
          <a href="#">
            <div className="gsaitem-image">
              <img src={item.iconUrl} width="64" height="64" alt={`${item.name} free icon`} title={`${item.name} free icon`} />
            </div>
            <div className="gsaitem-text">{item.name}</div>
            <div className="gsaitem-link-desc">Visit Site</div>
          </a>
        </div>
      ))}
    </div>
  );
}

const GarlpexScreen = () => {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [purchaseCoupons, setPurchaseCoupons] = useState(true)
  const [paymentMethod, setPaymentMethod] = useState(false)
  const [placeOrder, setPlaceOrder] = useState(false)
  const [quantity, setQuantity] = useState(1);
  const [price, setPrice] = useState(12);
  const [totalPrice, setTotalPrice] = useState(0)
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [address, setAddress] = useState();
  const [phone, setPhone] = useState();
  const [orderID, setOrderID] = useState();
  const [pageLoad, setPageLoad] = useState(false)
  const [growthAssetID, setGrowthAssetID] = useState("0")

  const [open, setOpen] = React.useState(false);
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [balance, setBalance] = React.useState(0)
  const [personalData, setPersonalData] = React.useState("none")
  const Modal = ({ open, setOpen, AssetId }) => {
    if (AssetId === undefined) {
      setOpen(false)
      return null
    }

    console.log("Modal ID " + AssetId)
    const foundAsset = assets.find(asset => asset.id === AssetId);
    if (foundAsset === undefined) {
      setOpen(false)
      return null
    }
    if (AssetId !== "0") {
      return (
        <div className={` overlay animated ${open ? "show" : ""}`}>
          <div className="modalll">
            <div style={{ width: "50px", height: "50px", position: "sticky", top: 2 }}>
              <img src={closebutton} onClick={() => setOpen(false)} style={{ width: "50px", height: "50px" }} />
            </div>
            {/* <svg
              onClick={() => setOpen(false)}
              height="200"
              viewBox="0 0 200 200"
              width="200"
              style={{position: "sticky"}}
            >
              <title />
              <path d="M114,100l49-49a9.9,9.9,0,0,0-14-14L100,86,51,37A9.9,9.9,0,0,0,37,51l49,49L37,149a9.9,9.9,0,0,0,14,14l49-49,49,49a9.9,9.9,0,0,0,14-14Z" />
            </svg> */}
            <Header
              heading={foundAsset.name}
              content={foundAsset.description}
              image={foundAsset.profileImage}
            />
            <VideoCard
              videoSrc={foundAsset.youtubesrc}
              videoTitle={foundAsset.youtubeTitle}
              videoDescription={foundAsset.youtubeDescription}
            />
            <h1 style={{ color: "orange", textAlign: "center", margin: "20px" }}>PICK A COMMODITY FOR SWAP WITH MEGAJOULES</h1>
            <Minerals commodities={foundAsset.swappingAssets} />
          </div>
        </div>
      )
    } else {
      setOpen(false)
      return null
    }
  };


  const fetchData = async () => {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`/identification`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const data = await response.json();
        console.log('Fetching data:', data);

        setBalance(data.updatedUser.megajoule)
        setPersonalData(data.updatedUser);
        if (data.message === 'No Phone') {
          console.log("No phone has be found")
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      }
    }
  };


  const fetchAgreement = async (personalDat, agreement) => {
    setPageLoad(true)
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently();
        const response = await fetch(`/api/docusign`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ user: personalDat, agreement: agreement }),
        });

        const data = await response.json();
        console.log('Url =>:', data.url);

        if (data.url != undefined) {
          setPageLoad(false);
          window.location.replace(`${data.url}`);
        } else {
          alert('Failed to redirect');
          setPageLoad(false);
        }
      } catch (error) {

        setPageLoad(false);
        console.error('Error fetching user data:', error.message);
      }
    }
  };
  const applyforCrop = (application) => {
    // fetchData();
    fetchAgreement(user.email, application);
  }

  const toggleModal = (id) => {
    setGrowthAssetID(id.toString());
  }


  useEffect(() => {
    console.log("Growth Asset ID:", growthAssetID);
    setOpen(true)
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, [growthAssetID]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageLoad(true);
    setPageLoad(false);
  }, []);

  console.log("Total Price " + totalPrice);

  return (
    <>
      {pageLoad && (<Loader />)}
      {!pageLoad && (
        <>
          <div className="gradieant__bg">
            <Navbar />
            <Header
              heading="Growth Seeking Assets"
              content="Welcome to CROP - Your Premier Platform for Commodities Rental with Option to Purchase! Are you a mine owner or growth seeking asset seeking to maximize your asset's potential? Or perhaps you're in the market for machinery to enhance your growth seeking asset operations? Look no further than CROP, where we specialize in facilitating mutually beneficial exchanges between growth seeking assets and machinery providers."
              image="garlpex"
            />
          </div>
          <Toaster toastOptions={{ duration: 4000 }} />
          {!open && (
            <>
              <Possibility
                subheading="CROP"
                heading="Commodities Rental with Option to Purchase"
                content="Gain access to essential machinery to supercharge your mining or growth seeking asset operations. Our platform offers flexible rental options with the added benefit of purchasing the machinery at a later date. Maximize your mine's or growth seeking asset's growth potential while maintaining control over your asset strategy. It isn't just about accessing cutting-edge machinery or lucrative mining opportunities - it's also about connecting with a vibrant community of industry professionals, investors, and enthusiasts. Our platform facilitates networking and collaboration, allowing you to forge valuable connections, share insights, and stay informed about the latest trends and developments in the mining sector."
                image="https://cdn.pixabay.com/photo/2024/03/11/07/43/ai-generated-8626041_1280.png"
              />

              <h1 style={{ color: "white", textAlign: "center", fontSize: "35px" }}>APPLY CROP FOR</h1>

              <div className="cardminerals-list" style={{ width: "80%", marginLeft: "auto", marginRight: "auto", marginBottom: "50px" }}>
                {isAuthenticated ? (
                  <>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                      onClick={() => applyforCrop("gold")}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          GOLD MINE

                        </p>
                      </div>
                    </div>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                      onClick={() => applyforCrop("chrome")}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          CHROME MINE

                        </p>
                      </div>
                    </div>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                      onClick={() => applyforCrop("lithium")}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          LITHIUM MINE

                        </p>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          GOLD MINE

                        </p>
                      </div>
                    </div>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          CHROME MINE

                        </p>
                      </div>
                    </div>
                    <div
                      className="cardminerals 1"
                      style={{ marginBottom: "120px" }}
                    >
                      <div className="card_image">
                        <img src="https://img.freepik.com/free-vector/attached-files-concept-illustration_114360-4373.jpg?t=st=1713286526~exp=1713290126~hmac=5b422d88aedfc30ca13716328e50aac2fd13a61e95277cb9d70e8c6bab3913bb&w=1060" />
                      </div>
                      <div className="card_title title-white">
                        <p
                          style={{
                            color: "#FFAF45",
                            textAlign: "center",
                            marginTop: "10px"
                          }}
                        >
                          LITHIUM MINE

                        </p>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <Blog
                dataB={assets}
                heading="Registered Growth Seeking Assets"
                toggleModal={toggleModal}
              />
            </>
          )}
          <Modal open={open} setOpen={setOpen} AssetId={growthAssetID} />
          {!open && (
            <Footer />
          )}
        </>
      )}
    </>
  );
};

export default GarlpexScreen;
