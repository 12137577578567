import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast, Toaster } from "react-hot-toast";
import SyndicateContext from "../context/syndicateContext";
import PhoneInput from "react-phone-input-2";
import Loader from "./Loader";
import {storage} from '../firebase/index';
import {ref, uploadBytes, getDownloadURL} from 'firebase/storage'

const Assets = ({ userPhone, close, setclose, showMenu }) => {
    const [name, setName] = useState("");
    const {
        syndicates,
        findSyndicates,
        creatteSyndicate,
        createSuccess,
        errorMessage,
        resetSyndicateState,
        loading,
        setLoading,
        dashboardData,
        path,
        setPath,
        getAgent,
        agent,
        addToSyndicatte,
        deleteAgent,
        setDashboard,
        addStocks,
        deleteStock,
        addProducts
    } = useContext(SyndicateContext);

    const [refineries, setRefineries] = useState(dashboardData.refineries);
    const [addStock, setStock] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [delete_id, setDeleteId] = useState(null)
    

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const results = dashboardData.refineries.filter((refinery) => {
                return refinery.refinedItem.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setRefineries(results);
        } else {
            setRefineries(dashboardData.refineries);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    const handleAddStock = () => {
        setStock(true);
    };

    const handleCloseModal = () => {
        setStock(false);
        setModalDelete(false);
    };

    //Show User
    const ShowAssetStockModal = ({ modalOpen, handleCloseModal, data }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)
        const [product, setProduct] = useState(null)
        const [itemCategory, setItemCategory] = useState(null)
        const [megajoule_equivalnce, setEquivalance] = useState(null)
        const [quantity, setQuantity] = useState(null)
        const [description, setDescription] = useState(null)
        const [image, setImage] =useState(null);
        const [urll, setUrl] =useState(null)


        if (!modalOpen) {
            return null;
        }

        const addProduct = ()=>{
            if(product === null || itemCategory === null || quantity === null || description === null){
                toast.error("Fill in empty fields") 
            }else{
                if(urll === null){
                    toast.error("Provide an Image") 
                }else{
                    addProducts(dashboardData.syndicatAccount,userPhone,product, itemCategory, quantity, description, urll, megajoule_equivalnce)
                    toast.success("Added Successfully") 
                    setStock(false)
                }
               
            }
        }

       

        const handleImageChange =(e)=>{
            if(e.target.files[0]){
                setImage(e.target.files[0])
            }
        }
        const handleSubmit =() =>{
            const imageRef = ref(storage, `${dashboardData.syndicatAccount}/assets/${product}`)
            uploadBytes(imageRef, image).then(()=>{
                getDownloadURL(imageRef).then((url)=>{
                    setUrl(url)
                }).catch( error =>{
                    toast.error("error getting the image url")
                });
                setImage(null);

            }).catch( error =>{
                toast.error(error.message)
            })
        }

        const handleProductChange = (e) =>{
            setProduct(e.target.value)
        }

        const handleCategoryChange = (e) =>{
            setItemCategory(e.target.value)
            if(urll === null){
                handleSubmit()
            }
        }
        return (
            <>
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                        {urll && (<img src={urll} alt="product" style={{width: "30%", height:"30%"}} />)}
                            <h1 style={{ color: "orange" }}>Asset Entry</h1>
                            <label for="cars" style={{ fontSize: "14px", color: "white" }}>Product Image:</label>
                            <input type='file' onChange={handleImageChange} style={{color: "black"}} accept="image/*" />
                            <input type='text'
                                style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }}
                                placeholder='Asset Name'
                                value={product}
                                onChange={handleProductChange}
                            />


                            <input type='text' style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }} placeholder='Asset Category' value={itemCategory}
                              onChange={handleCategoryChange}
                            />
                            <label for="cars" style={{ fontSize: "16px", color: "#9ADE7B" }}>Megajoule Equivalant:</label>
                             <input type='number' style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }} placeholder='Megajoule-equivalance' value={megajoule_equivalnce}
                              onChange={(e)=>setEquivalance(e.target.value)}
                            />
                            <input type='number' style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }} placeholder='Quantity'
                              onChange={(e)=>setQuantity(e.target.value)} value={quantity}
                            />
                            <textarea id="decript" name="description" rows="4" cols="50" style={{fontSize: "16px", margin: "2px", width: "98%", color: "black" }}
                              onChange={(e)=>setDescription(e.target.value)} value={description}
                            >
                                Additional Description
                            </textarea>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}
                             onClick={addProduct}
                            >
                                Add
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleCloseModal}>
                                Close
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

   
     //Modal For Assigning Field
     const DeleteUser = ({ modalOpen, handleCloseModal, dataTable }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)

        if (!modalOpen) {
            return null;
        }

        const handleDeleteStock = (e) => {
            
            if (dashboardData.refineries.length === 0) {
                toast.error("Nothing to delete");
            } else {
                deleteStock(delete_id, dashboardData.syndicatAccount, (data)=>{
                    if (data !== null) {
                        toast.success("Delete Succefull");
                        setclose(false)
                        setRefineries(data.refineries);
                        showMenu(true)
                    }
                })
            }
    
        }

        useEffect(() => {
            if (dashboardData) {
                console.log("Found Syndicate :" + dashboardData)
            }
        }, [refineries, dashboardData]);

        return (
            <>
                {loading && (<Loader />)}
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                            <h1 style={{ color: "orange" }}>Are you sure you want to delete?</h1>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(#3ee169, #09656f)", color: "white" }} onClick={handleCloseModal}
                            >
                                No
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleDeleteStock}>
                                Yes
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

    const handleDelete = (e) => {
        setDeleteId(e)
        setModalDelete(true)
    }
    //  ---------------------------- End Delete Modal -------------------------------//

    useEffect(() => {
        if (dashboardData) {
            console.log("Found Syndicate :" + dashboardData)
        }
    }, [refineries, dashboardData]);
    
    return (
        <div classNameName="containerUsers bounce-in-top">

            <div className="wrap">
                <div className="search">
                    <input type="text" value={name} onChange={filter} className="searchTerm" placeholder="Search Assets" />
                    <button type="submit" className="searchButton">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                <button style={{ width: "20px", float: "right", fontSize: "14px", backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", margin: "1px", padding: "3px", marginTop: "30px" }} onClick={close}>X</button>
                <button style={{ width: "100px", fontSize: "14px", float: "left", backgroundImage: "linear-gradient(#3ee169, #09656f)", margin: "1px", padding: "3px" }} onClick={handleAddStock}>New Asset Entry</button>
            </div>
            <Toaster toastOptions={{ duration: 4000 }} />
            <div classNameName="cardGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                <div classNameName="ContainerGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                    <div classNameName="CardStack">
                        {refineries && refineries.length > 0 ? (
                            refineries.map((refinery) => (
                                <div classNameName="Cardgrid" key={refinery._id} style={{marginTop: "70px"}} >
                                    <div classNameName="AvatarWrap">
                                        <img classNameName="Avatar" src={refinery.imgUrl} alt={refinery.refinedItem} style={{width: "200px", height: "100px"}} />
                                    </div>
                                    <div classNameName="Title" style={{fontSize: "17px"}}>{refinery.refinedItem}</div>
                                    <div classNameName="Description">
                                        Category: {refinery.itemCategory} <br />
                                        quantinty: {refinery.quantinty} <br />
                                        <span style={{color: "black"}}>Value: <sup style={{fontSize: "10px", color: "green"}}>MJ</sup><i style={{ color: "green"}}>{refinery.megaEquivalence} </i></span> <br />
                                        Agent Account: {refinery.agentAccount} <br />
                                        Date : {refinery.dateAcquired}
                                    </div>
                                    <div classNameName="Actions">
                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={() => handleDelete(refinery._id)}>
                                            Delete
                                        </a>

                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }} >
                                            Options
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h1 style={{ color: "orange" }}>No results found!</h1>
                        )}


                        <ShowAssetStockModal modalOpen={addStock} handleCloseModal={handleCloseModal} />
                        <DeleteUser modalOpen={modalDelete} handleCloseModal={handleCloseModal} />
                    </div>

                </div>
            </div>
            <button type="button" style={{
                backgroundImage: "linear-gradient(#3ee169, #09656f)",
                width: "40%", fontSize: "17px", marginLeft: "auto", marginRight: "auto"
            }} >Add User</button>
        </div>
    );
}


export default Assets;
