import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";
import { useLocation } from "react-router-dom";
import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Services,
} from "../containers";

import { CTA, Brand, Navbar, CardExpand } from "../components";
// import gpt3Logo from '../assets/logo1.png';
const featuresData = [
  {
    title: "Fast International Trade",
    text:
      "Exchanging Megajoules across borders is as easy as sending them across the street. There are no special limitations on the minimum or maximum megajoules you can send.",
  },
  {
    title: "Trade made Easy",
    text:
      "All trades can be concluded on your mobile phone in real time. Commodity trading only require a phone number with access to GSM short messaging service to perform trade.",
  },
  {
    title: "Works Everywhere anytime",
    text:
      "Megajoule is one of the instruments that's expected to facilitate intra-african trade.",
  },
  {
    title: "Low Cost",
    text: "Megajoule is the easiest way to perform trade at a very low cost.",
  },
];

const AxisData = [
  {
    title: "Buy a Franchise",
    text:
      "Buy a franchise that will get you onto the property investment ladder through our townhouse and factory purchase scheme.",
  },
  {
    title: "Technovation",
    text:
      "Join a team that is commited to manufacture and supply batteries, invetors and photovoltaic cells to generate 1000 MW of energy to power up homes using proprietary 1 KW residential power systems(RPS)",
  },
  {
    title: "Cost",
    text: "Own a Franchise for only MJ 499",
  },
  // {
  //   title: "Low Cost",
  //   text: "Megajoule is the easiest way to perform trade at a very low cost.",
  // },
];

const MegajouleAxisScreen = () => {
  const location = useLocation();
  const data = location.state;
  return (
    <>
      <div classNameName="gradieant__bg">
        <Navbar />
      </div>
      {/* <Brand /> */}
      {/* <CardExpand /> */}
      <div classNameName="body">
        <iframe
          width="791"
          height="445"
          style={{ width: "80%" }}
          src="https://www.youtube.com/embed/C6rv1h7tfd8"
          title="DIY Prepaid Inverter System"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
      <Possibility
        subheading="Request Early Access"
        heading="Smart Irrigation Solution"
        content=" Irrigation in rural areas today is dominated by manually controlled flood and bucket irrigation leading to uneven distribution of water with attendant problems such as hard pans and salinity in ferruginous soils. For  traditionally disadvantaged and emerging commercial farmers financing of irrigation equipment is expensive and a challenge because of theft of components. Farming equipment powered by the national grid is not an option for most  traditionally disadvantaged and emerging commercial farmers in rural settlements as access to the national grid is difficult and very expensive. Agriculture contributes significantly to the development of the African 
        continent hence several governments and other organizations are projecting on expanding agricultural system that will control the funding channels and the functionality of the irrigator unit to be solely dependent on the monthly subscriptions made by farmers to generate revenue for the company and to pay back the loans issued by agricultural development banks, governments and other organizations."
        image="megajouleAgro"
      />
      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Bgfl39G6Dzo?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
      <WhatGPT3
        featureTitle="Mentoring?"
        featureText="We are dedicated to empowering individuals in industries such as agriculture and mining by providing
        mentorship,tools and technologies that will help them succeed. Our goal is to create a community of like-minded individuals who are
        passionate about these industries and want to make a difference. Whether you just starting out or you're
        an experienced professional, we have something for everyone"
        heading="The possibilities are beyond your imagination"
        headingText="Explore services"
        featureTitle_i="Tools"
        featureText_i="After signing up for one of our smart contracts you get access to our tools and begin to participate in
         our ongoing projects."
        featureTitle_ii="Empowerment"
        featureText_ii="Industries like agriculture and mining are two industries that have the pontential to create jobs and 
        generate income for communities. MIAMI provides you with the tools you need to
        succeed in these industries, you can become more self-sufficient and less reliant on outside assistance."
        featureTitle_iii="Energy"
        featureText_iii="A key challenge to development is shortage of electrical energy. On average Zimbabwe generate 30% of the required 2200 MW 
        The balance has to made up by imports or outright loadshading. The unavailable energy means that critical inputs, such as fertilizer, diesel, mechanical, chemical and other inputs
        can not be manufactured or imported timeously. The net result is low productivity and reduced incomes for the populous. MIAMI is therefore commited to harnessing alterantive forms of energy for
        application in appropriate technological environments to inhance productivity."
      />

      <Footer />
    </>
  );
};

export default MegajouleAxisScreen;
