import React, { useEffect } from "react";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { myAccountz } from "../actions/accountActions";

const MyAccountScreen = ({ history }) => {
  const dispatch = useDispatch();

  const myAccount = useSelector((state) => state.myAccount);
  const { loading, error, account } = myAccount;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo) {
      dispatch(myAccountz());
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo]);

  return (
    <>
      <h1> Jew Accounts</h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive classNameName="table-sm">
          <thead>
            <tr>
              <th
                style={{
                  backgroundColor: "green",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                ACCOUNT No
              </th>
              <th
                style={{
                  backgroundColor: "green",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                USER
              </th>
              <th
                style={{
                  backgroundColor: "green",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                DATE CREATED
              </th>
              <th
                style={{
                  backgroundColor: "lightgrey",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                POWER COAL MEGAJEWS
              </th>
              <th
                style={{
                  backgroundColor: "grey",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                INTERBANK COAL MEGAJEWS
              </th>
              <th
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                COAKING COAL MEGAJEWS
              </th>
              <th
                style={{
                  backgroundColor: "orange",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                COKE MEGAJEWS
              </th>
              <th
                style={{
                  backgroundColor: "green",
                  padding: "3px",
                  color: "white",
                  textAlign: "center",
                }}
              >
                MJT BALANCE
              </th>
            </tr>
          </thead>
          <tbody>
            {/* <tr key={account._id}>
              <td>{account.accountNumber}</td>
              <td>{account.user && account.user.name}</td>
              <td>{account.createdAt.substring(0, 10)}</td>
              <td style={{ padding: "3px" }}>{account.powerCoalMJ}</td>
              <td style={{ padding: "3px" }}>{account.interbankCoalMJ}</td>
              <td style={{ padding: "3px" }}>{account.cokingCoalMj}</td>
              <td style={{ padding: "3px" }}>{account.cokeMj}</td>
              <td style={{ color: "green" }}>{account.megajewTradeBalance}</td>
            </tr> */}
          </tbody>
        </Table>
      )}
    </>
  );
};

export default MyAccountScreen;
