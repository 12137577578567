import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { listOrders } from "../actions/jeworderActions";

const JewOrderListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const orderList = useSelector((state) => state.jorderList);
  const { loading, error, jeworderItems } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo]);

  return (
    <>
      <Row classNameName="align-items-center">
        <Col>
          <h1 style={{ color: "orange", margin: "1rem" }}>
            ALL MEGAJOULE ORDERS{" "}
          </h1>
        </Col>
        <Col classNameName="text-right">
          <Link
            to="/profile"
            classNameName="btn btn-outline-info"
            style={{ marginRight: "3rem" }}
          >
            Back
          </Link>
        </Col>
      </Row>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div classNameName="table-wrapper detail-card bounce-in-top">
            <table classNameName="table bounce-in-top" id="table">
              <thead classNameName="bounce-in-top">
                <tr classNameName="table-head detail-card bounce-in-top">
                  <th classNameName="table-cell align-left">ID</th>
                  <th classNameName="table-cell align-left">USER</th>
                  <th classNameName="table-cell align-left">DATE</th>
                  <th classNameName="table-cell align-left">TOTAL</th>
                  <th classNameName="table-cell align-left">PAID</th>
                  <th classNameName="table-cell align-left">DELIVERED</th>
                  <th classNameName="table-cell align-left">OPTIONS</th>
                </tr>
              </thead>

              <tbody style={{ color: "white" }}>
                {jeworderItems.map((order) => (
                  <tr key={order._id}>
                    <td>{order._id}</td>
                    <td style={{ paddingLeft: "1rem" }}>
                      {order.user && order.user.name}
                    </td>
                    <td style={{ paddingLeft: "1rem" }}>
                      {order.createdAt.substring(0, 10)}
                    </td>
                    <td style={{ paddingLeft: "1rem" }}>${order.totalPrice}</td>
                    <td style={{ paddingLeft: "1rem" }}>
                      {" "}
                      {order.isPaid ? (
                        order.paidAt.substring(0, 10)
                      ) : (
                        <i
                          classNameName="fas fa-times"
                          style={{ color: "red" }}
                        ></i>
                      )}
                    </td>

                    <td>
                      {order.isDelivered ? (
                        order.deliveredAt.substring(0, 10)
                      ) : (
                        <i
                          classNameName="fas fa-times"
                          style={{ color: "red" }}
                        ></i>
                      )}
                    </td>
                    <td style={{ paddingLeft: "1rem" }}>
                      <LinkContainer to={`/jeworders/${order._id}`}>
                        <Button variant="light" classNameName="btn-sm">
                          Details
                        </Button>
                      </LinkContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div classNameName="no-results hidden" id="no-results">
              <p classNameName="no-results-message">No results found.</p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default JewOrderListScreen;
