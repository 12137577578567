import React from "react";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Rating from "./Rating";

const Product = ({ products }) => {
  return (
    <>
      <div classNameName="contain">
        <h3 classNameName="h3 gradient__text">AVAILABLE MINERALS </h3>
        <div classNameName="row">
          {products.map((product) => (
            <div
              classNameName="col-md-3 col-sm-6 card"
              style={{ marginTop: "35px" }}
            >
              <div classNameName="product-grid">
                <div classNameName="product-image">
                  <a href="#">
                    <img
                      classNameName="pic-1"
                      src={`${product.image}`}
                      alt="Product"
                    />
                    <img
                      classNameName="pic-2"
                      src={`${product.image}`}
                      alt="Product"
                    />
                  </a>
                  <ul classNameName="social">
                    <li>
                      <a href="" data-tip="Quick View">
                        <i classNameName="fa fa-search"></i>
                      </a>
                    </li>
                    <li>
                      <a href="" data-tip="Add to Wishlist">
                        <i classNameName="fa fa-shopping-bag"></i>
                      </a>
                    </li>
                    <li>
                      <a href="" data-tip="Add to Cart">
                        <i classNameName="fa fa-shopping-cart"></i>
                      </a>
                    </li>
                  </ul>
                </div>
                <ul classNameName="rating" style={{ color: "white" }}>
                  <li classNameName="fa fa-star"></li>
                  <li classNameName="fa fa-star"></li>
                  <li classNameName="fa fa-star"></li>
                  <li classNameName="fa fa-star"></li>
                  <li classNameName="fa fa-star disable"></li>
                </ul>
                <div classNameName="product-content">
                  <div classNameName="price">
                    <sub>MJT</sub> {product.price} / <sup>Kg</sup>
                    <span>
                      <sub>MJT</sub> 20.00
                    </span>
                  </div>
                  <h3 style={{ color: "orange" }}>{product.name}</h3>
                  <Link classNameName="add-to-cart" to={`/product/${product._id}`}>
                    + Add To Cart
                  </Link>
                  {/* <a  href="">
                   
                  </a> */}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <hr />
      <br />
      {/* <div classNameName="shell">
        <div classNameName="containr">
          <div classNameName="row">
            {products.map((product) => (
              <div classNameName="col-md-3">
                <div classNameName="wsk-cp-product">
                  <div classNameName="wsk-cp-img">
                    <img
                      src={`${product.image}`}
                      alt="Product"
                      className="img-responsive"
                    />
                  </div>
                  <div classNameName="wsk-cp-text">
                    <div classNameName="category">
                      <span>{product.name}</span>
                    </div>
                    <div classNameName="title-product">
                      <h3>{product.brand}</h3>
                    </div>
                    <div classNameName="description-prod">
                      <p>{product.description}</p>
                    </div>
                    <div classNameName="card-footer">
                      <div classNameName="wcf-left">
                        <span classNameName="price">
                          <sup>MJT</sup>
                          {product.price} / <sup>Kg</sup>
                        </span>
                      </div>
                      <div classNameName="wcf-right">
                        <Link
                          classNameName="buy-btn"
                          to={`/product/${product._id}`}
                        >
                          <i classNameName="fa fa-shopping-cart"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div classNameName="row">
            <div classNameName="col-md-6">
              <div classNameName="wsk-cp-product">
                <div classNameName="wsk-cp-img">
                  <img
                    src="https://3.bp.blogspot.com/-eDeTttUjHxI/WVSvmI-552I/AAAAAAAAAKw/0T3LN6jABKMyEkTRUUQMFxpe6PLvtcMMwCPcBGAYYCw/s1600/001-culture-clash-matthew-gianoulis.jpg"
                    alt="Product"
                    className="img-responsive"
                  />
                </div>
                <div classNameName="wsk-cp-text">
                  <div classNameName="category">
                    <span>Ethnic</span>
                  </div>
                  <div classNameName="title-product">
                    <h3>My face not my heart</h3>
                  </div>
                  <div classNameName="description-prod">
                    <p>
                      Description Product tell me how to change playlist height
                      size like 600px in html5 player. player good work now
                      check this link
                    </p>
                  </div>
                  <div classNameName="card-footer">
                    <div classNameName="wcf-left">
                      <span classNameName="price">Rp500.000</span>
                    </div>
                    <div classNameName="wcf-right">
                      <a href="#" classNameName="buy-btn">
                        <i classNameName="fa fa-shopping-cart"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div classNameName="col-md-3">
              <div classNameName="wsk-cp-product">
                <div classNameName="wsk-cp-img">
                  <img
                    src="https://1.bp.blogspot.com/-b-2SgNUrFHg/WVSvmewWqgI/AAAAAAAAAK0/1K4YCcbYjhokHwV_IgiVJN9mEnQoWunIwCPcBGAYYCw/s1600/fashion-portrait-nicole-6347.jpg"
                    alt="Product"
                    className="img-responsive"
                  />
                </div>
                <div classNameName="wsk-cp-text">
                  <div classNameName="category">
                    <span>Introvert</span>
                  </div>
                  <div classNameName="title-product">
                    <h3>My face not my heart</h3>
                  </div>
                  <div classNameName="description-prod">
                    <p>
                      Description Product tell me how to change playlist height
                      size like 600px in html5 player. player good work now
                      check this link
                    </p>
                  </div>
                  <div classNameName="card-footer">
                    <div classNameName="wcf-left">
                      <span classNameName="price">Rp500.000</span>
                    </div>
                    <div classNameName="wcf-right">
                      <a href="#" classNameName="buy-btn">
                        <i classNameName="zmdi zmdi-shopping-basket"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div classNameName="col-md-3">
              <div classNameName="wsk-cp-product">
                <div classNameName="wsk-cp-img">
                  <img
                    src="https://1.bp.blogspot.com/-XL_Ba-178Fo/WVSvm5AbApI/AAAAAAAAAK4/X5109HTqUiAhPjbmz4NFVHcpL7ZWe6T3ACPcBGAYYCw/s1600/wow-29.jpg"
                    alt="Product"
                    className="img-responsive"
                  />
                </div>
                <div classNameName="wsk-cp-text">
                  <div classNameName="category">
                    <span>Beauty</span>
                  </div>
                  <div classNameName="title-product">
                    <h3>My face not my heart</h3>
                  </div>
                  <div classNameName="description-prod">
                    <p>
                      Description Product tell me how to change playlist height
                      size like 600px in html5 player. player good work now
                      check this link
                    </p>
                  </div>
                  <div classNameName="card-footer">
                    <div classNameName="wcf-left">
                      <span classNameName="price">Rp500.000</span>
                    </div>
                    <div classNameName="wcf-right">
                      <a href="#" classNameName="buy-btn">
                        <i classNameName="zmdi zmdi-shopping-basket"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div classNameName="col-md-6">
              <div classNameName="wsk-cp-product">
                <div classNameName="wsk-cp-img">
                  <img
                    src="https://3.bp.blogspot.com/-iUes8qr4uC8/WVSvl6Wf_fI/AAAAAAAAAKs/JiTUUVvdbqEC_QGGaQhuVJiezIN3LIqEgCPcBGAYYCw/s1600/IMG_5367-bp.jpg"
                    alt="Product"
                    className="img-responsive"
                  />
                </div>
                <div classNameName="wsk-cp-text">
                  <div classNameName="category">
                    <span>Drama</span>
                  </div>
                  <div classNameName="title-product">
                    <h3>My face not my heart cvf ggf gfg g</h3>
                  </div>
                  <div classNameName="description-prod">
                    <p>
                      Description Product tell me how to change playlist height
                      size like 600px in html5 player. player good work now
                      check this link
                    </p>
                  </div>
                  <div classNameName="card-footer">
                    <div classNameName="wcf-left">
                      <span classNameName="price">Rp500.000</span>
                    </div>
                    <div classNameName="wcf-right">
                      <a href="#" classNameName="buy-btn">
                        <i classNameName="fa fa-shopping-cart"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
      {/* </div>
      </div> */}
    </>
  );
};

export default Product;
