import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  ListGroup,
  Image,
  Form,
  Button,
  Card,
} from "react-bootstrap";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import Message from "../components/Message";
import { addToJewCart, removeFromJewCart } from "../actions/jewscartActions";

const JewsCartScreen = ({ match, location, history }) => {
  const megajewId = match.params.id;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const jewscart = useSelector((state) => state.jewscart);
  const { jewCartItems } = jewscart;

  useEffect(() => {
    if (megajewId) {
      dispatch(addToJewCart(megajewId, qty));
    }
  }, [dispatch, megajewId, qty]);

  const removeFromJewsCartHandler = (id) => {
    dispatch(removeFromJewCart(id));
  };

  const checkoutHandler = () => {
    history.push("/login?redirect=jewshipping");
  };

  return (
    <>
      <Navbar />
      <Row>
        <Col md={8}>
          <h1 style={{ color: "orange" }}>MEGAJOULE CART</h1>
          {jewCartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/">Go Back</Link>
            </Message>
          ) : (
            <ListGroup variant="flush">
              {jewCartItems.map((item) => (
                <ListGroup.Item
                  key={item.megajew}
                  classNameName="detail-card bounce-in-top"
                >
                  <Row>
                    <Col md={2}>
                      <Image src={item.image} alt={item.name} fluid rounded />
                    </Col>
                    <Col md={3}>
                      <Link to={`/megajew/${item.megajew}`}>{item.name}</Link>
                    </Col>
                    <Col md={2}>${item.price}</Col>
                    <Col md={2}>
                      <Form.Control
                        as="select"
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(
                            addToJewCart(item.megajew, Number(e.target.value))
                          )
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </Form.Control>
                    </Col>
                    <Col md={2}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromJewsCartHandler(item.megajew)}
                      >
                        <i classNameName="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card classNameName="detail-card bounce-in-top">
            <ListGroup variant="flush">
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2>
                  Subtotal (
                  {jewCartItems.reduce((acc, item) => acc + item.qty, 0)}) items
                </h2>
                $
                {jewCartItems
                  .reduce((acc, item) => acc + item.qty * item.price, 0)
                  .toFixed(2)}
              </ListGroup.Item>
              <ListGroup.Item>
                <Button
                  type="button"
                  classNameName="btn-block"
                  disabled={jewCartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  Proceed To Checkout
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default JewsCartScreen;
