import React, { useEffect, useState } from 'react';
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";

import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { auth } from "../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { toast, Toaster } from "react-hot-toast";
import { useUserContext } from "../context/userContext";
import Loader from "../components/Loader";
import { useHistory } from "react-router-dom";
import './css/authentication.css'
const Authentication = () => {
    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pageloading, setPageLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    // const [user, setUser] = useState(null);
    const { user, signInUser, forgotPassword } = useUserContext();
    useEffect(() => {
        if (user) {
            
            history.push("/profile");
        }
    });

    function onCaptchVerify() {
        if (!window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                size: "invisible",
                callback: (response) => {
                    onSignup();
                },
                "expired-callback": () => { },
            });
        }
    }

    function onSignup() {
        setLoading(true);
        onCaptchVerify();

        const appVerifier = window.recaptchaVerifier;

        const formatPh = "+" + ph;

        signInWithPhoneNumber(auth, formatPh, appVerifier)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setLoading(false);
                setShowOTP(true);
                toast.success("OTP sended successfully!");
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    }

    function onOTPVerify() {
        setLoading(true);
        window.confirmationResult
            .confirm(otp)
            .then(async (res) => {
                console.log(res);
                user = res.user;
                setLoading(false);
                console.log(user);
            })
            .catch((err) => {
                console.log(err);
                setLoading(false);
            });
    }

    return (
        <>
            {pageloading ? (<Loader />) : (

                <section classNameName="bg-emerald-500 flex items-center justify-center h-screen">
                    <div style={{ backgroundColor: "rgba(4, 0, 3, 0.5)", width: "100%" }}>
                        <Toaster toastOptions={{ duration: 4000 }} />
                        <div id="recaptcha-container" style={{ zIndex: "30", position: "absolute" }}></div>
                        {user ? (
                            <h2 classNameName="text-center text-white font-medium text-2xl" style={{
                                textAlign: 'center',
                                color: 'white',
                                fontSize: "font-size: 1.5rem"
                            }}>
                                👍Login Success
                            </h2>
                        ) : (
                            <>
                                <div className="vid-container">
                                    <video className="bgvid" autoplay="autoplay" muted="muted" preload="auto" loop>
                                        <source src="https://mazwai.com/videvo_files/video/free/2019-09/small_watermarked/190916_01_01_preview.webm" type="video/webm" />
                                    </video>
                                    <div className="inner-container">
                                        <video className="bgvid inner" autoplay="autoplay" muted="muted" preload="auto" loop>
                                            <source src="https://mazwai.com/videvo_files/video/free/2019-09/small_watermarked/190916_01_01_preview.webm" type="video/webm" />
                                        </video>
                                        <div className="box">
                                            {showOTP ? (
                                                <>
                                                    <div classNameName="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full"
                                                        style={{
                                                            backgroundColor: "white",
                                                            color: "orange",
                                                            width: "60%",
                                                            maxWidth: "auto",
                                                            borderRadius: "9999px"
                                                        }}>
                                                        <BsFillShieldLockFill size={30} />
                                                    </div>
                                                    <label
                                                        htmlFor="otp"
                                                        classNameName="font-bold text-xl text-white text-center"
                                                        style={{ textAlign: 'center', marginLeft: "30px", fontSize: "40px" }}
                                                    >
                                                        Enter your OTP
                                                    </label>
                                                    <OtpInput
                                                        value={otp}
                                                        onChange={setOtp}
                                                        OTPLength={6}
                                                        otpType="number"
                                                        disabled={false}
                                                        autoFocus
                                                        classNameName="opt-container "
                                                    ></OtpInput>
                                                    <button
                                                        onClick={onOTPVerify}
                                                        classNameName="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                                                        style={{
                                                            backgroundColor: "orange", display: "flex", gapWidth: '0.25rem',
                                                            alignItems: "center", justifyContent: "center", borderRadius: "0.25rem"
                                                            , paddingTop: "0.625rem", paddingBottom: "0.625rem"
                                                        }}
                                                    >
                                                        {loading && (
                                                            <CgSpinner size={20} classNameName="mt-1 animate-spin" />
                                                        )}
                                                        <span>Verify OTP</span>
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <h1 style={{ color: "orange" }}>Made In Africa</h1>
                                                    <BsTelephoneFill size={30} style={{ marginLeft: "30px" }} />
                                                    <label
                                                        htmlFor=""
                                                        style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.25rem", marginBottom: "20px" }}
                                                    >
                                                        Login with your phone number
                                                    </label>
                                                    <PhoneInput country={"zw"} value={ph} onChange={setPh} style={{ width: "74%" }} />
                                                    <button
                                                        onClick={onSignup}
                                                        classNameName="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                                                        style={{ backgroundColor: "orange" }}
                                                    >
                                                        {loading && (
                                                            <CgSpinner size={20} classNameName="mt-1 animate-spin" />
                                                        )}
                                                        <span>Send code via SMS</span>
                                                    </button>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </section>
            )}

        </>

    );
};

export default Authentication;