import React, { useState, useEffect, useContext } from "react";
import Loader from "../components/Loader";
import { auth } from "../firebase";
import { db } from "../firebase";
import { collection, getDoc, addDoc, doc, setDoc, updateDoc } from "firebase/firestore";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { CTA, Brand, Navbar, Slider, Success, Error, MsgBox, List } from "../components";
import {
    Profile,
} from "../containers";
import { toast, Toaster } from "react-hot-toast";
import { updateProfile, updateEmail } from "firebase/auth";
import SyndicateContext from "../context/syndicateContext";
import WalletAssets from "../components/accountAssets";
import OrderedAssets from "../components/orderedAssets";
import InstallmentAssets from "../components/installmentAssets";
import InstallmentHistory from "../components/installmentHistory";
import TransferAssets from "../components/transferAssets";
import mega from "../assets/mega.png";
import io from 'socket.io-client';
import { useAccount } from "../context/accountContext";

const DashboardPScreen = () => {
    const [personalData, setPersonalData] = useState("none");
    const [transactions, setTransactions] = useState([]);
    const [balancee, setBalance] = useState(0);

    const socket = io('http://localhost:3002');

    const Wallet = () => {
        const sortedTransactions = (transactionz) => {
            const transactionarray = []
            for (let i = 0; i < transactionz.length; i++) {
                if (transactionz[i].originid === personalData.phone && transactionz[i].destination === personalData.phone) {
                    let object = {
                        id: transactionz[i]._id,
                        description: "deposited",
                        amount: transactionz[i].amount,
                        to: "You",
                        from: "bank",
                        date: new Date(transactionz[i].date).toLocaleString(),
                        balance: transactionz[i].destinationBalance
                    }
                    transactionarray.push(object);
                } else if (transactionz[i].originid === personalData.phone) {
                    let object = {
                        id: transactionz[i]._id,
                        description: "sent",
                        amount: transactionz[i].amount,
                        to: transactionz[i].destinationName,
                        from: "You",
                        date: new Date(transactionz[i].date).toLocaleString(),
                        balance: transactionz[i].senderBalance
                    }
                    transactionarray.push(object);
                } else if (transactionz[i].destination === personalData.phone) {
                    let object = {
                        id: transactionz[i]._id,
                        description: "received",
                        amount: transactionz[i].amount,
                        to: "You",
                        from: transactionz[i].name,
                        date: new Date(transactionz[i].date).toLocaleString(),
                        balance: transactionz[i].senderBalance
                    }
                    transactionarray.push(object);
                } else {
                    let object = {
                        id: transactionz[i]._id,
                        description: "withdrawn",
                        amount: transactionz[i].amount,
                        to: "bank",
                        from: transactionz[i].name,
                        date: new Date(transactionz[i].date).toLocaleString(),
                        balance: transactionz[i].senderBalance
                    }
                    transactionarray.push(object);
                }
            }

            return transactionz.sort((a, b) => new Date(b.date) - new Date(a.date)).map(transaction => {
                // Convert transaction data to your desired format
                return {
                    id: transaction._id,
                    description: transaction.description,
                    amount: transaction.amount,
                    to: transaction.destination === personalData.phone ? "you" : transaction.destinationName,
                    from: transaction.originid === personalData.phone ? "You" : transaction.name,
                    date: new Date(transaction.date).toLocaleString(),
                    balance: transaction.originid === personalData.phone ? transaction.senderBalance : transaction.destinationBalance // Adjust as needed
                };
            });
        }

        useEffect(() => {
            console.log('Component mounted'); // Log when the component mounts

            // Initial fetch from server (optional)
            handleSubscribeToBalance()

            // Listen for socket.io events
            console.log('Listening for Socket.IO events');
            socket.on('initialTransactions', (initialTransactions) => {
                console.log('Initial transactions received:', initialTransactions); // Log initial transactions
                const transactionz = sortedTransactions(initialTransactions)
                setTransactions(transactionz); // Set initial transactions
            });

            socket.on('newTransaction', (newTransaction) => {
                console.log('New transaction received:', newTransaction); // Log new transaction
                // Option 1: Update state directly (efficient for large datasets)
                const transaction = {
                    id: newTransaction._id,
                    description: newTransaction.description,
                    amount: newTransaction.amount,
                    to: newTransaction.destination === personalData.phone ? "You" : newTransaction.destinationName,
                    from: newTransaction.originid === personalData.phone ? "You" : newTransaction.name,
                    date: new Date(newTransaction.date).toLocaleString(),
                    balance: newTransaction.originid === personalData.phone ? newTransaction.senderBalance : newTransaction.destinationBalance
                }

                const newbalance = newTransaction.originid === personalData.phone ? newTransaction.senderBalance : newTransaction.destinationBalance

                console.log('Transaction received Balance : ' + newbalance)
                setBalance(newbalance);
                setTransactions(prevTransactions => [transaction, ...prevTransactions]);
                // Option 2: Prepend new transaction for visual emphasis (optional)
            });

            // socket.on('balanceChange',(newbalance)=>{
            //       console.log('New Balance : '+newbalance)

            // })

            return () => {
                console.log('Component unmounted'); // Log when the component unmounts
                socket.disconnect(); // Cleanup on unmount
            };
        }, []);


        return (
            <div className="bank">
                <div className="mobile">
                    <div className="profile">
                        <figure className="profile__avatar">
                            <img src={personalData.profile ? personalData.profile : "https://i.ibb.co/G78wzN3/sophie.png"} alt="profile_pic" />
                        </figure>
                        <div className="profile__name">{personalData.name}</div>
                    </div>
                    <div className="balance">
                        <div className="balance__text" style={{ fontWeight: "600" }}>MEGAJOULE BALANCE</div>
                        <div className="balance__amount"><sup style={{ color: "#FF6969", display: "inline" }}>MJ</sup><span style={{ color: "#2D9596", display: "inline" }}>{balancee ? balancee : 0}</span></div>
                    </div>
                    <div className="walletcard">
                        <div className="card__information">
                            <div className="card__information__text">Need Megajoules</div>
                            <img src={mega} alt="CapWay card" style={{ width: "80%" }} />
                        </div>
                        <button className="walletcard__button">Get Megajoule</button>
                    </div>
                    <div className="wallettabs">
                        <nav className="nav">
                            <div className="nav__tab nav__tab--active">
                                <span className="nav__tab__text">Transactions</span>
                            </div>
                            <div className="nav__tab">
                                {/* <span className="nav__tab__text">Deposits</span> */}
                            </div>
                        </nav>
                        <div className="indicator"></div>
                        <div className="walletcontents">
                            <div className="walletcontent">
                                <div className="today">
                                    {/* <div>{new Date().toLocaleString()}</div> */}
                                    {/* <div>-$430.08</div> */}
                                </div>
                                {/* Transactions */}

                                {transactions.length > 0 && transactions.map((transaction) => (
                                    <div className="transaction" key={transaction.id}>
                                        <TransactionWho who={transaction.description}>
                                            <TransactionAbout name={`${transaction.from}   `} type={transaction.description} />
                                            <TransactionData amount={`  ----- ${transaction.description}     M$${transaction.amount.toFixed(2)}  to ${transaction.to}`} date={` on  ${transaction.date}`} />
                                        </TransactionWho>
                                    </div>
                                ))}
                            </div>
                            <div className="walletcontent">
                                <div className="transaction">
                                    <TransactionWho who="deposit">
                                        <TransactionAbout name="Deposit" />
                                        <TransactionData amount="+ $250.00" date="11:45 AM" />
                                    </TransactionWho>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="walletoptioncontent">
                    {/* <!-- card --> */}
                    <div class="walletoptioncard">

                        <div class="icon"><i class="material-icons md-36">compare_arrows</i></div>
                        <p class="title">Send MJ</p>
                        <p class="text">Click to see or edit your profile page.</p>

                    </div>
                    {/* <!-- end card --> */}
                    {/* <!-- card --> */}
                    <div class="walletoptioncard">

                        <div class="icon"><i class="material-icons md-36">grain</i></div>
                        <p class="title">Swap</p>
                        <p class="text">Check all your favourites in one place.</p>

                    </div>
                    {/* <!-- end card --> */}
                    {/* <!-- card --> */}
                    <div class="walletoptioncard">

                        <div class="icon"><i class="material-icons md-36">local_atm</i></div>
                        <p class="title">Redeem MJ</p>
                        <p class="text">Add or change your contacts and links.</p>

                    </div>
                    {/* <!-- end card --> */}
                </div>
            </div>
        );
    };

    const handleSubscribeToBalance = () => {
        socket.emit('subscribeToBalance', personalData.phone); // Emit the phone number to subscribe to balance changes
    };
    const TransactionWho = ({ children, who }) => {
        const whoclassNames = `transaction__who transaction__who--${who}`;
        return <div className={whoclassNames}>{children}</div>;
    };

    const TransactionAbout = ({ name, type }) => {
        return (
            <div className="transaction__who__about">
                <div className="transaction__who__name">{name}</div>
                <div className="transaction__who__type">{type}</div>
            </div>
        );
    };

    const TransactionData = ({ amount, date }) => {
        return (
            <div className="transaction__data">
                <div className="transaction__data__amount">{amount}</div>
                <div className="transaction__data__date">{date}</div>
            </div>
        );
    };



    return (
        <>
            <div className="housefindashboard">
                <div className="dashsidebar">
                    <div className="logo"></div>
                    <div className="dashmenu">
                        <ul className="ulist" style={{ display: "block" }}>
                            <Link to="/">
                                <li className="liitem"><i className="fas fa-th"></i>
                                    <i style={{ color: "white" }}>Home</i>
                                </li>
                            </Link>

                            <Link to="/commodity/garlpex">
                                <li className="liitem"><i className="fas fa-briefcase"> </i>
                                    <i style={{ color: "white" }}>GrowthHub</i>
                                </li>
                            </Link>

                            <Link to="/bazaar/page">
                                <li className="liitem"><i className="fa fa-cart-arrow-down"> </i>
                                    <i style={{ color: "white" }}>Exchange</i>
                                </li>
                            </Link>
                        </ul>
                    </div>
                </div>
                <div className="content">
                    <div className="searchbar">
                        <i className="fa fa-search"></i>
                        <input type="text" placeholder="Search" />
                        <i className="far fa-bell"></i>
                        <img style={{ width: "40px" }} src="https://images.pexels.com/photos/51439/pexels-photo-51439.jpeg" alt="" />
                    </div>
                    <div className="items">
                        <div className="itemgoogle">
                            <div className="itemheader">
                                <div className="logo">
                                    {/* <i className="fab fa-google"></i> */}
                                    <img style={{ width: "60px" }} src="https://www.svgrepo.com/show/489271/style.svg" alt="contracts" />
                                </div>
                                <div className="brand">
                                    <h3>CONTRACTS</h3>
                                    <h5 style={{ color: "white" }}>In Progress</h5>
                                </div>
                                <i className="fas fa-ellipsis-v"></i>
                            </div>
                            <div className="itemcontent">
                                <div className="importants">
                                    <a className="ilk" href="">IN PROGRESS</a>
                                    <a href="">HIGH</a>
                                </div>
                                <div className="taskdone">
                                    <h4>Task Done: <span>25</span> / 50</h4>
                                    <div className="progresbar">
                                        <div className="inner"></div>
                                    </div>

                                </div>

                                <div className="importans2">

                                    <a className="ilk" href="">IOS APP</a>
                                    <a href="">UI/UX</a>

                                </div>
                                <div className="users">
                                    <img style={{ width: "40px" }} src="https://images.pexels.com/photos/3366753/pexels-photo-3366753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /><img style={{ width: "40px" }} src="https://images.pexels.com/photos/1820559/pexels-photo-1820559.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /><img style={{ width: "40px" }} src="https://images.pexels.com/photos/2240772/pexels-photo-2240772.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                    <img style={{ width: "40px" }} src="https://images.pexels.com/photos/2269872/pexels-photo-2269872.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                    <div className="more">
                                        +5
                                    </div>
                                </div>

                                <div className="importants3">
                                    <a href=""> DUE DATE: 20 jUNE </a>
                                    <i className="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="itemtask">
                            <div className="itemhead">
                                <h3 style={{ color: "black" }}>TRANSACTIONS <span>(05)</span></h3>
                            </div>
                            <div className="tasks">
                                <div className="taskitem">
                                    {/* {transactions.length > 0 && transactions.map((transaction) => (
                        <div key={transaction.id}>
                          <div className="itemnumber">M$${transaction.amount.toFixed(2)}</div>
                          <div className="itemname">{transaction.description}</div>
                          <div className="itemstatu"><i className="far fa-check-circle"></i></div>
                        </div>
                      ))} */}
                                </div>

                                <div className="taskitem">
                                    <div className="itemnumber">02</div>
                                    <div className="itemname">Slack Logo Design</div>
                                    <div className="itemstatu"><i className="far fa-check-circle"></i></div>

                                </div>
                                <div className="taskitem">
                                    <div className="itemnumber">03</div>
                                    <div className="itemname">Dashboard Design</div>
                                    <div className="itemstatu"><i className="far fa-check-circle"></i></div>

                                </div>
                                <div className="taskitem">
                                    <div className="itemnumber">04</div>
                                    <div className="itemname">Create wireframe</div>
                                    <div style={{ color: "green" }} className="itemstatu"><i className="fas fa-check-circle"></i></div>

                                </div>
                                <div className="taskitem">
                                    <div className="itemnumber">05</div>
                                    <div className="itemname">Google Logo Design</div>
                                    <div className="itemstatu"><i className="fas fa-check-circle" style={{ color: "green" }}></i></div>

                                </div>
                                <div className="taskitem">
                                    <div className="itemnumber">06</div>
                                    <div className="itemname">Create wireframe</div>
                                    <div className="itemstatu"><i className="far fa-check-circle"></i></div>

                                </div>
                                <div className="taskitem">
                                    <div className="itemnumber">07</div>
                                    <div className="itemname">Slack</div>
                                    <div className="itemstatu"><i className="far fa-check-circle"></i></div>

                                </div>
                            </div>

                        </div>
                        <div className="itemcalendar">
                            <div className="itemhead">
                                <h3>Feb 2020</h3>
                                <div className="arrows">
                                    <i className="fas fa-chevron-circle-left"></i>
                                    <i className="fas fa-chevron-circle-right"></i>
                                </div>
                            </div>
                            <div className="takvim">
                                <div className="item">SUN</div>
                                <div className="item">MON</div>
                                <div className="item">TUE</div>
                                <div className="item">WED</div>
                                <div className="item">THU</div>
                                <div className="item">FRI</div>
                                <div className="item">SAT</div>
                                <div className="days old">26</div>
                                <div className="days  old">27</div>
                                <div className="days old">28</div>
                                <div className="days old">29</div>
                                <div className="days old">30</div>
                                <div className="days old">31</div>
                                <div className="days">1</div>

                                <div className="days">2</div>
                                <div className="days">3</div>
                                <div className="days">4</div>
                                <div className="days select">5</div>
                                <div className="days">6</div>
                                <div className="days">7</div>
                                <div className="days">8</div>

                                <div className="days">9</div>
                                <div className="days">10</div>
                                <div className="days">11</div>
                                <div className="days">12</div>
                                <div className="days">13</div>
                                <div className="days">14</div>
                                <div className="days">15</div>

                                <div className="days">16</div>
                                <div className="days">17</div>
                                <div className="days">18</div>
                                <div className="days">19</div>
                                <div className="days">20</div>
                                <div className="days">21</div>
                                <div className="days">22</div>

                                <div className="days">23</div>
                                <div className="days">24</div>
                                <div className="days">25</div>
                                <div className="days">26</div>
                                <div className="days">27</div>
                                <div className="days">28</div>
                                <div className="days">29</div>

                            </div>

                        </div>
                        <div className="itemgoogle slack">
                            <div className="itemheader">
                                <div className="logo">
                                    <i className="fab fa-slack"></i>
                                </div>
                                <div className="brand">
                                    <h3>Slack</h3>
                                    <h5>Slack corporation</h5>
                                </div>
                                <i className="fas fa-ellipsis-v"></i>
                            </div>
                            <div className="itemcontent">
                                <div className="importants">
                                    <a className="ilk" href="">COMPLETED</a>
                                    <a href="">MEDIUM</a>
                                </div>
                                <div className="taskdone">
                                    <h4>Task Done: <span>30</span> / 30</h4>
                                    <div className="progresbar">
                                        <div className="inner"></div>
                                    </div>

                                </div>

                                <div className="importans2">

                                    <a className="ilk" href="">IOS APP</a>
                                    <a href="">ANDROID</a>

                                </div>
                                <div className="users">
                                    <img style={{ width: "40px" }} src="https://images.pexels.com/photos/3366753/pexels-photo-3366753.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /><img style={{ width: "40px" }} src="https://images.pexels.com/photos/1820559/pexels-photo-1820559.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" /><img style={{ width: "40px" }} src="https://images.pexels.com/photos/2240772/pexels-photo-2240772.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                    <img style={{ width: "40px" }} src="https://images.pexels.com/photos/2269872/pexels-photo-2269872.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500" alt="" />
                                    <div className="more">
                                        +5
                                    </div>
                                </div>

                                <div className="importants3">
                                    <a href=""> DUE DATE: 20 jUNE </a>
                                    <i className="fas fa-star"></i>
                                </div>
                            </div>
                        </div>
                        <div className="itemvideos">
                            <div className="itemheads">
                                <div className="dashtitle">
                                    <h3>Google</h3>
                                    <i className="far fa-play-circle"></i>
                                </div>
                                <div className="araitem">yh</div>

                            </div>
                            <div className="content"></div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
};

export default DashboardPScreen;