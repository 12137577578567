import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import "./minerals.css";
import ReactPhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import Notification from "../notification/Notification";
// import Meta from "../components/Meta";

const Minerals = ({ commodities }) => {
  
  return (
    <>
      <div className="cardminerals-list bounce-in-top" style={{ width: "80%", marginLeft: "auto", marginRight: "auto", marginBottom: "50px" }}>
        {commodities.map((commodity) => (
          <div
            className="cardminerals 1"
            style={{ marginBottom: "120px"}}
          >
            <div className="card_image">
              <img src={commodity.assetProfile} />
            </div>
            <div className="card_title title-white">
              <p
                style={{
                  color: "white", 
                  textAlign: "left"
                }}
              >
                {commodity.assetName} <br /> <span style={{fontSize: "15px", backgroundColor: "orange", padding: "10px", color: "white", textWrap:"wrap"}}>
                Desc: {commodity.description}</span>
                <span style={{color: "black", fontSize: "15px",backgroundColor: "orange",padding: "10px"}}>MJ: {commodity.megajouleSwapValue}</span>
              </p>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default Minerals;
