import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import './css/myprofile.css';
import { toast, Toaster } from "react-hot-toast";
import { updateProfile, updateEmail } from "firebase/auth";
import Loader from "../components/Loader";
import { auth } from "../firebase";


const MyProfileScreen = () => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [photoUrl, setPhoto] = useState('')
    const [loading, setLoading] = useState(false)

    const user = auth.currentUser;
    const history = useHistory();

    const UpdateProfile = () => {
        setLoading(true);
        updateProfile(auth.currentUser, {
            displayName: name, photoURL: "https://cdn.pixabay.com/photo/2013/07/18/15/06/couple-164525_1280.jpg"
        }).then(() => {
            updateEmail(auth.currentUser, email).then(() => {
                toast.success("Updated successfully!");
                setLoading(false);
            }).catch((error) => {
                console.log(error);
            });
        }).catch((error) => {
            console.log(error);
        });
    }
    useEffect(() => {
        console.log(user)
        if (!user) {
            history.push("/login");
        } else if (user.displayName != null) {
            history.push("/profile");
        }
    });

    return (
        <>
            <main>
                <section className="user_profille">
                    <div className="container headerrr">
                        <div className="row1" style={{ marginTop: "40px" }}>
                            <div className="twelve columns avatar"><span>M</span></div>
                        </div>
                        <div className="row1">
                            <div className="twelve columns">
                                <h1 style={{ color: "white" }}>Whatsup!</h1>
                                <p style={{ fontSize: "14px", color: "white" }}>Welcome to your profile. Feel free to make edits to your personal<br />information as well as manage your devices.</p>
                            </div>
                        </div>
                        <div className="row1">
                            <div className="one-half column">
                                <a href="#connect" className="a button-connect">upload Profile</a>
                                <a href="#connect" className="a button-connect">location Update</a>
                            </div>
                            <div className="one-half column upgrade" style={{ padding: "30px" }}>
                                <p>Account Type: <strong>Free</strong> <a href="#upgrade" className="a button-upgrade">Upgrade</a></p>
                            </div>
                        </div>
                    </div>
                    <div className="container tabs-area">
                        <div className="row1">
                            <ul className="tabs">
                                <li><a href="#profile" className="active a">Profile</a></li>
                                <li><a href="#devices">Dashboard</a></li>
                            </ul>
                        </div>
                        <div className="tab">
                            <div className="row1 title">
                                <div className="one-half column">
                                    <h5>MIAMI Profile</h5>
                                </div>
                                {/* <div className="one-half column r-align">
                                    <a href="#editprofile" className="a button-edit">Edit Profile</a>
                                </div> */}
                            </div>
                            <div className="row1">
                                <div className="two-thirds column">
                                    <form classNameName='formm'>
                                        {loading ? (<Loader />) : (<Toaster toastOptions={{ duration: 4000 }} />)}
                                        <div className="row1">
                                            <div className="one-half column">
                                                <label for="username">Name In Full</label>
                                                <input className="u-full-width inputt" type="text" placeholder="Enter Name" id="username"
                                                    onChange={(e) => setName(e.target.value)} />
                                            </div>
                                            <div className="one-half column">
                                                <label for="post-code">Bio</label>
                                                <input className="u-full-width empty inputt" type="text" placeholder="Tell us about yourself" id="post-code" />
                                            </div>
                                        </div>
                                        <div className="row1">
                                            <div className="one-half column">
                                                <label for="email">Email</label>
                                                <input className="u-full-width inputt" type="email" placeholder="Enter Email" id="email"
                                                    onChange={(e) => setEmail(e.target.value)}
                                                />
                                            </div>
                                            <div className="one-half column">
                                                <label for="expertize">Profession</label>
                                                <input className="u-full-width inputt" type="text" placeholder="Enter Profession" id="profession" />
                                            </div>
                                            <div className="one-half column">
                                                <label for="gender">Gender</label>
                                                <select className="u-full-width" id="gender">
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row1">
                                            <div className="one-half column">
                                                <label for="dob">National ID Number</label>
                                                <input className="u-full-width empty inputt" type="text" placeholder="ID" id="dob" />
                                            </div>
                                            <div className="one-half column">
                                                <label for="age">Country</label>
                                                <input className="u-full-width empty inputt" type="text" placeholder="Country" id="age" />
                                            </div>
                                            <div className="one-half column">
                                                <label for="age">Address</label>
                                                <input className="u-full-width empty inputt" type="text" placeholder="Address" id="age" />
                                            </div>
                                            <div className="one-half column">
                                                <label for="age">Nearest City</label>
                                                <input className="u-full-width empty inputt" type="text" placeholder="City" id="age" />
                                            </div>
                                        </div>
                                        <div className="one-half column">
                                            <a href="#connect" className="a button-connect" style={{ backgroundColor: "orange", color: "black" }}
                                                onClick={UpdateProfile}>UPDATE PROFILE</a>

                                        </div>
                                    </form>
                                </div>
                                <div className="one-third column complete">
                                    <h6>Profile Completeness</h6>
                                    <div className="pie">
                                        <div className="slice-right"></div>
                                        <div className="slice-left"></div>
                                        <div className="percent">
                                            <div className="number">50<sup>%</sup><br /><span>Complete</span></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        </>
    )

}


export default MyProfileScreen;