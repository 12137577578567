// Import the necessary functions from the Firebase SDK
import React,{ createContext, useContext, useEffect, useState } from "react";
import { createUserWithEmailAndPassword,updateProfile, 
    onAuthStateChanged, signInWithEmailAndPassword, signOut,
    sendPasswordResetEmail, signInWithPhoneNumber,RecaptchaVerifier} from 'firebase/auth'
import { auth } from '../firebase'




// Create the UserContext
const UserContext = createContext({});
export const useUserContext = () => useContext(UserContext);

// Create the UserContextProvider component
export const UserContextProvider = ({ children }) => {
    // Set the initial state of the user and loading
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState();
    const [error, setError] = useState("");

    // On mount, check if a user is signed in and set the user state
    useEffect(() => {
        // Set the loading state to true
        setLoading(true);
        // Use the onAuthStateChanged listener to subscribe to user state changes
        // If a user is signed in, set the user state and clear any errors
        // If a user is not signed in, set the user state to null and clear any errors
        const unsubscribe = onAuthStateChanged(auth, (res) => {
            res ? setUser(res) : setUser(null);
            setError('');
            setLoading(false);
        })

        // Cleanup the listener on unmount
        return unsubscribe;
    }, [])

    // Create a function to register a user
    const registerUser = (email, name, password) => {
        // Set the loading state to true
        setLoading(true);
        // Create a new user with the createUserWithEmailAndPassword function
        // Then, update the user profile with the updateProfile function
        // Catch any errors and set the error state
        // Finally, set the loading state to false
        createUserWithEmailAndPassword(auth, email, password)
            .then(() => {
                return updateProfile(auth.currentUser, {
                    displayName: name,
                });
            }).then(res => console.log(res))
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    // Create a function to sign in a user
    const signInUser = (email, password) => {
        // Set the loading state to true
        setLoading(true);
        // Sign in the user with the signInWithEmailAndPassword function
        // Catch any errors and set the error state
        // Finally, set the loading state to false
        signInWithEmailAndPassword(auth, email, password)
            .then(res => {
                setUser(res.user);
                setError('');
                console.log(res);
            })
            .catch(err => setError(err.message))
            .finally(() => setLoading(false));
    }

    // Create a function to sign out a user
    const logoutUser = () => {
        // Sign out the user with the signOut function
        signOut(auth);
    }

    // Create a function to send a password reset email
    const forgotPassword = (email) => {
        // Return the sendPasswordResetEmail function
        return sendPasswordResetEmail(auth, email)
    }
    

    // Create the context value object
    const contextValue = {
        user, 
        loading, 
        error, 
        registerUser, 
        signInUser, 
        logoutUser, 
        forgotPassword
    };
    return (
        <UserContext.Provider value={contextValue}>
            {children}
        </UserContext.Provider>
    )
}