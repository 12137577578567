import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import $ from "jquery";
import { getUserDetails, updateUser } from "../actions/userActions";
import { USER_UPDATE_RESET } from "../constants/userConstants";

const UserEditScreen = ({ match, history }) => {
  const userId = match.params.id;
  $(document).ready(function () {
    $(".menu-links li a").click(function (e) {
      $(".menu-links li.active").removeclassName("active");
      var $parent = $(this).parent();
      $parent.addclassName("active");
    });
    $(".dashboardhamburger-icon").click(function () {
      $(".menu-links").toggleclassName("left");
    });
    $(".dashboardhamburger-icon").click(function () {
      $(this).toggleclassName("ham-style");
    });
    const themeSwitch = document.querySelector("#checkbox");
    themeSwitch.addEventListener("change", () => {
      document.body.classNameList.toggle("dark-theme");
    });
  });

  // Admin Options
  const [showAdmin, setShowAdmin] = useState(false);
  const admin = () => {
    setShowProfile(false);
    setShowAdmin(true);
  };
  //End Admin

  // Profile
  const [showProfile, setShowProfile] = useState(true);
  const profile = () => {
    setShowAdmin(false);
    setUsers(false);
    setShowProfile(true);
  };
  //End Profile

  // Admin ALL MIAMI USERS
  const [showUsers, setUsers] = useState(false);
  const miamiUsers = () => {
    setShowAdmin(false);
    setShowProfile(false);
    setUsers(true);
  };
  //End ALL MIAMI USERS

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      history.push("/admin/userlist");
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
        setIsAdmin(user.isAdmin);
      }
    }
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ _id: userId, name, email, isAdmin }));
  };

  return (
    <>
      <div className="bodydashboard light-theme dark-theme">
        <header>
          <div className="container-fluid">
            <nav className="navbardashboard navbar-expand-lg navbar-light">
              <div className="header-inner d-flex justify-content-between align-items-center">
                <a className="navbar-brand flex-shrink-0" href="#">
                  <img
                    src="https://yudiz.com/codepen/nft-store/logo-icon.svg"
                    alt="logo-image"
                    className="img-fluid"
                  />
                  MIAMI
                </a>
                <div className="header-content d-flex align-items-center justify-content-end">
                  <form className="d-flex justify-content-end align-items-center">
                    <div className="search-icon">
                      <i className="fa fa-search" aria-hidden="true"></i>
                      <input
                        className="form-control"
                        type="search"
                        placeholder="Search"
                        aria-label="Search"
                      />
                    </div>
                    <label className="switch flex-shrink-0 mb-0">
                      <input id="checkbox" type="checkbox" />
                      <span className="sliderdashboard round"></span>
                    </label>
                  </form>
                  <Link className="profile">
                    <img
                      src="https://yudiz.com/codepen/nft-store/user-pic1.svg"
                      alt="user-image"
                    />
                    {userInfo.name}
                  </Link>
                  <Link className="notification dashboardhamburger-icon">
                    <i className="fa fa-bars" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div className="nft-store">
          <div className="container-fluid">
            <div className="nft-store-inner d-flex">
              <div
                className="menu-links"
                style={{ backgroundColor: "#001C30", padding: "5%" }}
              >
                <ul>
                  <li className="nav-item">
                    <Link to="/" className="d-flex align-items-center nav-link">
                      <i className="fa fa-home" aria-hidden="true"></i>
                      <span>Home</span>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="d-flex align-items-center nav-link">
                      <i className="fa fa-user" aria-hidden="true"></i>
                      <span>Partners</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="d-flex align-items-center nav-link">
                      <i className="fa fa-fire" aria-hidden="true"></i>
                      <span>Transactions</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="d-flex align-items-center nav-link">
                      <i className="fa fa-briefcase" aria-hidden="true"></i>
                      <span>Wallet</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="#" className="d-flex align-items-center nav-link">
                      <i className="fa fa-shopping-cart" aria-hidden="true"></i>
                      <span>Purchased</span>
                    </a>
                  </li>
                  {userInfo && userInfo.isAdmin && (
                    <li className="nav-item">
                      <a
                        href="#"
                        className="d-flex align-items-center nav-link"
                        onClick={admin}
                      >
                        <i className="fa fa-cog" aria-hidden="true"></i>
                        <span>Admin</span>
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              {/* EDIT USERS */}
              <div
                style={{
                  width: "60%",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginBottom: "15px",
                }}
              >
                <h1 style={{ color: "orange", marginLeft: "60%" }}>
                  Edit User
                </h1>
                <Link
                  to="/profile"
                  classNameName="message btn"
                  style={{
                    fontWeight: "normal",
                    color: "white",
                  }}
                >
                  Dashboard
                </Link>
                {loadingUpdate && <Loader />}
                {errorUpdate && (
                  <Message variant="danger">{errorUpdate}</Message>
                )}
                {loading ? (
                  <Loader />
                ) : error ? (
                  <Message variant="danger">{error}</Message>
                ) : (
                  <Form
                    onSubmit={submitHandler}
                    classNameName="detail-card bounce-in-top"
                  >
                    <Form.Group controlId="name">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="name"
                        placeholder="Enter name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        style={{
                          width: "40%",
                          marginBottom: "15px",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="email">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        style={{
                          width: "40%",
                          marginBottom: "15px",
                          fontSize: "14px",
                          padding: "10px",
                        }}
                      ></Form.Control>
                    </Form.Group>

                    <Form.Group controlId="isadmin">
                      <Form.Check
                        type="checkbox"
                        label="Is Admin"
                        checked={isAdmin}
                        onChange={(e) => setIsAdmin(e.target.checked)}
                        style={{
                          marginBottom: "15px",
                          color: "white",
                        }}
                      ></Form.Check>
                    </Form.Group>

                    <Button type="submit" variant="primary">
                      Update
                    </Button>
                  </Form>
                )}
              </div>
              {/* END EDIT USER */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserEditScreen;
