import React, { useEffect, useState, useRef } from "react";
import { useAuth0 } from '@auth0/auth0-react';
import digital_asset from '../assets/digital-asset.png';
import funding from '../assets/funding.png';
import gains from '../assets/gains.png';
import monitor from '../assets/monitor.png';
import { Navbar } from "../components";
import VanillaTilt from 'vanilla-tilt';
import './css/portal.css';



const HomeSection = () => {
  const observer = useRef(null);

  useEffect(() => {
    const elementsToReveal = document.querySelectorAll('.card:not(.hero)');

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classNameList.add('reveal');
        }
      });
    };

    observer.current = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

    elementsToReveal.forEach((element) => {
      observer.current.observe(element);
    });

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <section classNameName="bento-section">
      <div classNameName="container">
        <header>
          <h2>Latest Stories</h2>
        </header>
        <div classNameName="bento-grid">
          {/* Card 1 */}
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/crocodile.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/crocodile.webp"
                  alt="Cartoon crocodile illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>

              <h3>Chomping Chronicles: The Crocodile's Adventure</h3>
              <small>January 12, 2024</small>
            </div>
          </a>

          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/macaw.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/macaw.webp"
                  alt="Cartoon macaw illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h3>Vibrant Wings: Journey of the Macaw</h3>
              <small>January 16, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/cow.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/cow.webp"
                  alt="Cartoon cow illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h3>Moo-rific Tales: The Brave Cow's Journey</h3>
              <small>January 18, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/dog.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/dog.webp"
                  alt="Cartoon dog illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h3>Pawsome Quest: A Dog's Adventure</h3>
              <small>January 20, 2024</small>
            </div>
          </a>

          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/elephant.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/elephant.webp"
                  alt="Cartoon elephant illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Giant Hearts: An Elephant's Odyssey</h4>
              <small>January 24, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/starfish.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/starfish.webp"
                  alt="Cartoon starfish illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Explore Undersea Wonders with Starfish's Cosmic Journey</h4>
              <small>January 11, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/horse.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/horse.webp"
                  alt="Cartoon horse illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Gallop and Giggles: The Whimsical Horse Chronicles</h4>
              <small>February 12, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/fox.avif"
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/fox.webp"
                  alt="Cartoon fox illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Furry Fantasia: Fox's Enchanted Tale</h4>
              <small>February 16, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/snake.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/snake.webp"
                  alt="Cartoon snake illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Slinky Serpent: A Snake's Mysterious Quest</h4>
              <small>February 20, 2024</small>
            </div>
          </a>
          <a href="#" className="card">
            <div className="visual">
              <picture>
                <source
                  srcset="
                    https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/cockatoo.avif
                  "
                  type="image/avif"
                />
                <img
                  src="https://raw.githubusercontent.com/mobalti/open-props-interfaces/main/bento-grid/images/cockatoo.webp"
                  alt="Cartoon cockatoo illustration"
                  width="512"
                  height="512"
                  loading="lazy"
                />
              </picture>
            </div>
            <div className="content">
              <small>FEATURED STORY</small>
              <h4>Feathered Friends: The Cockatoo's Symphony</h4>
              <small>February 24, 2024</small>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

const PortalScreen = () => {
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [personalData, setPersonalData] = useState("none");
  const [userAccessToken, setUserAccessToken] = useState(null);


  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          setUserAccessToken(accessToken);
          console.log('Fetching Access Token:', accessToken);
          const response = await fetch(`/identification`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = await response.json();
          console.log('Fetching data:', data);
          setPersonalData(JSON.stringify(data));
        } catch (error) {
          console.error('Error fetching user data:', error.message);
        }
      }
    };

    fetchData();
  }, [isAuthenticated, user]);


  return (
    <>
    <div classNameName="gradieant__bg">
        <Navbar />
      </div>
      <div style={{ backgroundImage: "url(https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Ftransparent-quartz-crystal-precious-gemstone-illuminated-generated-by-ai.jpg?alt=media&token=7002b1d2-b0ae-4210-a91e-dcb578748076)", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
        {/* <h1 style={{ color: "white", textAlign: "center" }}>Portal for {personalData.name}</h1> */}
        {isAuthenticated ? (
          <>
            <div classNameName="homecontainer slide-in-elliptic-top-fwd">
              <div classNameName="homecard" style={{ '--color': '#eb5ae5' }}>
                <div classNameName="content">
                  <div classNameName="icon">
                    {/* <ion-icon name="code-slash-outline"></ion-icon> */}
                    <img src={digital_asset} classNameName="wobble-hor-bottom" alt="digital asset" />
                  </div>
                  <div classNameName="text">
                    <h3>Contributors and Collateralization</h3>
                    <p style={{ textAlign: "left", fontSize: "17px" }}>Leverage yourself on our asset collateralization by mineral-backed shares from HANDAC, providing you with a layer of tradeable commodities</p>
                    <a>Read More</a>
                  </div>
                </div>
              </div>
              <div classNameName="homecard" style={{ '--color': '#5b98eb' }}>
                <div classNameName="content">
                  <div classNameName="icon">
                    <img src={funding} classNameName="wobble-hor-bottom" alt="digital asset" />
                  </div>
                  <div classNameName="text">
                    <h3> Growth-Seeking Assets</h3>
                    <p style={{ textAlign: "left", fontSize: "17px" }}>Growth-Seeking Assets are forward-thinking ventures proposed by asset owners looking to fuel their expansion. These assets span various industries, promising potential returns to contributors. As a contributor, you have the chance to become a stakeholder in these ventures by acquiring Megajoule tokens.</p>
                    <a href="#">Read More</a>
                  </div>
                </div>
              </div>

              <div classNameName="homecard" style={{ '--color': '#89ec5b' }}>
                <div classNameName="content">
                  <div classNameName="icon">
                    {/* <ion-icon name="brush-outline"></ion-icon> */}
                    <img src={gains} classNameName="wobble-hor-bottom" alt="digital asset" />
                  </div>
                  <div classNameName="text">
                    <h3>Quarterly Gains and Share Redemption</h3>
                    <p style={{ textAlign: "left", fontSize: "17px" }}>Experience the benefits of our structured operating model. Receive gains on your contribution and participate in the redemption of your amassed mineral-backed shares, realizing existing world market values for the shares.</p>
                    <a href="#">Read More</a>
                  </div>
                </div>
              </div>

              <div classNameName="homecard" style={{ '--color': '#eb5ae5' }}>
                <div classNameName="content">
                  <div classNameName="icon">
                    {/* <ion-icon name="code-slash-outline"></ion-icon> */}
                    <img src={monitor} classNameName="wobble-hor-bottom" alt="digital asset" />
                  </div>
                  <div classNameName="text">
                    <h3>Monitoring and Reporting</h3>
                    <p style={{ textAlign: "left", fontSize: "17px" }}>Stay informed with our robust monitoring and reporting system. View operational metrics, and receive real-time updates on your activities. Our dashboard ensures transparency and clarity in every step.</p>
                    <a>Read More</a>
                  </div>
                </div>
              </div>

              {/* <HomeSection /> */}
            </div>
            {/* <div classNameName="gpt3__whatgpt3 section__margin" style={{ width: "60%" }}>
              <form style={{ width: "98%" }}>
                <label htmlFor="prompt" style={{ color: "white", fontSize: "14px" }}>Enter Full Name </label><br />
                <input type="text" placeholder="Enter First Name" name="name" style={{ color: "black", fontSize: "15px", width: "90%" }} /> <br />
                <label htmlFor="prompt" style={{ color: "white", fontSize: "14px" }}>Home Address</label><br />
                <input type="text" placeholder="Enter Address" name="name" style={{ color: "black", fontSize: "15px", width: "90%" }} />
              </form>
            </div> */}
            
          </>
        ) : (
          <p>Please log in to access the portal</p>
        )}
      </div>
    </>
  );
};

export default PortalScreen;
