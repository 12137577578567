import React from "react";
import possibilityImage from "../../assets/house.jpg";
import coal from "../../assets/coal2.png";
import house from "../../assets/Enlarge_Megajoule.png";
import globe from "../../assets/globe1.jpg";
import megajoule from "../../assets/megajoule.jpg";
import megaAxis from "../../assets/Cover.png";
import mega from "../../assets/mega.png";
import red from "../../assets/Red.png";
import blackstone from "../../assets/Blackstone.png";
import { Link } from "react-router-dom";

import irrigator2 from "../../assets/Irrigator2.jpeg";

import "./possibility.css";
let image1 = "";

const Possibility = ({
  subheading,
  heading,
  content,
  image,
  linkUrl,
  linkText,
}) => {
  if (image === "coal") {
    image1 =
      "https://st3.depositphotos.com/1007385/13146/i/450/depositphotos_131464896-stock-photo-shovel-and-coal.jpg";
  } else if (image === "house") {
    image1 = house;
  } else if (image === "globe") {
    image1 = globe;
  } else if (image === "megajoule") {
    image1 = megajoule;
  } else if (image === "megajouleaxis") {
    image1 = megaAxis;
  } else if (image === "megajouleAgro") {
    image1 = irrigator2;
  } else if (image === "garlpex") {
    image1 =
      "https://cdn.pixabay.com/photo/2013/07/13/10/23/miner-157100_1280.png";
  } else if (image === "megajouleCoupon") {
    image1 =mega;
    // "https://cdn3d.iconscout.com/3d/premium/thumb/mining-9533389-7705879.png"
  } else if(image === "blackrock"){
    image1=blackstone;
  }else{
    image1 = image
  }

  return (
    <div
      className="gpt3__possibility section__padding bounce-in-top"
      id="possibility"
    >
      <div className="gpt3__possibility-image">
        <img src={image1} alt="possibility" className="bounce-top" style={{width: "90%", height: "70vh"}}/>
      </div>
      <div className="gpt3__possibility-content">
        <h4 >{subheading}</h4>
        <h1 className="gradient__text">{heading}</h1>
        <p>{content}</p>
        {/* <h4>{subheading}</h4> */}
        {linkUrl ? (
          <div className="wrapb">
            <Link
              to={linkUrl}
              style={{
                backgroundImage: "linear-gradient(#c0e13e, #0d111e)",
                color: "white",
                padding: "20px",
                borderRadius: "15px",
                fontSize: "15px"
              }}
            >
              {linkText}
            </Link>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Possibility;
