import React, { createContext, useState } from "react";
import axios from "axios";

const SyndicateContext = createContext();


export function SyndicateProvider({ children }) {
    const [syndicates, setSyndicates] = useState([]);
    const [createSuccess, setCreateSuccess] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [dashboardData, setDashboard] = useState(null);
    const [loading, setLoading]= useState(false)
    const [path, setPath] = useState(null)
    const [agent, setAgent] = useState(null)
   

    const findSyndicates = async (accountNumber, callback) => {
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/syndicate/findAll`,
                {
                    phone: accountNumber
                },
                config
            );


            console.log("Lenght :" + data[0].syndicateName)

            if (data.length === 0) {
                console.log("No Affliates");
                callback(null);
                return null
            } else {
                console.log("Affliates " + data[0]);
                setSyndicates((prevState) => data)
                callback(data);
                return syndicates
            }

        } catch (error) {
            console.log(error);
        }
    }

    async function creatteSyndicate(
        syndicatAccount,
        syndicateName,
        syndicateBio,
        Industry,
        Location,
        phone,
        syndicateEmail
    ) {
        setErrorMessage(null)
        setCreateSuccess(() => false)
        const config = {
            headers: {
                "Content-Type": "application/json",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/syndicateapplication`,
                {
                    syndicatAccount: syndicatAccount,
                    syndicateName: syndicateName,
                    syndicateBio: syndicateBio,
                    Industry: Industry,
                    Location: Location,
                    phone: phone,
                    syndicateEmail: syndicateEmail,
                },
                config
            );

            if (data.Message === "Syndicate Created") {
                setCreateSuccess(() => true);
            } else if (data.Message === "Syndicate already exists") {
                setErrorMessage(() => "Syndicate Exists");
            } else {
                setErrorMessage(() => "Error creating Syndicate");
            }
        } catch (error) {
            setErrorMessage("Error Message" + error);
        }

        // Wait for the component to re-render before returning
        await new Promise((resolve) => {
            setTimeout(resolve, 1000);
        });

        console.log("Create Status" + createSuccess)
        console.log("Message" + errorMessage)
    }

    const getAgent = async (accNumber, callback) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/syndicate/findAgent`,
                {
                    phoneNumber: "+"+accNumber,
                },
                config
            );

            if (data.message ==="User Not Available") {
                setErrorMessage("Agent Not Available");
                console.log("Not Available");
                callback(null);
                setLoading(()=> false)
            }else{
                setAgent(() =>data)
                console.log("Agent Name : "+ data.name)
                callback(data);
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong");
            setLoading(()=> false)
        }
    }

    const getSyndicate = async (item) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/syndicate/get`,
                {
                    user_id: item._id,
                    syndicateAccnt: item.syndicateAccount
                },
                config
            );

            if (data.SyndicateActive) {
                setDashboard(() =>data)
                console.log("SyndicateName : "+ data.syndicateName)
                setLoading(()=> false)
                setPath(()=> "/syndicatePage")
            }else{
                setErrorMessage("Syndicate Deactivated, could be because of subscription");
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }

    const resetSyndicateState = () => {
        setCreateSuccess(false)
        setErrorMessage(null)
    }

    const addToSyndicatte = async(agentAccount, assignedField, syndicatAccount, approvedBy) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/addToSyndicate`,
                {
                    agentAccount: agentAccount,
                    assignedField: assignedField,
                    syndicatAccount: syndicatAccount,
                    approvedBy: approvedBy
                },
                config
            );

            if (data.message==="User Not Found") {
                setErrorMessage("Syndicate Deactivated, could be because of subscription");
                setLoading(()=> false)
           
            }else{
                setDashboard(() =>data)
                console.log("Updated Syndicate: "+ data)
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }

    const addStocks = async(syndicatAccount,agentAccount,item, itemCategory, quantity, description) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/addItem`,
                {
                    syndicatAccount: syndicatAccount,
                    stockedItem: item,
                    category: itemCategory,
                    quantity: quantity,
                    description: description,
                    agentAccount: agentAccount
                },
                config
            );

            if (data.message==="User Not Found") {
                setErrorMessage("Syndicate Deactivated, could be because of subscription");
                setLoading(()=> false)
           
            }else{
                setDashboard(() =>data)
                console.log("Updated Syndicate: "+ data)
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }

    const addProducts = async(syndicatAccount,userPhone,product, itemCategory, quantity, description, urll,megajoule_equivalnce) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/addProduct`,
                {
                    syndicatAccount: syndicatAccount, 
                    product: product, 
                    category: itemCategory, 
                    quantity: quantity, 
                    description: description, 
                    imgUrl: urll, 
                    megaEquivalence: megajoule_equivalnce,
                    agentAccount: userPhone
                },
                config
            );

            if (data.message==="User Not Found") {
                setErrorMessage("Syndicate Deactivated, could be because of subscription");
                setLoading(()=> false)
           
            }else{
                setDashboard(() =>data)
                console.log("Updated Syndicate: "+ data)
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }

    const deleteAgent = async(agentId, syndicatAccount, callback) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/delete/agent`,
                {
                    agentId: agentId,
                    syndicatAccount: syndicatAccount,
                },
                config
            );

            if (data.message==="not updated") {
                setErrorMessage("Failed To Delete");
                setLoading(()=> false)
           
            }else{
                setDashboard(() =>data)
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }

    const deleteStock = async(stockId, syndicatAccount,callback) => {
        setLoading(()=> true)
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(
                `/api/whatbot/delete/stock`,
                {
                    stockId: stockId,
                    syndicatAccount: syndicatAccount,
                },
                config
            );

            if (data.message==="not updated") {
                setErrorMessage("Failed To Delete");
                setLoading(()=> false)
                callback(null)
           
            }else{
                setDashboard(() =>data)
                callback(data)
                setLoading(()=> false)
            }
        } catch (error) {
            setErrorMessage("Something went wrong"+ error.message);
            setLoading(()=> false)
        }
    }
    return (
        <SyndicateContext.Provider value={{
            syndicates, findSyndicates,
            getSyndicate,
            errorMessage, createSuccess, dashboardData,
            creatteSyndicate,
            setLoading,
            loading,
            resetSyndicateState,
            path,
            addToSyndicatte,
            setPath,
            getAgent,
            agent,
            deleteAgent,
            setDashboard,
            addStocks,
            deleteStock,
            addProducts
        }} >
            {children}
        </SyndicateContext.Provider>
    )
}

export default SyndicateContext;