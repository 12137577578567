export const MEGAJEW_LIST_REQUEST = "MEGAJEW_LIST_REQUEST";
export const MEGAJEW_LIST_SUCCESS = "MEGAJEW_LIST_SUCCESS";
export const MEGAJEW_LIST_FAIL = "MEGAJEW_LIST_FAIL";

export const MEGAJEW_DETAILS_REQUEST = "MEGAJEW_DETAILS_REQUEST";
export const MEGAJEW_DETAILS_SUCCESS = "MEGAJEW_DETAILS_SUCCESS";
export const MEGAJEW_DETAILS_FAIL = "MEGAJEW_DETAILS_FAIL";

export const MEGAJEW_DELETE_REQUEST = "MEGAJEW_DELETE_REQUEST";
export const MEGAJEW_DELETE_SUCCESS = "MEGAJEW_DELETE_SUCCESS";
export const MEGAJEW_DELETE_FAIL = "MEGAJEW_DELETE_FAIL";

export const MEGAJEW_CREATE_REQUEST = "MEGAJEW_CREATE_REQUEST";
export const MEGAJEW_CREATE_SUCCESS = "MEGAJEW_CREATE_SUCCESS";
export const MEGAJEW_CREATE_FAIL = "MEGAJEW_CREATE_FAIL";
export const MEGAJEW_CREATE_RESET = "MEGAJEW_CREATE_RESET";

export const MEGAJEW_UPDATE_REQUEST = "MEGAJEW_UPDATE_REQUEST";
export const MEGAJEW_UPDATE_SUCCESS = "MEGAJEW_UPDATE_SUCCESS";
export const MEGAJEW_UPDATE_FAIL = "MEGAJEW_UPDATE_FAIL";
export const MEGAJEW_UPDATE_RESET = "MEGAJEW_UPDATE_RESET";

export const MEGAJEW_CREATE_REVIEW_REQUEST = "MEGAJEW_CREATE_REVIEW_REQUEST";
export const MEGAJEW_CREATE_REVIEW_SUCCESS = "MEGAJEW_CREATE_REVIEW_SUCCESS";
export const MEGAJEW_CREATE_REVIEW_FAIL = "MEGAJEW_CREATE_REVIEW_FAIL";
export const MEGAJEW_CREATE_REVIEW_RESET = "MEGAJEW_CREATE_REVIEW_RESET";

export const MEGAJEW_TOP_REQUEST = "MEGAJEW_TOP_REQUEST";
export const MEGAJEW_TOP_SUCCESS = "MEGAJEW_TOP_SUCCESS";
export const MEGAJEW_TOP_FAIL = "MEGAJEW_TOP_FAIL";
