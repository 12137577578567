import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import { withAuthenticationRequired, useAuth0 } from '@auth0/auth0-react';
import Loader from "../components/Loader"; // Component to show while authentication is in progress

const ProtectedRoute = ({ component, ...args }) => {
  const history = useHistory();
  const { loginWithRedirect } = useAuth0();


  return (
    <Route
      component={withAuthenticationRequired(component, {
        onRedirecting: () => <Loader />,
        onRedirecting: () => loginWithRedirect(), // Trigger login/sign-up redirection
      })}
      {...args}
    />
  );
};

export default ProtectedRoute;
