import {
  ACCOUNT_LIST_MY_REQUEST,
  ACCOUNT_LIST_MY_SUCCESS,
  ACCOUNT_LIST_MY_FAIL,
  ACCOUNT_LIST_MY_RESET,
  ACCOUNT_LIST_REQUEST,
  ACCOUNT_LIST_SUCCESS,
  ACCOUNT_LIST_FAIL,
} from "../constants/accountConstants";

export const myAccountReducer = (state = { account: {} }, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case ACCOUNT_LIST_MY_SUCCESS:
      return {
        loading: false,
        account: action.payload,
      };
    case ACCOUNT_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case ACCOUNT_LIST_MY_RESET:
      return { account: {} };
    default:
      return state;
  }
};

export const listAccountsReducer = (state = { accounts: [] }, action) => {
  switch (action.type) {
    case ACCOUNT_LIST_REQUEST:
      return {
        loading: true,
      };
    case ACCOUNT_LIST_SUCCESS:
      return {
        loading: false,
        accounts: action.payload,
      };
    case ACCOUNT_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
