import React from "react";
import { useDispatch } from "react-redux";
import { RiMenu3Line, RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import { useAuth0 } from '@auth0/auth0-react';
import logo from "../../assets/logo1.png";
import "./navbar.css";

const Navbar = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const Menu = () => (
    <>
      <p>
        <Link to="/" className="nav_link">
          Home
        </Link>
      </p>
      <p>
        <Link to="/commodity/garlpex" className="nav_link">
         Growthhub
        </Link>
      </p>
      <p>
        <Link to="/bazaar/page" className="nav_link">
          Trade
        </Link>
      </p>
      {/* <p>
        <Link to="/housing" classNameName="nav_link">
          Partners
        </Link>
      </p> */}
      <p>
        <Link to="/banking" className="nav_link">
          Housefin
        </Link>
      </p>
    </>
  );

  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>
      {isAuthenticated ? (
        <div className="gpt3__navbar-sign">
          <p>
            <Link to="/profile">
              Profile
            </Link>
          </p>
          <button
            onClick={() => logout({ returnTo: window.location.origin })}
            style={{
              background: "#ff4820",
              color: "white",
              fontSize: "15px",
              borderRadius: "15px",
              padding: "15px",
              fontWeight: "normal",
            }}
          >
            Logout
          </button>
        </div>
      ) : (
        <div className="gpt3__navbar-sign">
          <p>
            <button
              onClick={() => loginWithRedirect()}
              style={{
                background: "#ff4820",
                color: "white",
                fontSize: "15px",
                borderRadius: "15px",
                fontWeight: "normal",
                padding: "15px",
              }}
            >
              Signin
            </button>
          </p>
        </div>
      )}

      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}

        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              <Menu />
              {isAuthenticated ? (
                <div className="gpt3__navbar-menu_container-links-sign">
                  <p>
                    <Link to="/profile">
                      Profile
                    </Link>
                  </p>

                  <button
                    style={{
                      background: "#ff4820",
                      color: "white",
                      fontSize: "15px",
                      borderRadius: "15px",
                      padding: "10px",
                      fontWeight: "normal",
                    }}
                    onClick={() => logout({ returnTo: window.location.origin })}
                  >
                    Logout
                  </button>
                </div>
              ) : (
                <div className="gpt3__navbar-menu_container-links-sign">
                  <p>
                    <Link onClick={() => loginWithRedirect()}>Sign in</Link>
                  </p>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
