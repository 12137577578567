const assets=[
    {
        id: "1",
        name:"Garlpex Investiments Private Limited",
        description: "Garlpex Investiments Limited is a licensed coal mining company established in 2012. The company is situated in Soonga in the North Western part of Matebeleland North Province in Hwange District Zimbabwe. Garlpex Mine has a coverage of 180 hectares of land. The mine has an estimated life span of 100 years. Over the past years the mine has been producing. Garlpex identified an opportunity in the COAL industry due to its high demands for COAL, COKE and its by products. The mine was established with the aim of maximizing profits in the COAL industry to compete favourably with leaders in the industry. We engage in open cast mining with an average production of 40 000 tonnes per month. Garlpex extracts COAL from the ground and processes it into COKING COAL, NUTS, PEAS and COBBLES, ready for sale. Our products found readily available at our local markets as well as international markets",
        address: "Soonga in the North Western part of Matebeleland North Province in Hwange District Zimbabwe",
        profileImage: "https://cdn.pixabay.com/photo/2013/07/13/10/23/miner-157100_1280.png",
        youtubesrc: "https://www.youtube.com/embed/ibJs07ZK0sY?rel=0",
        youtubeTitle: "Coal Mining",
        youtubeDescription: "Crusher at Hwange District Mine, Zimbabwe.",
        swappingAssets: [
                            { 
                                assetName: "Coal",
                                assetProfile: "https://st4.depositphotos.com/2404881/21760/i/600/depositphotos_217604128-stock-photo-shovel-coal-background-coal-mine.jpg",
                                description: "1 tonne",
                                megajouleSwapValue: 20000.00,
                            },
                            {
                                assetName: "Coke",
                                assetProfile: "https://st4.depositphotos.com/8707842/24741/i/600/depositphotos_247413936-stock-photo-natural-coal-black-background-isolated.jpg",
                                description: "1 tonne",
                                megajouleSwapValue: 20000.00,
                            }
                        ]
    },
    {
        id: "2",
        name:"Cleverhill Investiments Private Limited",
        description: "Welcome to Cleverhill Investments Pvt Ltd. With Clients feeling unsatisfied with the services provided, we have come up with a solution to overcome all the short comings within Cleverhills. Our goal is to be the best Real Estate Experts, helping homeowners buy and sell properties. Our team of Real estate Agents will have intimate knowledge of the local market. Now that we know the ins and outs of the Industry, we now understand the market trends, and plan to leverage our relationships with our clients and provide the best deals",
        address: "Soonga in the North Western part of Matebeleland North Province in Hwange District Zimbabwe",
        profileImage: "https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e",
        youtubesrc: "https://www.youtube.com/embed/1hLBCOlptq8?rel=0",
        youtubeTitle: "Cinematic View",
        youtubeDescription: "Double Storey Concerpt Video",
        swappingAssets: [
            { 
                assetName: "Land",
                assetProfile: "https://img.freepik.com/free-photo/beautiful-landscape-delimitation-concept_23-2149721831.jpg?t=st=1710941479~exp=1710945079~hmac=f33e3b6da0b117b8bf6f9d64ec4dbdb7ed85953392d215f778a27e7d4ac6b7ec&w=1800",
                description: "1 tonne",
                megajouleSwapValue: 20000.00,
            },
            {
                assetName: "Cluster",
                assetProfile: "https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e",
                description: "1 tonne ehdehdhedhehedhedhedh",
                megajouleSwapValue: 20000.00,
            }
        ]
    }
]

export default assets