import React, { useState } from "react";
import { Form, Button, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { jewsavePaymentMethod } from "../actions/jewscartActions";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";

const JewPaymentScreen = ({ history }) => {
  const jewscart = useSelector((state) => state.jewscart);
  const { jewshippingAddress } = jewscart;

  if (!jewshippingAddress.address) {
    history.push("/jewshipping");
  }

  const [paymentMethod, setPaymentMethod] = useState("PayPal");

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(jewsavePaymentMethod(paymentMethod));
    history.push("/jewplaceorder");
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          width: "60%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "15px",
        }}
      >
        <CheckoutSteps step1 step2 step3 />
        <h1 style={{ color: "orange", marginBottom: "15px" }}>
          Payment Method To Buy Megajews
        </h1>
        <Form onSubmit={submitHandler} classNameName="detail-card bounce-in-top">
          <Form.Group>
            <Form.Label as="legend">Select Method</Form.Label>
            <Col>
              <Form.Check
                type="radio"
                label="PayPal or Credit Card"
                id="PayPal"
                name="paymentMethod"
                value="PayPal"
                checked
                onChange={(e) => setPaymentMethod(e.target.value)}
              ></Form.Check>
              {/* <Form.Check
              type='radio'
              label='Stripe'
              id='Stripe'
              name='paymentMethod'
              value='Stripe'
              onChange={(e) => setPaymentMethod(e.target.value)}
            ></Form.Check> */}
            </Col>
          </Form.Group>

          <Button type="submit" variant="primary">
            Continue
          </Button>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default JewPaymentScreen;
