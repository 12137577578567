import {
  MEGAJEW_LIST_REQUEST,
  MEGAJEW_LIST_SUCCESS,
  MEGAJEW_LIST_FAIL,
  MEGAJEW_DETAILS_REQUEST,
  MEGAJEW_DETAILS_SUCCESS,
  MEGAJEW_DETAILS_FAIL,
  MEGAJEW_DELETE_REQUEST,
  MEGAJEW_DELETE_SUCCESS,
  MEGAJEW_DELETE_FAIL,
  MEGAJEW_CREATE_RESET,
  MEGAJEW_CREATE_FAIL,
  MEGAJEW_CREATE_SUCCESS,
  MEGAJEW_CREATE_REQUEST,
  MEGAJEW_UPDATE_REQUEST,
  MEGAJEW_UPDATE_SUCCESS,
  MEGAJEW_UPDATE_FAIL,
  MEGAJEW_UPDATE_RESET,
  MEGAJEW_CREATE_REVIEW_REQUEST,
  MEGAJEW_CREATE_REVIEW_SUCCESS,
  MEGAJEW_CREATE_REVIEW_FAIL,
  MEGAJEW_CREATE_REVIEW_RESET,
  MEGAJEW_TOP_REQUEST,
  MEGAJEW_TOP_SUCCESS,
  MEGAJEW_TOP_FAIL,
} from "../constants/megajewConstants";

export const megajewListReducer = (state = { megajews: [] }, action) => {
  switch (action.type) {
    case MEGAJEW_LIST_REQUEST:
      return { loading: true, megajews: [] };
    case MEGAJEW_LIST_SUCCESS:
      return {
        loading: false,
        megajews: action.payload.megajews,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case MEGAJEW_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const megajewDetailsReducer = (
  state = { megajew: { reviews: [] } },
  action
) => {
  switch (action.type) {
    case MEGAJEW_DETAILS_REQUEST:
      return { ...state, loading: true };
    case MEGAJEW_DETAILS_SUCCESS:
      return { loading: false, megajew: action.payload };
    case MEGAJEW_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const megajewDeleteReducer = (state = {}, action) => {
  switch (action.type) {
    case MEGAJEW_DELETE_REQUEST:
      return { loading: true };
    case MEGAJEW_DELETE_SUCCESS:
      return { loading: false, success: true };
    case MEGAJEW_DELETE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const megajewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEGAJEW_CREATE_REQUEST:
      return { loading: true };
    case MEGAJEW_CREATE_SUCCESS:
      return { loading: false, success: true, megajew: action.payload };
    case MEGAJEW_CREATE_FAIL:
      return { loading: false, error: action.payload };
    case MEGAJEW_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const megajewUpdateReducer = (state = { megajew: {} }, action) => {
  switch (action.type) {
    case MEGAJEW_UPDATE_REQUEST:
      return { loading: true };
    case MEGAJEW_UPDATE_SUCCESS:
      return { loading: false, success: true, megajew: action.payload };
    case MEGAJEW_UPDATE_FAIL:
      return { loading: false, error: action.payload };
    case MEGAJEW_UPDATE_RESET:
      return { megajew: {} };
    default:
      return state;
  }
};

export const megajewReviewCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case MEGAJEW_CREATE_REVIEW_REQUEST:
      return { loading: true };
    case MEGAJEW_CREATE_REVIEW_SUCCESS:
      return { loading: false, success: true };
    case MEGAJEW_CREATE_REVIEW_FAIL:
      return { loading: false, error: action.payload };
    case MEGAJEW_CREATE_REVIEW_RESET:
      return {};
    default:
      return state;
  }
};

export const megajewTopRatedReducer = (state = { megajews: [] }, action) => {
  switch (action.type) {
    case MEGAJEW_TOP_REQUEST:
      return { loading: true, megajews: [] };
    case MEGAJEW_TOP_SUCCESS:
      return { loading: false, megajews: action.payload };
    case MEGAJEW_TOP_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
