import React, { createContext, useState } from "react";
import { create } from 'zustand'
import axios from "axios";


export const useProducts = create((set) => ({
    products: [],
    loading: false,
    error: null,
  
    findProducts: async({accountNumber}) => {
        set({loading: true });
        set({error: null });

        const config = {
            headers: {
                     "Content-Type": "application/json; charset=utf-8",
                     },
                 };

      try {
         const { data } = await axios.post( `/api/whatbot/findProducts`, {phone: accountNumber},config );
         if (data.length === 0) {
             set({loading: false});
             set({error:"No products"})
        } else {
            set({loading: false});
            set({products: data});
        }
      } catch (error) {
          set({error:error})
      }
      }
  
  }));
// const ProductContext = createContext();


// export function ProductProvider({ children }) {
//     const [products, setProducts] = useState([]);
//     const [loading, setLoading]= useState(false)
//     const [error, setError] = useState(null)
   

//     const findProducts = async (accountNumber) => {
//         setLoading(()=> true)
//         setError(()=> null)
//         const config = {
//             headers: {
//                 "Content-Type": "application/json; charset=utf-8",
//             },
//         };

//         try {
//             const { data } = await axios.post(
//                 `/api/whatbot/findProducts`,
//                 {
//                     phone: accountNumber
//                 },
//                 config
//             );

//             if (data.length === 0) {
//                 setLoading(()=> false)
//                 setError(()=> "No products")
//                 return null
//             } else {
//                 setProducts((prevState) => data)
//                 setLoading(()=> false)
//                 return products
//             }

//         } catch (error) {
//             console.log(error.message);
//         }
//     }

//     return (
//         <ProductContext.Provider value={{
//             products, findProducts, setLoading, loading, error
//         }} >
//             {children}
//         </ProductContext.Provider>
//     )
// }

// export default ProductContext;