import React, { useState, useEffect } from "react";

import { Form, Button, Row, Col } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../components/Loader";
import FormContainer from "../components/FormContainer";
import { register } from "../actions/userActions";
import { CTA, Brand, Navbar, Slider, Signup } from "../components";

const RegisterScreen = ({ location, history }) => {
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  const redirect = location.search ? location.search.split("=")[1] : "/";

  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  return (
    <>
      <Signup />
    </>
    // <FormContainer>
    //   <h1>Sign Up</h1>
    //   {message && <Message variant="danger">{message}</Message>}
    //   {error && <Message variant="danger">{error}</Message>}
    //   {loading && <Loader />}
    //   <Form onSubmit={submitHandler}>
    //     <Form.Group controlId="name">
    //       <Form.Label>Name</Form.Label>
    //       <Form.Control
    //         type="name"
    //         placeholder="Enter name"
    //         value={name}
    //         onChange={(e) => setName(e.target.value)}
    //       ></Form.Control>
    //     </Form.Group>

    //     <Form.Group controlId="tel">
    //       <Form.Label>Phone</Form.Label>
    //       <Form.Control
    //         type="tel"
    //         placeholder="Enter Phone"
    //         value={phoneNumber}
    //         onChange={(e) => setPhone(e.target.value)}
    //       ></Form.Control>
    //     </Form.Group>

    //     <Form.Group controlId="email">
    //       <Form.Label>Email Address</Form.Label>
    //       <Form.Control
    //         type="email"
    //         placeholder="Enter email"
    //         value={email}
    //         onChange={(e) => setEmail(e.target.value)}
    //       ></Form.Control>
    //     </Form.Group>

    //     <Form.Group controlId="password">
    //       <Form.Label>Password</Form.Label>
    //       <Form.Control
    //         type="password"
    //         placeholder="Enter password"
    //         value={password}
    //         onChange={(e) => setPassword(e.target.value)}
    //       ></Form.Control>
    //     </Form.Group>

    //     <Form.Group controlId="confirmPassword">
    //       <Form.Label>Confirm Password</Form.Label>
    //       <Form.Control
    //         type="password"
    //         placeholder="Confirm password"
    //         value={confirmPassword}
    //         onChange={(e) => setConfirmPassword(e.target.value)}
    //       ></Form.Control>
    //     </Form.Group>

    //     <Button type="submit" variant="primary">
    //       Register
    //     </Button>
    //   </Form>

    //   <Row classNameName="py-3">
    //     <Col>
    //       Have an Account?{" "}
    //       <Link to={redirect ? `/login?redirect=${redirect}` : "/login"}>
    //         Login
    //       </Link>
    //     </Col>
    //   </Row>
    // </FormContainer>
  );
};

export default RegisterScreen;
