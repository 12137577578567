import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast, Toaster } from "react-hot-toast";
import SyndicateContext from "../context/syndicateContext";
import PhoneInput from "react-phone-input-2";
import Loader from "./Loader";

const Stocks = ({ userPhone, close, setclose, showMenu }) => {
    const [name, setName] = useState("");
    const {
        syndicates,
        findSyndicates,
        creatteSyndicate,
        createSuccess,
        errorMessage,
        resetSyndicateState,
        loading,
        setLoading,
        dashboardData,
        path,
        setPath,
        getAgent,
        agent,
        addToSyndicatte,
        deleteAgent,
        setDashboard,
        addStocks,
        deleteStock
    } = useContext(SyndicateContext);

    const [stocks, setStocks] = useState(dashboardData.stocks);
    const [addStock, setStock] = useState(false)
    const [modalDelete, setModalDelete] = useState(false)
    const [delete_id, setDeleteId] = useState(null)

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const results = dashboardData.stocks.filter((stock) => {
                return stock.Stockgained.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setStocks(results);
        } else {
            setStocks(dashboardData.stocks);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    const handleAddStock = () => {
        setStock(true);
    };

    const handleCloseModal = () => {
        setStock(false);
        setModalDelete(false);
    };

    //Show User
    const ShowAddStockModal = ({ modalOpen, handleCloseModal, data }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)
        const [item, setItem] = useState(null)
        const [itemCategory, setItemCategory] = useState(null)
        const [quantity, setQuantity] = useState(null)
        const [description, setDescription] = useState(null)


        if (!modalOpen) {
            return null;
        }

        const addStock = ()=>{
            if(item === null || itemCategory === null || quantity === null || description === null){
                toast.error("Fill in empty fields") 
            }else{
                addStocks(dashboardData.syndicatAccount,userPhone,item, itemCategory, quantity, description)
                toast.success("Added Successfully") 
                setStock(false)
            }
        }

        return (
            <>
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                            <h1 style={{ color: "orange" }}>Stock Entry</h1>
                            <label for="cars" style={{ fontSize: "14px", color: "white" }}>Store your Stock:</label>
                            <input type='text'
                                style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }}
                                placeholder='Item Name'
                                value={item}
                                onChange={(e)=>setItem(e.target.value)}
                            />
                            <input type='text' style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }} placeholder='Item Category' value={itemCategory}
                              onChange={(e)=>setItemCategory(e.target.value)}
                            />
                            <input type='number' style={{ height: '36px', fontSize: "16px", margin: "2px", width: "98%", color: "black" }} placeholder='Quantity'
                              onChange={(e)=>setQuantity(e.target.value)} value={quantity}
                            />
                            <textarea id="decript" name="description" rows="4" cols="50" style={{fontSize: "16px", margin: "2px", width: "98%", color: "black" }}
                              onChange={(e)=>setDescription(e.target.value)} value={description}
                            >
                                Additional Description
                            </textarea>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}
                             onClick={addStock}
                            >
                                Add
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleCloseModal}>
                                Close
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

   
     //Modal For Assigning Field
     const DeleteUser = ({ modalOpen, handleCloseModal, dataTable }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)

        if (!modalOpen) {
            return null;
        }

        const handleDeleteStock = (e) => {
            
            if (dashboardData.stocks.length === 0) {
                toast.error("Nothing to delete");
            } else {
                deleteStock(delete_id, dashboardData.syndicatAccount, (data)=>{
                    if (data !== null) {
                        toast.success("Delete Succefull");
                        setclose(false)
                        setStocks(data.stocks);
                        showMenu(true)
                    }
                })
            }
    
        }

        useEffect(() => {
            if (dashboardData) {
                console.log("Found Syndicate :" + dashboardData)
            }
        }, [stocks, dashboardData]);

        return (
            <>
                {loading && (<Loader />)}
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                            <h1 style={{ color: "orange" }}>Are you sure you want to delete?</h1>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(#3ee169, #09656f)", color: "white" }} onClick={handleCloseModal}
                            >
                                No
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleDeleteStock}>
                                Yes
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

    const handleDelete = (e) => {
        setDeleteId(e)
        setModalDelete(true)
    }
    //  ---------------------------- End Delete Modal -------------------------------//

    useEffect(() => {
        if (dashboardData) {
            console.log("Found Syndicate :" + dashboardData)
        }
    }, [stocks, dashboardData]);
    
    return (
        <div classNameName="containerUsers bounce-in-top">

            <div className="wrap">
                <div className="search">
                    <input type="text" value={name} onChange={filter} className="searchTerm" placeholder="Search Stock Entry?" />
                    <button type="submit" className="searchButton">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <div style={{ marginTop: "5px", marginBottom: "5px" }}>
                <button style={{ width: "20px", float: "right", fontSize: "14px", backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", margin: "1px", padding: "3px", marginTop: "30px" }} onClick={close}>X</button>
                <button style={{ width: "100px", fontSize: "14px", float: "left", backgroundImage: "linear-gradient(#3ee169, #09656f)", margin: "1px", padding: "3px" }} onClick={handleAddStock}>New Stock Entry</button>
            </div>
            <Toaster toastOptions={{ duration: 4000 }} />
            <div classNameName="cardGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                <div classNameName="ContainerGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                    <div classNameName="CardStack">
                        {stocks && stocks.length > 0 ? (
                            stocks.map((stock) => (
                                <div classNameName="Cardgrid" key={stock._id} >
                                    <div classNameName="AvatarWrap">
                                        <img classNameName="Avatar" src="https://img.freepik.com/free-psd/3d-stacks-dollar-coins-with-growth-arrow_23-2148938930.jpg?w=1060&t=st=1699608156~exp=1699608756~hmac=8ec250f8d585e93be35e7ccf6f94ced62adf53521b4deae333708f1a685a709d" alt="" />
                                    </div>
                                    <div classNameName="Title">{stock.Stockgained}</div>
                                    <div classNameName="Description">
                                        Category: {stock.itemCategory} <br />
                                        quantinty: {stock.quantinty} <br />
                                        Agent Account: {stock.agentAccount} <br />
                                        Date : {stock.dateAcquired}
                                    </div>
                                    <div classNameName="Actions">
                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={() => handleDelete(stock._id)}>
                                            Delete
                                        </a>

                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }} >
                                            Options
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h1 style={{ color: "orange" }}>No results found!</h1>
                        )}


                        <ShowAddStockModal modalOpen={addStock} handleCloseModal={handleCloseModal} />
                        <DeleteUser modalOpen={modalDelete} handleCloseModal={handleCloseModal} />
                    </div>

                </div>
            </div>
            <button type="button" style={{
                backgroundImage: "linear-gradient(#3ee169, #09656f)",
                width: "40%", fontSize: "17px", marginLeft: "auto", marginRight: "auto"
            }} >Add User</button>
        </div>
    );
}


export default Stocks;
