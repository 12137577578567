import React, { useState, useEffect } from "react";
import { Table, Button, Row, Col } from "react-bootstrap";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import { LinkContainer } from "react-router-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { getUserDetails, updateUserProfile } from "../actions/userActions";
import { listMyOrders } from "../actions/orderActions";
import { USER_UPDATE_PROFILE_RESET } from "../constants/userConstants";

const AdminScreen = ({ location, history }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userUpdateProfile = useSelector((state) => state.userUpdateProfile);
  const { success } = userUpdateProfile;

  const orderListMy = useSelector((state) => state.orderListMy);
  const { loading: loadingOrders, error: errorOrders, orders } = orderListMy;

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    } else {
      if (!user || !user.name || success) {
        dispatch({ type: USER_UPDATE_PROFILE_RESET });
        dispatch(getUserDetails("profile"));
        dispatch(listMyOrders());
      } else {
        setName(user.name);
        setEmail(user.email);
      }
    }
  }, [dispatch, history, userInfo, user, success]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      dispatch(updateUserProfile({ id: user._id, name, email, password }));
    }
  };

  return (
    // <>
    //   {/* <Breadcrumb
    //     style={{
    //       backgroundColor: "#F5E8C7",
    //       padding: "3px",
    //       marginBottom: "10px",
    //     }}
    //   >
    //     <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
    //     <Breadcrumb.Item href="/shopping">Shop</Breadcrumb.Item>
    //     <Breadcrumb.Item href="/profile">Profile</Breadcrumb.Item>
    //     <Breadcrumb.Item href="/bankingHall">Banking Hall</Breadcrumb.Item>
    //   </Breadcrumb> */}
    //   <Row>
    //     <Col md={3} style={{ marginTop: "3rem" }}>
    //       <div classNameName="containr">
    //         <div classNameName="card">
    //           <div classNameName="card-content">
    //             <div classNameName="image">
    //               <img
    //                 src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/3d/Fesoj_-_Papilio_machaon_%28by%29.jpg/220px-Fesoj_-_Papilio_machaon_%28by%29.jpg"
    //                 alt="profile pic"
    //               />
    //             </div>
    //             <div classNameName="text-center" style={{ marginTop: "1rem" }}>
    //               <h2>{userInfo.name}</h2>
    //             </div>
    //             Email:{" "}
    //             <span
    //               style={{
    //                 color: "green",
    //                 marginBottom: "3px",
    //                 marginTop: "5rem",
    //               }}
    //             >
    //               {userInfo.email}
    //             </span>
    //             <div classNameName="stats">
    //               <div classNameName="stat1">
    //                 <span>132</span>
    //                 <span>MJT</span>
    //               </div>

    //               <div classNameName="stat1">
    //                 <span>16</span>
    //                 <span>Orders</span>
    //               </div>

    //               <div classNameName="stat1">
    //                 <span>80%</span>
    //                 <span>Compliance</span>
    //               </div>
    //             </div>
    //             <div classNameName="buttons">
    //               <button classNameName="follow">Profile</button>

    //               <Link
    //                 to="/myaccount"
    //                 classNameName="message btn"
    //                 style={{ fontWeight: "normal", color: "lightgrey" }}
    //               >
    //                 Account
    //               </Link>
    //             </div>
    //           </div>
    //         </div>
    //       </div>

    //       {/* <h2>User Profile</h2>
    //       {message && <Message variant="danger">{message}</Message>}
    //       {}
    //       {success && <Message variant="success">Profile Updated</Message>}
    //       {loading ? (
    //         <Loader />
    //       ) : error ? (
    //         <Message variant="danger">{error}</Message>
    //       ) : (
    //         <Form onSubmit={submitHandler}>
    //           <Form.Group controlId="name">
    //             <Form.Label>Name</Form.Label>
    //             <Form.Control
    //               type="name"
    //               placeholder="Enter name"
    //               value={name}
    //               onChange={(e) => setName(e.target.value)}
    //             ></Form.Control>
    //           </Form.Group>

    //           <Form.Group controlId="email">
    //             <Form.Label>Email Address</Form.Label>
    //             <Form.Control
    //               type="email"
    //               placeholder="Enter email"
    //               value={email}
    //               onChange={(e) => setEmail(e.target.value)}
    //             ></Form.Control>
    //           </Form.Group>

    //           <Form.Group controlId="password">
    //             <Form.Label>Password</Form.Label>
    //             <Form.Control
    //               type="password"
    //               placeholder="Enter password"
    //               value={password}
    //               onChange={(e) => setPassword(e.target.value)}
    //             ></Form.Control>
    //           </Form.Group>

    //           <Form.Group controlId="confirmPassword">
    //             <Form.Label>Confirm Password</Form.Label>
    //             <Form.Control
    //               type="password"
    //               placeholder="Confirm password"
    //               value={confirmPassword}
    //               onChange={(e) => setConfirmPassword(e.target.value)}
    //             ></Form.Control>
    //           </Form.Group>

    //           <Button type="submit" variant="primary">
    //             Update
    //           </Button>
    //         </Form>
    //       )} */}
    //     </Col>

    //     <Col md={3}>
    //       <div classNameName="contanr" style={{ margin: "0" }}>
    //         <div classNameName="card1">
    //           <div classNameName="image1">
    //             <img src="/images/order1.jpeg" alt="All Orders" />
    //           </div>
    //           <div classNameName="content" style={{ textAlign: "center" }}>
    //             <h3>All Orders</h3>
    //             <Link
    //               to="/profile"
    //               style={{
    //                 fontSize: "15px",
    //                 fontWeight: "normal",
    //               }}
    //               classNameName="btn btn-outline-success"
    //             >
    //               Megajoule Orders
    //             </Link>
    //             {userInfo && userInfo.isAdmin && (
    //               <>
    //                 <h5>Admin Options</h5>
    //                 <Link
    //                   to="/admin/jeworderlist"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   Users Megajoule Orders
    //                 </Link>
    //                 <br />
    //                 <Link
    //                   to="/admin/orderlist"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   Users Product Orders
    //                 </Link>
    //                 <br />
    //                 <Link
    //                   to="/admin/productlist"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   View Products
    //                 </Link>
    //               </>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </Col>

    //     <Col md={3}>
    //       <div classNameName="contanr" style={{ margin: "0" }}>
    //         <div classNameName="card1">
    //           <div classNameName="image1">
    //             <img src="/images/account.jpeg" alt="Account Services" />
    //           </div>
    //           <div classNameName="content" style={{ textAlign: "center" }}>
    //             <h3>Account Services</h3>
    //             <Link
    //               to="/myaccount"
    //               style={{
    //                 fontSize: "15px",
    //                 fontWeight: "normal",
    //               }}
    //               classNameName="btn btn-outline-success"
    //             >
    //               Joule Account
    //             </Link>
    //             {userInfo && userInfo.isAdmin && (
    //               <>
    //                 <h5>Admin Options</h5>
    //                 <Link
    //                   to="/admin/userlist"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   User Accounts
    //                 </Link>
    //                 <br />
    //                 <Link
    //                   to="/admin/listAccounts"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   Users Joule Accounts
    //                 </Link>
    //                 <br />
    //                 <Link
    //                   to="/admin/megajewlist"
    //                   style={{
    //                     fontSize: "15px",
    //                     fontWeight: "normal",
    //                   }}
    //                   classNameName="btn btn-outline-success"
    //                 >
    //                   View MegaJoules
    //                 </Link>
    //               </>
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </Col>

    //     <Col md={3}>
    //       <div classNameName="contanr" style={{ margin: "0" }}>
    //         <div classNameName="card1">
    //           <div classNameName="image1">
    //             <img src="/images/properties.jpeg" alt="Account Services" />
    //           </div>
    //           <div classNameName="content" style={{ textAlign: "center" }}>
    //             <h3>Housing</h3>
    //             <Link
    //               to="/shopping"
    //               style={{
    //                 fontSize: "15px",
    //                 fontWeight: "normal",
    //               }}
    //               classNameName="btn btn-outline-success"
    //             >
    //               Property Compliance
    //             </Link>
    //           </div>
    //         </div>
    //       </div>
    //     </Col>

    //     <Col md={9}>
    //       <div
    //         style={{
    //           width: "100%",
    //           backgroundColor: "orange",
    //           marginTop: "1rem",
    //           boxShadow: "23px 17px 64px -14px rgba(90,87,87,0.75)",
    //           borderRadius: "15px",
    //         }}
    //       >
    //         <h1 style={{ color: "white", textAlign: "center" }}> My Orders </h1>
    //       </div>

    //       <div
    //         style={{
    //           width: "100%",
    //           backgroundColor: "white",
    //           marginTop: "0.5rem",
    //           boxShadow: "23px 17px 64px -14px rgba(90,87,87,0.75)",
    //           borderRadius: "15px",
    //           padding: "1rem",
    //         }}
    //       >
    //         {loadingOrders ? (
    //           <Loader />
    //         ) : errorOrders ? (
    //           <Message variant="danger">{errorOrders}</Message>
    //         ) : (
    //           <Table striped bordered hover responsive classNameName="table-sm">
    //             <thead>
    //               <tr>
    //                 <th>ID</th>
    //                 <th>DATE</th>
    //                 <th>TOTAL</th>
    //                 <th>PAID</th>
    //                 <th>DELIVERED</th>
    //                 <th></th>
    //               </tr>
    //             </thead>
    //             <tbody
    //               style={{
    //                 overflowX: "auto",
    //                 maxHeight: "200vh",
    //                 overflowY: "auto",
    //               }}
    //             >
    //               {orders.map((order) => (
    //                 <tr key={order._id}>
    //                   <td>{order._id}</td>
    //                   <td>{order.createdAt.substring(0, 10)}</td>
    //                   <td>{order.totalPrice}</td>
    //                   <td>
    //                     {order.isPaid ? (
    //                       order.paidAt.substring(0, 10)
    //                     ) : (
    //                       <i
    //                         classNameName="fas fa-times"
    //                         style={{ color: "red" }}
    //                       ></i>
    //                     )}
    //                   </td>
    //                   <td>
    //                     {order.isDelivered ? (
    //                       order.deliveredAt.substring(0, 10)
    //                     ) : (
    //                       <i
    //                         classNameName="fas fa-times"
    //                         style={{ color: "red" }}
    //                       ></i>
    //                     )}
    //                   </td>
    //                   <td>
    //                     <LinkContainer to={`/order/${order._id}`}>
    //                       <Button classNameName="btn-sm" variant="light">
    //                         Details
    //                       </Button>
    //                     </LinkContainer>
    //                   </td>
    //                 </tr>
    //               ))}
    //             </tbody>
    //           </Table>
    //         )}
    //       </div>
    //     </Col>
    //   </Row>
    // </>
    <>
      <main className="contentD">
        <section className="containerdashb">
          <div className="dashboard">
            <div className="user">
              <img
                src="https://randomuser.me/api/portraits/women/44.jpg"
                alt=""
              />
              <h3>{userInfo.name}</h3>
              <p>{userInfo.email}</p>
            </div>
            <div className="links">
              <Link to="/" style={{ padding: "0" }}>
                <div className="link">
                  <img
                    src="https://cdn2.iconfinder.com/data/icons/custom-ios-14-1/60/Home-256.png"
                    alt=""
                  />
                  <h2>Home</h2>
                </div>
              </Link>

              <div className="link">
                <img
                  src="https://cdn0.iconfinder.com/data/icons/business-startup-10/50/46-256.png"
                  alt=""
                />
                <h2>Chat</h2>
              </div>
              <div className="link">
                <img
                  src="https://cdn2.iconfinder.com/data/icons/money_icons/PNG/512x512/Money_Bag.png"
                  alt=""
                />
                <h2>Tokens</h2>
              </div>
              <Link to="/profile" style={{ padding: "0" }}>
                <div className="link">
                  <img
                    src="https://cdn1.iconfinder.com/data/icons/unicons-line-vol-2/24/create-dashboard-256.png"
                    alt=""
                  />
                  <h2>Back</h2>
                </div>
              </Link>
            </div>
            <div className="pro">
              <h2>Get Megajoule Tokens</h2>
              <img
                src="https://cdn4.iconfinder.com/data/icons/finance-and-banking-free/64/Finance_loan_money-256.png"
                alt=""
              />
            </div>
          </div>
          <div className="games">
            <div className="status">
              <h1>Admin</h1>
              <input type="text" />
            </div>
            <div className="cards_dashboard">
              <div className="card_dashboard">
                <img
                  src="https://cdn2.iconfinder.com/data/icons/new_google_product_icons_by_carlosjj-dwke/128/partner_search.png"
                  alt=""
                />
                <div className="card-info">
                  <h2>Become a Partner</h2>
                  <p>Now</p>
                  <div className="progress"></div>
                </div>
                <h2 className="percentage">60%</h2>
              </div>
              {userInfo && userInfo.isAdmin && (
                <Link to="/admin/userlist" style={{ padding: "0" }}>
                  <div className="card_dashboard">
                    <img
                      src="https://cdn1.iconfinder.com/data/icons/unicons-line-vol-6/24/users-alt-256.png"
                      alt=""
                    />
                    <div className="card-info">
                      <h2>All User</h2>
                      <p>Details</p>
                      <div className="progress"></div>
                    </div>
                    <h2 className="percentage">60%</h2>
                  </div>
                </Link>
              )}

              {userInfo && userInfo.isAdmin && (
                <div className="card_dashboard">
                  <img
                    src="https://cdn0.iconfinder.com/data/icons/ie_Financial_set/256/02.png"
                    alt=""
                  />
                  <div className="card-info">
                    <h2>Megajoule Orders</h2>
                    <p>Details</p>
                    <div className="progress"></div>
                  </div>
                  <h2 className="percentage">60%</h2>
                </div>
              )}

              {userInfo && userInfo.isAdmin && (
                <div className="card_dashboard">
                  <img
                    src="https://cdn1.iconfinder.com/data/icons/lottery-5/64/cash_coins_currency_dollar_finance-256.png"
                    alt=""
                  />
                  <div className="card-info">
                    <h2>Available Megajoules</h2>
                    <p>Details</p>
                    <div className="progress"></div>
                  </div>
                  <h2 className="percentage">60%</h2>
                </div>
              )}

              <div className="card_dashboard">
                <img
                  src="https://imagizer.imageshack.com/img922/226/5jOqTc.png"
                  alt=""
                />
                <div className="card-info">
                  <h2>Plans Approval</h2>
                  <p>Details</p>
                  <div className="progress"></div>
                </div>
                <h2 className="percentage">60%</h2>
              </div>
            </div>
          </div>
        </section>
      </main>
      <div className="circle1"></div>
      <div className="circle2"></div>
    </>
  );
};

// <div className="dashboardBackgroung">
// <div className="containerDashboard">
//   <div className="dashboard">
//     <div className="dashboard-header">
//       <div id="greeting" className="dashboard-header__title">
//         Hi {userInfo.name}!
//       </div>
//       <div id="time" className="dashboard-header__time">
//         X:XX PM
//       </div>
//       <div id="date" className="dashboard-header__date">
//         Loading...
//       </div>
//     </div>
//     <div
//       className="dashboard-card weather"
//       style={{
//         backgroundImage:
//           "url('https://cdn.pixabay.com/photo/2017/09/07/08/53/money-2724235_1280.jpg')",
//         backgroundSize: "100% 100%",
//         backgroundRepeat: "no-repeat",
//       }}
//     >
//       {userInfo && userInfo.isAdmin && (
//         <>
//           <div className="dashboard-card__title">
//             <span
//               className="wi wi-day-sunny"
//               style={{
//                 color: "white",
//                 backgroundColor: "orange",
//                 padding: "10px",
//               }}
//             >
//               Admin Options
//             </span>
//           </div>
//           <div className="dashboad-card__content">
//             <div className="dashboard-card__card-piece">
//               <div className="weather-days">
//                 <div className="weather-days__item">
//                   <div className="weather-days__title"></div>
//                   <div className="weather-days__icon">
//                     <span className="wi wi-day-cloudy"></span>
//                   </div>
//                   <div className="weather-days__humidity"></div>
//                 </div>
//                 <div className="weather-days__item">
//                   <Link
//                     to="/admin/userlist"
//                     style={{
//                       fontSize: "15px",
//                       fontWeight: "normal",
//                     }}
//                     classNameName="dashboardlinks"
//                   >
//                     <div className="weather-days__title">Users</div>
//                   </Link>

//                   <div className="weather-days__icon">
//                     <span className="wi wi-cloudy"></span>
//                   </div>
//                   <div className="weather-days__humidity"></div>
//                 </div>
//                 <div className="weather-days__item">
//                   <Link
//                     to="/admin/megajewlist"
//                     style={{
//                       fontSize: "15px",
//                       fontWeight: "normal",
//                     }}
//                     classNameName="dashboardlinks"
//                   >
//                     <div className="weather-days__title">Megajoule</div>
//                   </Link>

//                   <div className="weather-days__icon">
//                     <span className="wi wi-day-rain"></span>
//                   </div>
//                   <div className="weather-days__humidity"></div>
//                 </div>
//                 <div className="weather-days__item">
//                   <div className="weather-days__title"></div>
//                   <div className="weather-days__icon">
//                     <span className="wi wi-day-rain"></span>
//                   </div>
//                   <div className="weather-days__humidity"></div>
//                 </div>
//                 <div className="weather-days__item">
//                   <div className="weather-days__title"></div>
//                   <div className="weather-days__icon">
//                     <span className="wi wi-day-sunny"></span>
//                   </div>
//                   <div className="weather-days__humidity"></div>
//                 </div>
//               </div>
//               <a href="#" className="dashboard-card__link" tabindex="1">
//                 View in details<span className="fa fa-angle-right"></span>
//               </a>
//             </div>
//           </div>
//         </>
//       )}
//     </div>
//     <div className="dashboard-card temperature">
//       <div className="dashboard-card__title">
//         <span className="wi wi-thermometer"></span>
//       </div>
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="temperature-stats">
//             <Link to="/" style={{ padding: "15px" }}>
//               <div className="stats__item">
//                 <div className="stats__title">HOME</div>
//                 <div className="stats__icon">
//                   <span className="fa fa-home"></span>
//                 </div>

//               </div>
//             </Link>

//           </div>
//           <a id="modal-btn" className="dashboard-card__link" tabindex="1">
//             Edit<span className="fa fa-angle-right"></span>
//           </a>

//           <div id="modal" className="modal">

//             <div className="modal-content">
//               <span className="close">&times;</span>
//               <div className="dashboard-header__time">HVAC Control</div>
//               <p>HVAC CONTROLS IN DASHBOARD CARD.</p>
//               <div className="dashboard-card hvac">
//                 <div className="dashboard-card__title">
//                   <span className="fa fa-thermometer"></span>Downstairs
//                   Ecobee
//                 </div>
//                 <div className="dashboard-card__content">
//                   <div className="dashboard-card__card-piece">
//                     <div className="hvac__temperatures">
//                       <div className="hvac__current-temp">
//                         Current Temp: 71&deg;F
//                       </div>
//                       <div className="hvac__set-temp">
//                         Set Temperature: 75&deg;F
//                       </div>
//                     </div>
//                     <div className="hvac__mode-status">
//                       <div className="hvac__mode">Heat</div>
//                       <div className="hvac__status">On</div>
//                     </div>
//                     <div className="hvac__controls">
//                       <div className="hvac__control-btn fan-on">Fan On</div>
//                       <div className="hvac__control-btn fan-auto">
//                         Fan Auto
//                       </div>
//                       <div className="hvac__control-btn home">Home</div>
//                       <div className="hvac__control-btn away">Away</div>
//                       <div className="hvac__control-btn sleep">Sleep</div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="dashboard-card wifi">
//       <div className="dashboard-card__title">
//         <span className="fa-thin fa-wallet"></span>Megajoule
//       </div>
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="status status_success">
//             <div className="status__icon">
//               <span className="fa fa-check"></span>
//             </div>
//             <div className="status__text">Activated</div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="1">
//             Edit<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//       </div>
//     </div>
//     <div className="dashboard-card alarm">
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="status status_danger">
//             <div className="status__icon">
//               <span className="fa fa-times"></span>
//             </div>
//             <div className="status__text">Disabled</div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="1">
//             Edit<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//       </div>
//     </div>
//     <div
//       className="dashboard-card light"
//       style={{
//         backgroundImage:
//           "url('https://cdn.pixabay.com/photo/2018/01/24/17/33/light-bulb-3104355_960_720.jpg')",
//         backgroundRepeat: "no-repeat",
//         backgroundSize: "100% 100%",
//       }}
//     >
//       <div className="dashboard-card__title">
//         <span className="fa fa-lightbulb-o"></span>My Orders
//       </div>
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="light-switches">
//             <div
//               className="switch switch_order-1"
//               style={{
//                 backgroundImage:
//                   "url('https://imagizer.imageshack.com/img923/8807/PG2Rue.png')",
//                 backgroundSize: "300px 200px",
//                 backgroundRepeat: "no-repeat",
//                 marginTop: "10px",
//               }}
//             >
//               <div className="stats__item">

//                 <div className="stats__icon">
//                   <span className="fa fa-flash"></span>
//                 </div>
//                 <div className="stats__measure">
//                   <div className="stats__value">.</div>
//                   <div className="stats__unit stats__unit_power">

//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="switch switch_order-2"
//               style={{
//                 backgroundImage:
//                   "url('https://imagizer.imageshack.com/img923/4152/axVAFe.png')",
//                 backgroundSize: "300px 200px",
//                 backgroundRepeat: "no-repeat",
//                 marginTop: "10px",
//               }}
//             >
//               <div className="stats__item">

//                 <div className="stats__icon">
//                   <span className="fa fa-flash"></span>
//                 </div>
//                 <div className="stats__measure">
//                   <div className="stats__value">.</div>
//                   <div className="stats__unit stats__unit_power">

//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="switch switch_order-1"
//               style={{
//                 backgroundImage:
//                   "url('https://imagizer.imageshack.com/img922/226/5jOqTc.png')",
//                 backgroundSize: "300px 200px",
//                 backgroundRepeat: "no-repeat",
//                 marginTop: "10px",
//               }}
//             >
//               <div className="stats__item">

//                 <div className="stats__icon">
//                   <span className="fa fa-flash"></span>
//                 </div>
//                 <div className="stats__measure">
//                   <div className="stats__value">.</div>
//                   <div className="stats__unit stats__unit_power">

//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div
//               className="switch switch_order-2"
//               style={{
//                 backgroundImage:
//                   "url('https://imagizer.imageshack.com/img922/3411/fc5Z0S.png')",
//                 backgroundSize: "300px 200px",
//                 backgroundRepeat: "no-repeat",
//                 marginTop: "10px",
//               }}
//             >
//               <div className="stats__item">

//                 <div className="stats__icon">
//                   <span className="fa fa-flash"></span>
//                 </div>
//                 <div className="stats__measure">
//                   <div className="stats__value">.</div>
//                   <div className="stats__unit stats__unit_power">

//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//     <div className="dashboard-card power">
//       <div className="dashboard-card__title">
//         <span className="fa fa-bar-chart"></span>MEGAJOULES
//       </div>
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">BALANCE</div>
//             <div className="stats__icon">
//               <span className="wi wi-raindrop"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_meter">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">SENT</div>
//             <div className="stats__icon">
//               <span className="fa fa-flash"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_power">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">RECEIVED</div>
//             <div className="stats__icon">
//               <span className="fa fa-fire"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_meter">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//       </div>
//     </div>

//     <div className="dashboard-card power">
//       <div className="dashboard-card__title">
//         <span className="fa fa-bar-chart"></span>MEGAJOULES
//       </div>
//       <div className="dashboad-card__content">
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">BALANCE</div>
//             <div className="stats__icon">
//               <span className="wi wi-raindrop"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_meter">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">SENT</div>
//             <div className="stats__icon">
//               <span className="fa fa-flash"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_power">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//         <div className="dashboard-card__card-piece">
//           <div className="stats__item">
//             <div className="stats__title">RECEIVED</div>
//             <div className="stats__icon">
//               <span className="fa fa-fire"></span>
//             </div>
//             <div className="stats__measure">
//               <div className="stats__value">0.0</div>
//               <div className="stats__unit stats__unit_meter">MJ</div>
//             </div>
//           </div>
//           <a href="#" className="dashboard-card__link" tabindex="4">
//             View in details<span className="fa fa-angle-right"></span>
//           </a>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>

export default AdminScreen;
