import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Image,
  ListGroup,
  Card,
  Button,
  Form,
} from "react-bootstrap";
import Rating from "../components/Rating";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Meta from "../components/Meta";
import { Footer } from "../containers";
import { Navbar } from "../components";

import {
  listMegajewDetails,
  createMegajewReview,
} from "../actions/megajewActions";
import { MEGAJEW_CREATE_REVIEW_RESET } from "../constants/megajewConstants";

const MegajewScreen = ({ history, match }) => {
  const [qty, setQty] = useState(1);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const dispatch = useDispatch();

  const megajewDetails = useSelector((state) => state.megajewDetails);
  const { loading, error, megajew } = megajewDetails;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const megajewReviewCreate = useSelector((state) => state.megajewReviewCreate);
  const {
    success: successMegajewReview,
    loading: loadingMegajewReview,
    error: errorMegajewReview,
  } = megajewReviewCreate;

  useEffect(() => {
    if (successMegajewReview) {
      setRating(0);
      setComment("");
    }
    if (!megajew._id || megajew._id !== match.params.id) {
      dispatch(listMegajewDetails(match.params.id));
      dispatch({ type: MEGAJEW_CREATE_REVIEW_RESET });
    }
  }, [dispatch, match, successMegajewReview]);

  const addToCartHandler = () => {
    history.push(`/jewscart/${match.params.id}?qty=${qty}`);
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createMegajewReview(match.params.id, {
        rating,
        comment,
      })
    );
  };

  return (
    <>
      <Navbar />
      <Link classNameName="btn btn-light my-3" to="/">
        Go Back
      </Link>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Meta title={megajew.name} />
          <Row>
            <Col md={6} classNameName="detail-card bounce-in-top">
              <Image src={megajew.image} alt={megajew.name} fluid />
            </Col>
            <Col md={3} classNameName="detail-card bounce-in-top">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>{megajew.name}</h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Rating
                    value={megajew.rating}
                    text={`${megajew.numReviews} reviews`}
                  />
                </ListGroup.Item>
                <ListGroup.Item>Price: ${megajew.price}</ListGroup.Item>
                <ListGroup.Item>
                  Description: {megajew.description}
                </ListGroup.Item>
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card classNameName="detail-card bounce-in-top">
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong>${megajew.price}</strong>
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        {megajew.countInStock > 0 ? "In Stock" : "Out Of Stock"}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {megajew.countInStock > 0 && (
                    <ListGroup.Item classNameName="detail-card bounce-in-top">
                      <Row>
                        <Col>Qty</Col>
                        <Col>
                          <Form.Control
                            as="select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            {[...Array(megajew.countInStock).keys()].map(
                              (x) => (
                                <option key={x + 1} value={x + 1}>
                                  {x + 1}
                                </option>
                              )
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item>
                    <Button
                      onClick={addToCartHandler}
                      classNameName="btn-block"
                      type="button"
                      disabled={megajew.countInStock === 0}
                    >
                      Add To Cart
                    </Button>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <Row classNameName="detail-card bounce-in-top">
            <Col md={6}>
              <h2>Reviews</h2>
              {megajew.reviews.length === 0 && <Message>No Reviews</Message>}
              <ListGroup variant="flush">
                {megajew.reviews.map((review) => (
                  <ListGroup.Item key={review._id}>
                    <strong>{review.name}</strong>
                    <Rating value={review.rating} />
                    <p>{review.createdAt.substring(0, 10)}</p>
                    <p>{review.comment}</p>
                  </ListGroup.Item>
                ))}
                <ListGroup.Item classNameName="detail-card bounce-in-top">
                  <h2>Write a Customer Review</h2>
                  {successMegajewReview && (
                    <Message variant="success">
                      Review submitted successfully
                    </Message>
                  )}
                  {loadingMegajewReview && <Loader />}
                  {errorMegajewReview && (
                    <Message variant="danger">{errorMegajewReview}</Message>
                  )}
                  {userInfo ? (
                    <Form onSubmit={submitHandler} style={{ width: "100%" }}>
                      <Form.Group controlId="rating">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={rating}
                          onChange={(e) => setRating(e.target.value)}
                        >
                          <option value="">Select...</option>
                          <option value="1">1 - Poor</option>
                          <option value="2">2 - Fair</option>
                          <option value="3">3 - Good</option>
                          <option value="4">4 - Very Good</option>
                          <option value="5">5 - Excellent</option>
                        </Form.Control>
                      </Form.Group>
                      <Form.Group controlId="comment">
                        <Form.Label>Comment</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="3"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                          style={{ width: "500px", height: "150px" }}
                        ></Form.Control>
                      </Form.Group>
                      <Button
                        disabled={loadingMegajewReview}
                        type="submit"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message>
                      Please <Link to="/login">sign in</Link> to write a review{" "}
                    </Message>
                  )}
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </>
      )}
      <Footer />
    </>
  );
};

export default MegajewScreen;
