import React from "react";
import "./article.css";

const Article = ({ imgUrl, date, text, click, id }) => (
  <div className="gpt3__blog-container_article">
    <div className="gpt3__blog-container_article-image">
      <img src={imgUrl} alt="blog_image" />
    </div>
    <div className="gpt3__blog-container_article-content">
      <div>
        <p>{date}</p>
        <h3>{text}</h3>
      </div>
      {id && (
        <button style={{background: 'black', borderRadius: "20px", fontSize: "14px"}} onClick={()=>click(id)}>Profile</button>
      )}
    </div>
  </div>
);

export default Article;
