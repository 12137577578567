import React, { useRef, useState, useEffect } from "react";
import { Footer, Possibility, Features, Header, WhatGPT3 } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import { Link } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from "react-router-dom";
import { auth } from "../firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { useUserContext } from "../context/userContext";
import { BsFillShieldLockFill, BsTelephoneFill } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import OtpInput from "otp-input-react";
import PhoneInput from "react-phone-input-2";
import axios from "axios";
import mega from "../assets/mega.png";
import rental from "../assets/Rental.png";
import housefin from "../assets/housefin2.png";
import housefinlogo from "../assets/housefinlogo1.png";
import megajouleToken from "../assets/Token.png";
import Loader from "../components/Loader";
import "./css/housefin.css";
import './css/authentication.css'




const BankingHallScreen = ({ match }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory();
  const { isAuthenticated, user, getAccessTokenSilently } = useAuth0();
  const [personalData, setPersonalData] = useState("none");
  const [userAccessToken, setUserAccessToken] = useState(null);
  const [showPhone, setShowPhone] = useState(false);
  const [approved, setApproved] = useState(false);
  const [balancee, setBalance] = useState(0);
  const [transactions, setTransactions] = useState([]);


  
  const sendPhone = async (phoneNumber, userEmail) => {
    if (isAuthenticated) {
      try {
        const accessToken = await getAccessTokenSilently();
        console.log('Fetching Access Token:', accessToken);
        const response = await fetch(`/identification/phone`, {
          method: 'POST', // Change the method to POST
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ phoneNumber, userEmail }), // Send phone number and email in the request body
        });

        const data = await response.json();
        console.log('Response data:', data);
      } catch (error) {
        console.error('Error saving user phone:', error.message);
      }
    }
  };

  const Authentication = () => {
    const [otp, setOtp] = useState("");
    const [ph, setPh] = useState("");
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pageloading, setPageLoading] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    // const [user, setUser] = useState(null);
    const { userr, signInUser, forgotPassword } = useUserContext();
    useEffect(() => {
      if (userr) {
        // console.log("USER DATE :", userr)
        // history.push("/");
      }
    });

    function onCaptchVerify() {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": () => { },
        });
      }
    }

    function onSignup() {
      setLoading(true);
      onCaptchVerify();

      const appVerifier = window.recaptchaVerifier;

      const formatPh = "+" + ph;

      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setLoading(false);
          setShowOTP(true);
          toast.success("OTP sended successfully!");
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }

    function onOTPVerify() {
      setLoading(true);
      window.confirmationResult
        .confirm(otp)
        .then(async (res) => {
          console.log(res);
          setApproved(true);
          sendPhone("+" + ph, user.email);
          setShowPhone(false)
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    }

    return (
      <>
        {pageloading ? (<Loader />) : (

          <section className="bg-emerald-500 flex items-center justify-center h-screen">
            <div style={{ backgroundColor: "rgba(4, 0, 3, 0.5)", width: "100%" }}>
              <Toaster toastOptions={{ duration: 4000 }} />
              <div id="recaptcha-container" style={{ zIndex: "30", position: "absolute" }}></div>
              {approved ? (
                <h2 className="text-center text-white font-medium text-2xl" style={{
                  textAlign: 'center',
                  color: 'white',
                  fontSize: "font-size: 1.5rem"
                }}>
                  {/* Message to User */}
                </h2>
              ) : (
                <>
                  <div className="vid-container">
                    <video class="bgvid" autoplay="autoplay" muted="muted" preload="auto" loop>
                      <source src="https://videocdn.cdnpk.net/joy/content/video/free/video0471/large_preview/_import_619c37dd58d742.19860068.mp4?filename=1111736_bills_counting_profit_3840x2160.mp4" type="video/webm" />
                    </video>
                    <div class="inner-container">
                      <video class="bgvid inner" autoplay="autoplay" muted="muted" preload="auto" loop>
                        <source src="https://videocdn.cdnpk.net/joy/content/video/free/video0471/large_preview/_import_619c37dd58d742.19860068.mp4?filename=1111736_bills_counting_profit_3840x2160.mp4" type="video/webm" />
                      </video>
                      <div class="box">
                        {showOTP ? (
                          <>
                            <div className="bg-white text-emerald-500 w-fit mx-auto p-4 rounded-full"
                              style={{
                                backgroundColor: "white",
                                color: "orange",
                                width: "60%",
                                maxWidth: "auto",
                                borderRadius: "9999px"
                              }}>
                              <BsFillShieldLockFill size={30} />
                            </div>
                            <label
                              htmlFor="otp"
                              className="font-bold text-xl text-white text-center"
                              style={{ textAlign: 'center', marginLeft: "30px", fontSize: "40px" }}
                            >
                              Enter your OTP
                            </label>
                            <OtpInput
                              value={otp}
                              onChange={setOtp}
                              OTPLength={6}
                              otpType="number"
                              disabled={false}
                              autoFocus
                              className="opt-container "
                            ></OtpInput>
                            <button
                              onClick={onOTPVerify}
                              className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                              style={{
                                backgroundColor: "orange", display: "flex", gapWidth: '0.25rem',
                                alignItems: "center", justifyContent: "center", borderRadius: "0.25rem"
                                , paddingTop: "0.625rem", paddingBottom: "0.625rem"
                              }}
                            >
                              {loading && (
                                <CgSpinner size={20} className="mt-1 animate-spin" />
                              )}
                              <span>Verify OTP</span>
                            </button>
                          </>
                        ) : (
                          <>
                            <h1 style={{ color: "orange" }}>HOUSEFIN ACCOUNT</h1>
                            {/* <BsTelephoneFill size={30} style={{ marginLeft: "30px" }} /> */}
                            <label
                              htmlFor=""
                              style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.25rem", marginBottom: "60px", paddingLeft: "20px" }}
                            >
                              Register a Housefin mobile account number.
                            </label>
                            <PhoneInput country={"zw"} value={ph} onChange={setPh} style={{ width: "74%" }} />
                            <button
                              onClick={onSignup}
                              className="bg-emerald-600 w-full flex gap-1 items-center justify-center py-2.5 text-white rounded"
                              style={{ backgroundColor: "orange" }}
                            >
                              {loading && (
                                <CgSpinner size={20} className="mt-1 animate-spin" />
                              )}
                              <span>Verify Mobile Number</span>
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        )}

      </>

    );
  };

  const FruitCard = () => {
    const [num, setNum] = useState(1);
    const price = 7;
  
    const handleIncrement = () => {
      const newNum = num + 1;
      setNum(newNum);
      updatePrice(newNum, price);
    };
  
    const handleDecrement = () => {
      const newNum = num - 1;
      if (newNum > -1) {
        setNum(newNum);
        updatePrice(newNum, price);
      }
    };
    
    const handleInputChange=(e)=>{
      // alert();
      setNum(e.target.value)
      updatePrice(e.target.value,price)
    }
    const updatePrice = (num, price) => {
      const total = num * price;
      document.querySelector('.amount').innerText = total;
    };
  
    return (
      <div className="container">
        <img src={megajouleToken} alt="Strawberry" className="img-fruit" />
        <h3>Get Megajoules</h3>
        <div className="control-btn">
          <span className="prev">
            <i className="fa fa-long-arrow-left"></i>
          </span>
          <span className="next">
            <i className="fa fa-long-arrow-right"></i>
          </span>
        </div>
        <p>
          The garden strawberry is a widely grown hybrid species of the genus Fragaria. It is cultivated worldwide for its fruit.
        </p>
        <div className="properties">
          <div className="qty">
            <h4>Quantity</h4>
            <i className="fa fa-minus" onClick={handleDecrement}></i>
            {/* <span className="number">{num}</span> */}
            <input type="number" className="number" value={num} onChange={(e)=>handleInputChange(e)}/>
            <i className="fa fa-plus" onClick={handleIncrement}></i>
          </div>
          <div className="price">
            <h4>Total Price</h4>
            <span className="price-inr">
              <i className="fa fa-usd"></i>
              <span className="amount">7</span>
            </span>
          </div>
          <div className="delivery">
            <h4>Price per Megajoule</h4>
            <i className="fa fa-usd"></i>
            <span className="time">In USD</span>
          </div>
        </div>
        <input className="ip-add-cart" type="button" value="Purchase" />
      </div>
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (isAuthenticated) {
        try {
          const accessToken = await getAccessTokenSilently();
          setUserAccessToken(accessToken);
          console.log('Fetching Access Token:', accessToken);
          const response = await fetch(`/identification`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const data = await response.json();
          console.log('Fetching data:', data);
          setPersonalData(data.updatedUser);
          setBalance(data.updatedUser.megajoule)
          if (data.message === 'No Phone') {
            console.log("No phone has be found")
            console.log("USER DATA:", personalData);
            setShowPhone(true)
          }
        } catch (error) {
          console.error('Error fetching user data:', error.message);
        }
      }
    };

    fetchData();
  }, [isAuthenticated, user]);

  return (
    <>
      {loading && (<Loader />)}
      {!showPhone && (
        <>
          <div className="gradieant__bg">
            <Navbar />
            <Header
              heading="Housefin Private Ltd"
              content="Housefin's role as a registered none deposit taking financial institution brings credibility and efficiency to the asset swap ecosystem. As a broker, Housefin ensures transparency, security, and compliance, fostering trust among megajoule holders seeking to exchange underlying assets. This partnership opens doors to a seamless and regulated asset swap experience.Housefin marks a significant step towards democratizing access to high-potential assets and fostering financial inclusion. This innovative approach not only enhances liquidity and flexibility but also empowers investors to unlock value, drive growth, and shape the future of wealth creation in the digital economy."
              image="https://cdn.pixabay.com/photo/2018/01/22/12/08/investment-3098806_1280.png"
            />
          </div>
          {/* <Wallet /> */}
          <Possibility
            subheading="Blackstone"
            heading="Blackstone Properties 42CC"
            content="Blackstone Properties specializes in offering investment opportunities tailored to individuals seeking rental properties with the added flexibility of potential purchase options. This unique approach allows clients to enjoy the benefits of renting while maintaining the opportunity to transition into property ownership at a later stage, providing a flexible pathway towards long-term wealth building and real estate ownership.Blackstone Properties, headquartered in South Africa, stands as a pioneering force in the realm of real estate investment, offering a dynamic blend of innovation and flexibility.
             Through strategic partnerships, such as its collaboration with Housefin, Blackstone is reshaping the landscape of property investment by introducing a unique model that extends beyond traditional rental arrangements.
             At the core of Blackstone's approach is the commitment to providing clients with not only housing solutions but also opportunities in various sectors, including mining equipment and beyond. This holistic vision reflects the company's dedication to meeting diverse needs and fostering growth across industries.
             By teaming up with Housefin, a leading financial institution renowned for its expertise in innovative financing solutions, Blackstone Properties has forged a formidable alliance aimed at expanding access to rental properties with purchase options. This partnership harnesses the strengths of both entities to deliver unparalleled value to investors and tenants alike.
             Whether individuals aspire to own their dream home, acquire essential mining equipment, or venture into other fields, Blackstone Properties and Housefin offer a comprehensive suite of services designed to empower clients on their journey towards financial prosperity. Through flexible lease-to-own arrangements and tailored investment strategies, they pave the way for individuals to realize their aspirations and build a brighter future."
            image="blackrock"
          />

          <h1 style={{ color: "white", textAlign: "center", fontSize: "35px" }}>FINANCIAL SERVICES</h1>

          <div className="cardminerals-list" style={{ width: "80%", marginLeft: "auto", marginRight: "auto", marginBottom: "50px" }}>
            <div
              className="cardminerals 1"
              style={{ marginBottom: "120px" }}
            >
              <div className="card_image">
                <img src={housefin} />
              </div>
              <div className="card_title title-white">
                <p
                  style={{
                    color: "#FFAF45",
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  ASSET MANAGEMENT

                </p>
              </div>
            </div>
            <div
              className="cardminerals 1"
              style={{ marginBottom: "120px" }}
            >
              <div className="card_image">
                <img src={housefin} />
              </div>
              <div className="card_title title-white">
                <p
                  style={{
                    color: "#FFAF45",
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  COMMODITY EXCHANGE
                </p>
              </div>
            </div>
            <div
              className="cardminerals 1"
              style={{ marginBottom: "120px" }}
            >
              <div className="card_image">
                <img src={rental} />
              </div>
              <div className="card_title title-white">
                <p
                  style={{
                    color: "#FFAF45",
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "20px",
                  }}
                >
                  RENTAL WITH OPTION TO PURCHASE

                </p>
              </div>
            </div>
          </div>

        </>
      )}


      {showPhone && (<Authentication />)}
      {!showPhone && (
        <>
          <Possibility
            subheading="Megajoule Token"
            heading="Introducing the Megajoule Coupon"
            content="It is a dividend-bearing security token signifying a fractional portion in an underlying asset, providing contributors with a stake in the returns generated by the asset. It's important to emphasize that owning these tokens does not convey direct ownership of the asset; instead, they serve as a financial instrument enabling contributors to receive a portion of the contribution payback. By tokenizing growth-seeking assets into megajoules, contributers gain access to a dynamic marketplace where asset swaps play a pivotal role in optimizing portfolios. Housefin, a None Deposit Taking Financial Institution, acts as a facilitator, connecting swappers and streamlining the exchange process for enhanced liquidity and strategic partnerships. Housefin's role as a registered Microfinance bank brings credibility and efficiency to the asset swap ecosystem. As a broker, Housefin ensures transparency, security, and compliance, fostering trust among megajoule holders seeking to exchange underlying assets. This partnership opens doors to a seamless and regulated asset swap experience."
            image={megajouleToken}
          />
          <FruitCard />
          <Footer />
        </>
      )}
    </>
  );
};

export default BankingHallScreen;
