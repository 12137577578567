import React from "react";
import { Feature } from "../../components";
import { Link } from "react-router-dom";
import "./whatGPT3.css";

const WhatGPT3 = ({
  featureTitle,
  featureText,
  heading,
  headingText,
  featureTitle_i,
  featureText_i,
  featureTitle_ii,
  featureText_ii,
  featureTitle_iii,
  featureText_iii,
  modalActive
}) => {
  return (
    <div
      className={`gpt3__whatgpt3 section__margin detail-card bounce-in-top ${modalActive ? 'modal-active' : ''}`}
      id="wgpt3"
    >
      <div className="gpt3__whatgpt3-feature">
        <Feature
          title={featureTitle} //"What are Megajoules?"
          text={featureText} //"Is a unit of energy used to denominate relative values of commodities."
        />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">
          {heading}
          {/* {} The possibilities are beyond your imagination */}
        </h1>
        {/* Explore Megajoules */}
        <p style={{marginLeft: "20px"}}>{headingText}</p>
        <div className="gpt3__cta-btn"></div>
      </div>
      <div className="gpt3__whatgpt3-container">
        <Feature
          // "Minerals Market"
          title={featureTitle_i}
          // "Use Megajoules to perform trade commodities such as Minerals on MIAMI"
          text={featureText_i}
        />
        <Feature
          // "Transfer Value"
          title={featureTitle_ii}
          // "Use Megajoules to trade with other Member's Portfolios"
          text={featureText_ii}
        />
        <Feature
          //  "Trade Commodities"
          title={featureTitle_iii}
          //"Use Megajoules to perform do trade with other members registered with MIAMI"
          text={featureText_iii}
        />
      </div>
    </div>
  );
};

export default WhatGPT3;
