import React, { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";
import { useLocation } from "react-router-dom";
import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Services,
} from "../containers";
import irrigator1 from "../assets/Irrigator1.jpeg";
import irrigator2 from "../assets/Irrigator2.jpeg";
import { CTA, Brand, Navbar, Agropower } from "../components";
import Loader from "../components/Loader";

// import gpt3Logo from '../assets/logo1.png';

const AgroPowerScreen = () => {
  const location = useLocation();
  const data = location.state;
  const [show, setShow] = useState(false);
  const [pageLoad, setPageLoad] = useState(false)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageLoad(true);
     
    setTimeout(() => {
      setPageLoad(false);
    }, 2000);
}, []);

  return (
    <>
     {pageLoad && (<Loader />)}
      <div classNameName="gradieant__bg">
        <Navbar />
        <Header
          heading="Agro-Power"
          content="Agro Power is a new company that is focused on empowering farmers with technology. We believe that technology can help farmers to improve their productivity, efficiency, and profitability.
          We offer a variety of products and services that can help farmers, including:
          Drones: Our drones can be used to survey fields, monitor crops, and apply pesticides and fertilizers.
          Irrigators: Our irrigators can be used to water crops efficiently and effectively.
          Software: Our software can be used to manage farms, track crop yields, and forecast weather patterns.
          We are committed to providing farmers with the tools they need to succeed. We believe that technology can help farmers to feed the world and create a more sustainable future."
          image="agropower"
        />
      </div>
      {/* <Brand /> */}
      {/* <CardExpand /> */}

      <h1 style={{ color: "orange", textAlign: "center" }}>Look at some of our Videos</h1>
      <div className="containerrr bounce-in-top">
        <div className="img-pop fix">
          <img className="img-hover" src={irrigator1} alt="" style={{height: "72%"}}/>
          {/* <a href="https://cdn.pixabay.com/photo/2016/07/16/13/24/coal-1521718_1280.jpg" className="btn">&#x271C; </a> */}
        </div>

        <div className="video2 fix">
          <img classNameName={show ? "popup" : "img-hover"} src={irrigator2} alt="" style={{height: "70%"}}/>
          <a className="btn2"> <img src="https://cdn.pixabay.com/photo/2016/02/01/12/20/play-1173495__340.png" style={{ backgroundColor: "white" }} classNameName={show ? "popup" : "img-hover"} alt="" onClick={() => setShow(true)} /> </a>
          <div classNameName={show ? "activePopup" : "popup"} style={{ margin: "30px", borderColor: "orange" }}>
            <button style={{ backgroundColor: "red", color: "white", width: "5%", borderRadius: "20%" }} onClick={() => setShow(false)} classNameName="bounce-in-top">X</button>
            <iframe
              title="Garlpex"
              classNameName="bounce-in-top youtube"
              width="791"
              height="445"
              style={{ width: "80%" }}
              src="https://www.youtube.com/embed/DnPidcSgMwY"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>

      {/* <div classNameName="body">
        <iframe
          title="Smart Irrigation"
          classNameName="bounce-in-top"
          width="791"
          height="445"
          style={{ width: "80%" }}
          src="https://www.youtube.com/embed/Bgfl39G6Dzo?rel=0"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div> */}
      <Possibility
        subheading="Request Early Access"
        heading="Smart Irrigation Solution"
        content=" Irrigation in rural areas today is dominated by manually controlled flood and bucket irrigation leading to uneven distribution of water with attendant problems such as hard pans and salinity in ferruginous soils. For  traditionally disadvantaged and emerging commercial farmers financing of irrigation equipment is expensive and a challenge because of theft of components. Farming equipment powered by the national grid is not an option for most  traditionally disadvantaged and emerging commercial farmers in rural settlements as access to the national grid is difficult and very expensive. Agriculture contributes significantly to the development of the African 
        continent hence several governments and other organizations are projecting on expanding agricultural system that will control the funding channels and the functionality of the irrigator unit to be solely dependent on the monthly subscriptions made by farmers to generate revenue for the company and to pay back the loans issued by agricultural development banks, governments and other organizations."
        image="megajouleAgro"
      />
      {/* <h1 style={{ textAlign: "center", color: "orange" }}>
        Place your Order{" "}
      </h1> */}
      {/* <Agropower /> */}
     
      {/* <iframe
        width="560"
        height="315"
        src="https://www.youtube.com/embed/Bgfl39G6Dzo?controls=0"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe> */}
      <WhatGPT3
        featureTitle="Garlpex Trading Hall"
        featureText="Provides customers with variety of minerals to select from"
        heading="The possibilities are beyond your imagination"
        headingText="Explore services"
        featureTitle_i="Market"
        featureText_i="After signing up and placing an order you get an quotation emailed to you for the order "
        featureTitle_ii="Business Management"
        featureText_ii="To ensure a vibrant business with achievable smart goals Garlpex has a team of qualified and dedicated workers
        with different organisational responsibilities and duties"
        featureTitle_iii="Energy"
        featureText_iii="A key challenge to development is shortage of electrical energy. On average Zimbabwe generate 30% of the required 2200 MW 
        The balance has to made up by imports or outright loadshading. The unavailable energy means that critical inputs, such as fertilizer, diesel, mechanical, chemical and other inputs
        can not be manufactured or imported timeously. The net result is low productivity and reduced incomes for the populous. Garlpex is therefore commited to harnessing alterantive forms of energy for
        application in appropriate technological environments to inhance productivity."
      />

      <Footer />
    </>
  );
};

export default AgroPowerScreen;
