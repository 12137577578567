import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { Footer } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import axios from "axios";

const PlansApprovalScreen = () => {
  const fileInputRef = useRef(null);
  const handleFileUpload = async () => {
    const files = fileInputRef.current.files;

    if (files.length > 0) {
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
      }

      try {
        const response = await fetch("/api/upload/approvalPlans", {
          method: "POST",
          body: formData,
        });

        const data = await response.json();
        console.log("Upload Files : ", data.files);
      } catch (error) {
        console.log("error");
      }
    }
  };

  return (
    <>
      <Navbar />
      <div>
        {/* <div>
        <h1>Upload Multiple Files to Google Drive</h1>
        <input type="file" multiple ref={fileInputRef} />
        <button onClick={handleFileUpload}>Upload Files</button>
      </div> */}
        <aside className="aside aside-open">
          <form action="">
            <button
              id="aside-btn"
              className="aside-toggle aside-toggle-open"
              type="button"
            >
              <span className="sr">Toggle Panel</span>
            </button>
            <label for="img_upload" style={{ color: "white" }}>
              Upload Plan for Approval
            </label>
            <div className="upload-btn">
              <input
                id="img_upload"
                name="img_upload"
                type="file"
                multiple
                ref={fileInputRef}
                // accept="image/*"
              />
              <button
                for="img_upload"
                type="button"
                tabindex="-1"
                title="Upload"
              >
                <svg
                  viewBox="0 0 512 512"
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="m182.461 155.48 49.539-49.539v262.059a24 24 0 0 0 48 0v-262.059l49.539 49.539a24 24 0 1 0 33.941-33.941l-90.509-90.51a24 24 0 0 0 -33.942 0l-90.509 90.51a24 24 0 1 0 33.941 33.941z" />
                  <path d="m464 232a24 24 0 0 0 -24 24v184h-368v-184a24 24 0 0 0 -48 0v192a40 40 0 0 0 40 40h384a40 40 0 0 0 40-40v-192a24 24 0 0 0 -24-24z" />
                </svg>
              </button>
              <input
                id="img_name"
                name="img_name"
                type="text"
                placeholder="Select files "
                disabled
              />
            </div>
            <button id="reset-btn" type="button" onClick={handleFileUpload}>
              Upload
            </button>
          </form>
        </aside>
        {/* <Footer /> */}
      </div>
    </>
  );
};

export default PlansApprovalScreen;
