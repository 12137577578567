import React from 'react'
import './css/tradinghall.css';


const TradingHallScreen = () => {





  return (
    <div>
      <div className="container">
        {/* <!-- Header Page --> */}
        <header>
          {/* <!-- Logo --> */}
          <div className="header-logo">
            <h1>Store</h1>
          </div>
          {/* <!-- Nav Links --> */}
          <div className="header-nav">
            <li className="header-favoriet">
              <i className='bx bx-heart'></i>
              <span className="favbadge"><span>0</span></span>
            </li>
            <li className="header-cart">
              <i className='bx bx-shopping-bag'></i>
              <span className="badge"><span>0</span></span>
            </li>
          </div>
        </header>
        {/* <!-- End Header --> */}

        {/* <!-- Search Area --> */}
        <div className="search-area">
          <h2>Search To Product</h2>
          <input type="text" className="inputSearch" placeholder="Product Name" />
        </div>
        {/* <!-- End Search --> */}

        {/* <!-- Products Area --> */}
        <div className="products-area"></div>
        {/* <!-- Alarm For No Search  --> */}
        <div className="noSearchAlarm">
          ! No products were found matching your selection.
        </div>
        {/* <!-- End Products Area --> */}

        {/* <!-- Cart Container --> */}
        <div className="cart-container">
          {/* <!-- Cart Header --> */}
          <div className="cart-header">
            {/* <!-- Heading Text --> */}
            <h2>Your Cart</h2>
            {/* <!-- Close Cart Button --> */}
            <div className="closeCart-btn">
              <div className="close-cart-action">
                <i className='bx bxs-up-arrow-alt bx-tada' ></i>
                <span>Close</span>
              </div>
            </div>
          </div>

          {/* <!-- No Product Yet Alarm Box --> */}
          <div className="noProductsInCart">
            <i className="bx bx-shopping-bag bx-tada"></i>
            <h3>No Products Added To The Cart Yet !</h3>
            <button onclick="closeWindow(cartConatiner)">Return To Shop</button>
          </div>

          {/* <!-- In Cart Area --> */}
          <div className="inCart-area">
            {/* <!-- Data Will Be Come From The JavaScript (: --> */}
          </div>
          {/* <!-- In Cart End --> */}

          {/* <!-- Controls --> */}
          <div className="inCart-controls">
            {/* <!-- Total Price Box --> */}
            <div className="totalPrice-box">
              <span>Total Price: $0</span>
            </div>
            {/* <!-- Checkout Button --> */}
            <div className="checkout-btn">
              <span>Checkout</span>
            </div>
          </div>
        </div>
        {/* <!-- End Cart Container --> */}

        {/* <!-- Start In Favoriet --> */}
        <div className="favorites-container">
          {/* <!-- Favorites Header --> */}
          <div className="favorites-header">
            {/* <!-- Heading Text --> */}
            <h2>Your Favorites</h2>
            {/* <!-- Close Favorites Button --> */}
            <div className="closeFavorites-btn">
              <div className="close-Favorites-action">
                <i className='bx bxs-up-arrow-alt bx-tada' ></i>
                <span>Close</span>
              </div>
            </div>
          </div>

          {/* <!-- No Favorites Yet Alarm Box --> */}
          <div className="noFavorites">
            <i className="bx bx-heart bx-tada"></i>
            <h3>No Favorites Added To The Favorites Yet !</h3>
            <button onclick="closeWindow(favoritesConatiner)">Return To Shop</button>
          </div>

          {/* <!-- In Favorites Area --> */}
          <div className="inFavorites-area">
            {/* <!-- Data Will Be Come From The JavaScript (: --> */}
          </div>
        </div>
        {/* <!-- End Favoriet --> */}

        {/* <!-- Start Footer (: --> */}
        <footer>
          <div className="footer-info">
            <span>
              Made With
              <i className='bx bxs-heart bx-tada'></i>
              By
              <a href="https://www.instagram.com/0k1lx" target="_blanck" title="Instagram">Ali</a>
            </span>
          </div>
        </footer>
        {/* <!-- End  Footer --> */}
      </div>
      {/* <!-- End Conatiner --> */}

      {/* <!-- Primary Area --> */}
      <div className="primary-area">
        <h2>Primary Color</h2>
        {/* <!-- Content Area --> */}
        <div className="primary-content">
          {/* <!-- Primary Colors Box --> */}
          <div className="primary-colors-box">
            <span data-primary="rgb(80, 216, 144)"></span>
            <span data-primary="rgb(103, 58, 183)"></span>
            <span data-primary="rgb(0, 102, 255)"></span>
            <span data-primary="rgb(233, 30, 99)"></span>
          </div>
          {/* <!-- Toogle Button --> */}
          <div className="show-PrimaryBox-Btn">
            <i className='bx bxs-color-fill bx-tada'></i>
          </div>
        </div>
      </div>
      <div className="primary-overflow"></div>
      {/* <!-- End Primary Area --> */}

    </div>
  )
}


export default TradingHallScreen