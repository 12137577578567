import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import ReactPhoneInput from "react-phone-input-2";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import Notification from "../notification/Notification";
// import Meta from "../components/Meta";

const Agropower = () => {
  const [country, setCountry] = useState("");
  const [product, setProduct] = useState("");
  const [quantity, setQuantity] = useState("");
  const alert = useAlert();

  let messages;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderItem = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (country === "") {
      alert.show("Provide a country");
      // setMessage("");
    } else if (product === "") {
      alert.show("Product field is empty");
      return;
    } else if (quantity === "") {
      alert.show("Quantity field is empty");
      return;
    }

    // const { data } = await axios.post(`/api/orders`, order, config)
    const { data } = await axios.post(
      `/api/agropowerorders`,
      { country, product, quantity },
      config
    );
    if (data) {
      alert.show(
        "Thank you for placing your order. MIAMI will get back to you shortly. Check Email for Invoice!"
      );
      closeForm();
    }
  };

  //Scroll and Focus
  const handleClickScroll = () => {
    const element = document.getElementById("formOrder");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  let history = useHistory();
  const routeChange = () => {
    let path = `/login`;
    history.push(path);
  };

  const [showResults, setShowResults] = React.useState(false);
  const onClick = () => setShowResults(true);

  const closeForm = () => setShowResults(false);

  return (
    <>
      <div className="cardminerals-list bounce-in-top" style={{ width: "94%" }}>
        <div
          className="cardminerals 1"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            {" "}
            <img src="https://imagizer.imageshack.com/v2/735x348q70/924/4LuO46.jpg" />{" "}
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            >
              CHIVUNGUVUNGU
            </p>
          </div>
        </div>

        <div
          className="cardminerals 2"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://imagizer.imageshack.com/v2/735x348q70/924/cLnfdH.jpg" />
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            ></p>
          </div>
        </div>
        <div
          className="cardminerals 2"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://imagizer.imageshack.com/v2/185x391q70/923/gee3YK.jpg" />
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            ></p>
          </div>
        </div>
        {/* <div
          className="cardminerals 2"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://st2.depositphotos.com/1008404/5970/i/600/depositphotos_59707589-stock-photo-copper-background.jpg" />
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            >
              COPPER
            </p>
          </div>
        </div> */}

        {/* <div
          className="cardminerals 1"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://st4.depositphotos.com/1006643/22038/i/600/depositphotos_220389810-stock-photo-pink-lepidolite-mica-mineral-hoydalen.jpg" />
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            >
              LITHIUM
            </p>
          </div>
        </div>
        <div
          className="cardminerals 2"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://st2.depositphotos.com/1077338/12254/i/600/depositphotos_122541932-stock-photo-native-limonite-stone-isolated-on.jpg" />
          </div>
          <div className="card_title title-white">
            <p
              style={{
                color: "white",
              }}
            >
              IRON
            </p>
          </div>
        </div> */}
        <div
          className="cardminerals 4"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://media.giphy.com/media/LwIyvaNcnzsD6/giphy.gif" />
          </div>
          <div className="card_title title-black"></div>
        </div>
        {/* Form Begin */}
        {showResults ? (
          <div id="formOrder">
            <div className="form bounce-top" style={{ margin: "auto" }}>
              {/* <div className="title">Welcome</div> */}
              <div className="subtitle" style={{ fontSize: "30px" }}>
                Order Now{" "}
                <button
                  classNameName="close-Button"
                  style={{
                    color: "white",
                    backgroundColor: "orangered",
                    padding: "6px",
                    borderRadius: "20px",
                    width: "60px",
                    marginLeft: "40%",
                  }}
                  onClick={closeForm}
                >
                  x
                </button>
              </div>

              <div>
                <label for="country-select" className="placeholder">
                  Choose Country:
                </label>

                <select
                  name="pets"
                  id="country-select"
                  classNameName="input-container ic2"
                  style={{
                    backgroundColor: "white",
                    color: "grey",
                    borderRadius: "12px",
                    border: "0",
                    padding: "5px",
                  }}
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option>Select Country</option>
                  <option value="AF">Afghanistan</option>
                  <option value="AX">Aland Islands</option>
                  <option value="AL">Albania</option>
                  <option value="DZ">Algeria</option>
                  <option value="AS">American Samoa</option>
                  <option value="AD">Andorra</option>
                  <option value="AO">Angola</option>
                  <option value="AI">Anguilla</option>
                  <option value="AQ">Antarctica</option>
                  <option value="AG">Antigua and Barbuda</option>
                  <option value="AR">Argentina</option>
                  <option value="AM">Armenia</option>
                  <option value="AW">Aruba</option>
                  <option value="AU">Australia</option>
                  <option value="AT">Austria</option>
                  <option value="AZ">Azerbaijan</option>
                  <option value="BS">Bahamas</option>
                  <option value="BH">Bahrain</option>
                  <option value="BD">Bangladesh</option>
                  <option value="BB">Barbados</option>
                  <option value="BY">Belarus</option>
                  <option value="BE">Belgium</option>
                  <option value="BZ">Belize</option>
                  <option value="BJ">Benin</option>
                  <option value="BM">Bermuda</option>
                  <option value="BT">Bhutan</option>
                  <option value="BO">Bolivia</option>
                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                  <option value="BA">Bosnia and Herzegovina</option>
                  <option value="BW">Botswana</option>
                  <option value="BV">Bouvet Island</option>
                  <option value="BR">Brazil</option>
                  <option value="IO">British Indian Ocean Territory</option>
                  <option value="BN">Brunei Darussalam</option>
                  <option value="BG">Bulgaria</option>
                  <option value="BF">Burkina Faso</option>
                  <option value="BI">Burundi</option>
                  <option value="KH">Cambodia</option>
                  <option value="CM">Cameroon</option>
                  <option value="CA">Canada</option>
                  <option value="CV">Cape Verde</option>
                  <option value="KY">Cayman Islands</option>
                  <option value="CF">Central African Republic</option>
                  <option value="TD">Chad</option>
                  <option value="CL">Chile</option>
                  <option value="CN">China</option>
                  <option value="CX">Christmas Island</option>
                  <option value="CC">Cocos (Keeling) Islands</option>
                  <option value="CO">Colombia</option>
                  <option value="KM">Comoros</option>
                  <option value="CG">Congo</option>
                  <option value="CD">
                    Congo, Democratic Republic of the Congo
                  </option>
                  <option value="CK">Cook Islands</option>
                  <option value="CR">Costa Rica</option>
                  <option value="CI">Cote D'Ivoire</option>
                  <option value="HR">Croatia</option>
                  <option value="CU">Cuba</option>
                  <option value="CW">Curacao</option>
                  <option value="CY">Cyprus</option>
                  <option value="CZ">Czech Republic</option>
                  <option value="DK">Denmark</option>
                  <option value="DJ">Djibouti</option>
                  <option value="DM">Dominica</option>
                  <option value="DO">Dominican Republic</option>
                  <option value="EC">Ecuador</option>
                  <option value="EG">Egypt</option>
                  <option value="SV">El Salvador</option>
                  <option value="GQ">Equatorial Guinea</option>
                  <option value="ER">Eritrea</option>
                  <option value="EE">Estonia</option>
                  <option value="ET">Ethiopia</option>
                  <option value="FK">Falkland Islands (Malvinas)</option>
                  <option value="FO">Faroe Islands</option>
                  <option value="FJ">Fiji</option>
                  <option value="FI">Finland</option>
                  <option value="FR">France</option>
                  <option value="GF">French Guiana</option>
                  <option value="PF">French Polynesia</option>
                  <option value="TF">French Southern Territories</option>
                  <option value="GA">Gabon</option>
                  <option value="GM">Gambia</option>
                  <option value="GE">Georgia</option>
                  <option value="DE">Germany</option>
                  <option value="GH">Ghana</option>
                  <option value="GI">Gibraltar</option>
                  <option value="GR">Greece</option>
                  <option value="GL">Greenland</option>
                  <option value="GD">Grenada</option>
                  <option value="GP">Guadeloupe</option>
                  <option value="GU">Guam</option>
                  <option value="GT">Guatemala</option>
                  <option value="GG">Guernsey</option>
                  <option value="GN">Guinea</option>
                  <option value="GW">Guinea-Bissau</option>
                  <option value="GY">Guyana</option>
                  <option value="HT">Haiti</option>
                  <option value="HM">Heard Island and Mcdonald Islands</option>
                  <option value="VA">Holy See (Vatican City State)</option>
                  <option value="HN">Honduras</option>
                  <option value="HK">Hong Kong</option>
                  <option value="HU">Hungary</option>
                  <option value="IS">Iceland</option>
                  <option value="IN">India</option>
                  <option value="ID">Indonesia</option>
                  <option value="IR">Iran, Islamic Republic of</option>
                  <option value="IQ">Iraq</option>
                  <option value="IE">Ireland</option>
                  <option value="IM">Isle of Man</option>
                  <option value="IL">Israel</option>
                  <option value="IT">Italy</option>
                  <option value="JM">Jamaica</option>
                  <option value="JP">Japan</option>
                  <option value="JE">Jersey</option>
                  <option value="JO">Jordan</option>
                  <option value="KZ">Kazakhstan</option>
                  <option value="KE">Kenya</option>
                  <option value="KI">Kiribati</option>
                  <option value="KP">
                    Korea, Democratic People's Republic of
                  </option>
                  <option value="KR">Korea, Republic of</option>
                  <option value="XK">Kosovo</option>
                  <option value="KW">Kuwait</option>
                  <option value="KG">Kyrgyzstan</option>
                  <option value="LA">Lao People's Democratic Republic</option>
                  <option value="LV">Latvia</option>
                  <option value="LB">Lebanon</option>
                  <option value="LS">Lesotho</option>
                  <option value="LR">Liberia</option>
                  <option value="LY">Libyan Arab Jamahiriya</option>
                  <option value="LI">Liechtenstein</option>
                  <option value="LT">Lithuania</option>
                  <option value="LU">Luxembourg</option>
                  <option value="MO">Macao</option>
                  <option value="MK">
                    Macedonia, the Former Yugoslav Republic of
                  </option>
                  <option value="MG">Madagascar</option>
                  <option value="MW">Malawi</option>
                  <option value="MY">Malaysia</option>
                  <option value="MV">Maldives</option>
                  <option value="ML">Mali</option>
                  <option value="MT">Malta</option>
                  <option value="MH">Marshall Islands</option>
                  <option value="MQ">Martinique</option>
                  <option value="MR">Mauritania</option>
                  <option value="MU">Mauritius</option>
                  <option value="YT">Mayotte</option>
                  <option value="MX">Mexico</option>
                  <option value="FM">Micronesia, Federated States of</option>
                  <option value="MD">Moldova, Republic of</option>
                  <option value="MC">Monaco</option>
                  <option value="MN">Mongolia</option>
                  <option value="ME">Montenegro</option>
                  <option value="MS">Montserrat</option>
                  <option value="MA">Morocco</option>
                  <option value="MZ">Mozambique</option>
                  <option value="MM">Myanmar</option>
                  <option value="NA">Namibia</option>
                  <option value="NR">Nauru</option>
                  <option value="NP">Nepal</option>
                  <option value="NL">Netherlands</option>
                  <option value="AN">Netherlands Antilles</option>
                  <option value="NC">New Caledonia</option>
                  <option value="NZ">New Zealand</option>
                  <option value="NI">Nicaragua</option>
                  <option value="NE">Niger</option>
                  <option value="NG">Nigeria</option>
                  <option value="NU">Niue</option>
                  <option value="NF">Norfolk Island</option>
                  <option value="MP">Northern Mariana Islands</option>
                  <option value="NO">Norway</option>
                  <option value="OM">Oman</option>
                  <option value="PK">Pakistan</option>
                  <option value="PW">Palau</option>
                  <option value="PS">Palestinian Territory, Occupied</option>
                  <option value="PA">Panama</option>
                  <option value="PG">Papua New Guinea</option>
                  <option value="PY">Paraguay</option>
                  <option value="PE">Peru</option>
                  <option value="PH">Philippines</option>
                  <option value="PN">Pitcairn</option>
                  <option value="PL">Poland</option>
                  <option value="PT">Portugal</option>
                  <option value="PR">Puerto Rico</option>
                  <option value="QA">Qatar</option>
                  <option value="RE">Reunion</option>
                  <option value="RO">Romania</option>
                  <option value="RU">Russian Federation</option>
                  <option value="RW">Rwanda</option>
                  <option value="BL">Saint Barthelemy</option>
                  <option value="SH">Saint Helena</option>
                  <option value="KN">Saint Kitts and Nevis</option>
                  <option value="LC">Saint Lucia</option>
                  <option value="MF">Saint Martin</option>
                  <option value="PM">Saint Pierre and Miquelon</option>
                  <option value="VC">Saint Vincent and the Grenadines</option>
                  <option value="WS">Samoa</option>
                  <option value="SM">San Marino</option>
                  <option value="ST">Sao Tome and Principe</option>
                  <option value="SA">Saudi Arabia</option>
                  <option value="SN">Senegal</option>
                  <option value="RS">Serbia</option>
                  <option value="CS">Serbia and Montenegro</option>
                  <option value="SC">Seychelles</option>
                  <option value="SL">Sierra Leone</option>
                  <option value="SG">Singapore</option>
                  <option value="SX">Sint Maarten</option>
                  <option value="SK">Slovakia</option>
                  <option value="SI">Slovenia</option>
                  <option value="SB">Solomon Islands</option>
                  <option value="SO">Somalia</option>
                  <option value="ZA">South Africa</option>
                  <option value="GS">
                    South Georgia and the South Sandwich Islands
                  </option>
                  <option value="SS">South Sudan</option>
                  <option value="ES">Spain</option>
                  <option value="LK">Sri Lanka</option>
                  <option value="SD">Sudan</option>
                  <option value="SR">Suriname</option>
                  <option value="SJ">Svalbard and Jan Mayen</option>
                  <option value="SZ">Swaziland</option>
                  <option value="SE">Sweden</option>
                  <option value="CH">Switzerland</option>
                  <option value="SY">Syrian Arab Republic</option>
                  <option value="TW">Taiwan, Province of China</option>
                  <option value="TJ">Tajikistan</option>
                  <option value="TZ">Tanzania, United Republic of</option>
                  <option value="TH">Thailand</option>
                  <option value="TL">Timor-Leste</option>
                  <option value="TG">Togo</option>
                  <option value="TK">Tokelau</option>
                  <option value="TO">Tonga</option>
                  <option value="TT">Trinidad and Tobago</option>
                  <option value="TN">Tunisia</option>
                  <option value="TR">Turkey</option>
                  <option value="TM">Turkmenistan</option>
                  <option value="TC">Turks and Caicos Islands</option>
                  <option value="TV">Tuvalu</option>
                  <option value="UG">Uganda</option>
                  <option value="UA">Ukraine</option>
                  <option value="AE">United Arab Emirates</option>
                  <option value="GB">United Kingdom</option>
                  <option value="US">United States</option>
                  <option value="UM">
                    United States Minor Outlying Islands
                  </option>
                  <option value="UY">Uruguay</option>
                  <option value="UZ">Uzbekistan</option>
                  <option value="VU">Vanuatu</option>
                  <option value="VE">Venezuela</option>
                  <option value="VN">Viet Nam</option>
                  <option value="VG">Virgin Islands, British</option>
                  <option value="VI">Virgin Islands, U.s.</option>
                  <option value="WF">Wallis and Futuna</option>
                  <option value="EH">Western Sahara</option>
                  <option value="YE">Yemen</option>
                  <option value="ZM">Zambia</option>
                  <option value="ZW">Zimbabwe</option>
                </select>
              </div>

              <div>
                <label for="country-select" className="placeholder">
                  Choose Country:
                </label>

                <select
                  name="minerals"
                  id="mineral-select"
                  classNameName="input-container ic2"
                  style={{
                    backgroundColor: "white",
                    color: "grey",
                    borderRadius: "12px",
                    padding: "5px",
                    border: "0",
                  }}
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                >
                  <option>Select Product</option>
                  <option value="Chivunguvungu">Chivunguvungu Irrigator</option>
                </select>
                <div className="input-container ic2">
                  <input
                    id="quantity"
                    className="input"
                    type="number"
                    placeholder="Number of Irrigators"
                    min="1"
                    max="1000"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <div className="cut cut-short"></div>
                  <label for="quantity" className="placeholder">
                    Quantity
                  </label>
                </div>
              </div>
              {userInfo ? (
                <button type="text" className="submit" onClick={orderItem}>
                  Order Now
                </button>
              ) : (
                <button type="text" className="submit" onClick={routeChange}>
                  Sign In
                </button>
              )}
            </div>
          </div>
        ) : null}

        {/* End Form */}
        <div
          className="cardminerals 3"
          onClick={onClick}
          onMouseOut={handleClickScroll}
          onTouchEnd={handleClickScroll}
        >
          <div className="card_image">
            <img src="https://media.giphy.com/media/10SvWCbt1ytWCc/giphy.gif" />
          </div>
          <div className="card_title"></div>
        </div>
      </div>
    </>
  );
};

export default Agropower;
