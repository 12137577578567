import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Product from "../components/Product";
import { Footer, Possibility, Features, Header } from "../containers";
import { Brand, Navbar, Slider } from "../components";
import Meta from "../components/Meta";
import { listProducts } from "../actions/productActions";

const slideData = [
  {
    index: 0,
    headline: "Graphite",
    button: "Read More",
    src:
      "https://st.depositphotos.com/1698754/2635/i/450/depositphotos_26355007-stock-photo-graphite-schist.jpg",
  },
  {
    index: 1,
    headline: "Lithium",
    button: "Read More",
    src:
      "https://st2.depositphotos.com/5344180/44986/i/450/depositphotos_449869516-stock-photo-petalite-petalite-castorite-important-mineral.jpg",
  },
  {
    index: 2,
    headline: "Copper",
    button: "Read More",
    src:
      "https://st.depositphotos.com/2698635/3256/i/450/depositphotos_32568451-stock-photo-copper-bullion-bars.jpg",
  },
  {
    index: 3,
    headline: "Neodymium",
    button: "Read More",
    src:
      "https://st2.depositphotos.com/5344180/44455/i/450/depositphotos_444557768-stock-photo-neodymium-stone-part-rare-earth.jpg",
  },
  {
    index: 4,
    headline: "Aluminum",
    button: "Read More",
    src:
      "https://st2.depositphotos.com/5344180/43898/i/450/depositphotos_438988324-stock-photo-aluminum-nuggets-white-background-industrial.jpg",
  },
  {
    index: 5,
    headline: "Coal",
    button: "Read More",
    src:
      "https://st3.depositphotos.com/1007385/13146/i/450/depositphotos_131464896-stock-photo-shovel-and-coal.jpg",
  },
];

const featuresData = [
  {
    title: "Rates Clearance",
    text: "Those who want to do rates clearance, MIAMI is here to assist you.",
  },
  {
    title: "Site Plans",
    text:
      "For those who aren't aware of their property positions, MIAMI is ready to supply with site plans",
  },
  {
    title: "Agreement of Sale",
    text:
      "If you want to check on property ownership compliance, and need assistance with building plans approval, MIAMI is your solution",
  },
  {
    title: "Property Purchases",
    text: "Find and Buy Property with MIAMI",
  },
];

const ShopScreen = ({ match }) => {
  const keyword = match.params.keyword;

  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  useEffect(() => {
    dispatch(listProducts(keyword, pageNumber));
  }, [dispatch, keyword, pageNumber]);

  return (
    <>
      <Meta />
      <div classNameName="gradieant__bg">
        <Navbar />
        <Header
          heading="Minerals Market"
          content="The African continent is a treasure trove of minerals that have been extracted and exported to many international destinations. MIAMI collects information on all minerals mined and marketed on the continent as a strategy to dismenate market intelligence and promote value addition."
          image="coal"
        />
      </div>
      <Brand />
      <Slider heading="Example Slider" slides={slideData} />
      <Possibility
        subheading="Request Early Access to MIAMI Property Management System"
        heading="The possibilities are beyond your imagination with MIAMI Property Management System"
        content="From buying Properties to sorting out contracts. All is done on MIAMI"
        image="coal"
      />
      <Features
        featuresD={featuresData}
        heading=" The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen with MIAMI"
        contentP="Browse through our services"
      />

      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <Product products={products} />
        </>
      )}

      <Footer />

      {/* <> */}
      {/* <div classNameName="row property__gallery">
        <ul>
          {products.map((product) => (
            <div
              key={product._id}
              classNameName="col-lg-3 col-md-4 col-sm-6 mix coal"
            >
              <Product product={product} />
            </div>
          ))}
        </ul>
        <Row></Row>
        <Paginate pages={pages} page={page} keyword={keyword ? keyword : ""} />
      </div> */}
    </>
  );
};

export default ShopScreen;
