import React from "react";
import Article from "../../components/article/Article";
import { blog01, blog02, blog03, blog04, blog05 } from "./imports";
import "./blog.css";


const Blog = ({ heading, dataA, dataB,viewAssets, toggleModal }) => (
  <div className="gpt3__blog section__padding" id="blog">
    <div className="gpt3__blog-heading">
      <h1 className="gradient__text">
        {heading}
      </h1>
    </div>
    <div className="gpt3__blog-container">
      <div className="gpt3__blog-container_groupA">
        <Article
          imgUrl={blog01}
          date="Sep 26, 2021"
          text="Mineral Market. Buy and Find Minerals on MIAMI."
        />
      </div>
      <div className="gpt3__blog-container_groupB">
        {dataB.map((item) => (
          <Article
          key={item.id}
          imgUrl={item.profileImage}
          date="Sep 26, 2021"
          text={item.name}
          id={item.id}
          click={toggleModal}
        />
        ))}
        {/* <Article
          imgUrl={blog05}
          date="Sep 26, 2021"
          text="A Knowledgebase at your disposal."
        /> */}
      </div>
    </div>
  </div>
);

export default Blog;
