import axios from "axios";
import {
  JEWCART_ADD_ITEM,
  JEWCART_REMOVE_ITEM,
  JEWCART_SAVE_SHIPPING_ADDRESS,
  JEW_SAVE_PAYMENT_METHOD,
} from "../constants/jewscartConstants";

export const addToJewCart = (id, qty) => async (dispatch, getState) => {
  const { data } = await axios.get(`/api/megajews/${id}`);

  dispatch({
    type: JEWCART_ADD_ITEM,
    payload: {
      megajew: data._id,
      name: data.name,
      image: data.image,
      price: data.price,
      countInStock: data.countInStock,
      qty,
    },
  });

  localStorage.setItem(
    "jewCartItems",
    JSON.stringify(getState().jewscart.jewCartItems)
  );
};

export const removeFromJewCart = (id) => (dispatch, getState) => {
  dispatch({
    type: JEWCART_REMOVE_ITEM,
    payload: id,
  });

  localStorage.setItem(
    "jewcartItems",
    JSON.stringify(getState().jewscart.jewCartItems)
  );
};

export const saveJewShippingAddress = (data) => (dispatch) => {
  dispatch({
    type: JEWCART_SAVE_SHIPPING_ADDRESS,
    payload: data,
  });

  localStorage.setItem("jewshippingAddress", JSON.stringify(data));
};

export const jewsavePaymentMethod = (data) => (dispatch) => {
  dispatch({
    type: JEW_SAVE_PAYMENT_METHOD,
    payload: data,
  });

  localStorage.setItem("jewpaymentMethod", JSON.stringify(data));
};
