import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../actions/userActions";
import Loader from "../Loader";
import { Link } from "react-router-dom";
import Message from "../../components/Message";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useAlert } from "react-alert";
import { useUserContext } from "../../context/userContext"; 

const Signup = () => {

  // const emailRef= useRef();
  // const nameRef= useRef();
  // const passwordRef= useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState(null);
  const [phoneNumber, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState(null);
  const alert = useAlert();

  const dispatch = useDispatch();

  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;


  // const {registerUser,loading,error} = useUserContext();
  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else if (phoneNumber === "") {
      setMessage("Please fill in Phone Number");
    } else {
      dispatch(register(name, email, "+" + phoneNumber, password));
    }
    // const email = String(emailRef.current.value).trim();
    // console.log(email);
    // const password = passwordRef.current.value;
    // const name = nameRef.current.value;
    // const email = String(emailRef.current.value).trim();
    // console.log(typeof(email));
    // if(email && password && name){
    //   registerUser(name, email, password);
    // }
  };

  return (
    <>
      <div id="wrapper">
        <div id="left">
          <div id="signin">
            <div className="logo">
              <img
                src="https://imagizer.imageshack.com/img922/7420/jrXTNU.png"
                alt="Sluralpright"
              />
            </div>
            {loading && <Loader />}
            {error && <Message variant="danger">{error}</Message>} 
            <form onSubmit={submitHandler}>
              <div>
                <label>Full Name</label>
                <input
                  required="true"
                  id="username"
                  type="name"
                  placeholder="Enter name"
                  // ref={nameRef}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="text-input"
                />
              </div>

              <div>
                <label>Email</label>
                <input
                  type="email"
                  placeholder="Enter email"
                  //  ref={emailRef}
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className="text-input"
                />
              </div>
              <div style={{ width: "100%" }}>
                <label>Phone Number</label>
                <ReactPhoneInput
                  preferredCountries={["zw", "us"]}
                  id="phone"
                  disableAreaCodes
                  value={phoneNumber}
                  onChange={(phoneNumber) => setPhone(phoneNumber)}
                />
              </div>
              <div>
                <label>Password</label>
                <input
                  type="password"
                  placeholder="Password"
                  required="true"
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  // ref={passwordRef}
                  className="text-input"
                />
              </div>

              <div>
                <label> Confirm Password</label>
                <input
                  required="true"
                  id="password"
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="text-input"
                />
              </div>
              <button type="submit" className="primary-btn">
                Create Account
              </button>
              <Link to="/login" className="secondary-btn">
                Sign In
              </Link>
            </form>
            <div className="links">
              <a href="#">Sign in with</a>
            </div>
            <div className="or">
              <hr className="bar" />
              <span>OR</span>
              <hr className="bar" />
            </div>
          </div>
          <footer id="main-footer">
            <p>Copyright &copy; 2023, MIAMI All Rights Reserved</p>
            <div>
              <a href="#">terms of use</a> | <a href="#">Privacy Policy</a>
            </div>
          </footer>
        </div>
        <div id="right">
          <div id="showcase">
            <div className="showcase-content">
              <h1 className="showcase-text">
                Let's create the future <strong>together</strong>
              </h1>
              <a href="#" className="secondary-btn">
                Be part of Made In Africa
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div classNameName="container" style={{ margin: "0" }}>
        <div classNameName="forms-container">
          <div classNameName="signin-signup">
            <form action="#" classNameName="sign-in-form" onSubmit={submitHandler}>
              <h2 classNameName="titl gradient__text">Sign Up</h2>
              <div classNameName="input-field">
                <i classNameName="fas fa-user"></i>
                <input
                  required="true"
                  id="username"
                  type="name"
                  placeholder="Enter name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <ReactPhoneInput
                preferredCountries={["zw", "us"]}
                classNameName="input-field"
                id="phone"
                disableAreaCodes
                value={phoneNumber}
                onChange={(phoneNumber) => setPhone(phoneNumber)}
              />
              <div classNameName="input-field">
                <i classNameName="fas fa-user"></i>
                <input
                  required="true"
                  id="username"
                  type="email"
                  placeholder="Enter email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div classNameName="input-field">
                <i classNameName="fas fa-lock"></i>
                <input
                  required="true"
                  id="password"
                  type="password"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>

              <div classNameName="input-field">
                <i classNameName="fas fa-lock"></i>
                <input
                  required="true"
                  id="password"
                  type="password"
                  placeholder="Confirm password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </div>
              {error && <Message variant="danger">{error}</Message>}
              <input type="submit" value="Sign Up" classNameName="btn solid" />
              <p classNameName="social-text">Or Sign in with social platforms</p>
              <div classNameName="social-media">
                <a href="#" classNameName="social-icon">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i className="fab fa-google"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i classNameName="fab fa-linkedin-in"></i>
                </a>
              </div>
            </form>
            <form action="#" className="sign-up-form">
              <h2 classNameName="titl">Sign up</h2>
              <div classNameName="input-field">
                <i classNameName="fas fa-user"></i>
                <input type="text" placeholder="Username" />
              </div>
              <div classNameName="input-field">
                <i classNameName="fas fa-envelope"></i>
                <input type="email" placeholder="Email" />
              </div>
              <div classNameName="input-field">
                <i classNameName="fas fa-lock"></i>
                <input type="password" placeholder="Password" />
              </div>
              <input type="submit" classNameName="btn" value="Sign up" />
              <p classNameName="social-text">Or Sign up with social platforms</p>
              <div classNameName="social-media">
                <a href="#" classNameName="social-icon">
                  <i classNameName="fab fa-facebook-f"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i classNameName="fab fa-twitter"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i classNameName="fab fa-google"></i>
                </a>
                <a href="#" classNameName="social-icon">
                  <i classNameName="fab fa-linkedin-in"></i>
                </a>
              </div>
            </form>
          </div>
        </div>

        <div classNameName="panels-container">
          <div classNameName="panel left-panel">
            <div classNameName="content">
              <h3 style={{ color: "white" }}>Not New ?</h3>
              <p>Sign in with MIAMI</p>

              <Link to="/login">
                <button classNameName="btn transparent" id="sign-in-btn">
                  Sign In
                </button>
              </Link>
            </div>
            <img src="img/log.svg" classNameName="image" alt="" />
          </div>
          <div classNameName="panel right-panel">
            <div classNameName="content">
              <h3>One of us ?</h3>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laboriosam ad deleniti.
              </p>
              <Link to="/login">
                <button classNameName="btn transparent" id="sign-in-btn">
                  Sign In
                </button>
              </Link>
            </div>
            <img src="img/register.svg" classNameName="image" alt="" />
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Signup;
