import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/jeworderActions";
import { JEWORDER_CREATE_RESET } from "../constants/jewsorderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";

const JewPlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const jewscart = useSelector((state) => state.jewscart);

  if (!jewscart.jewshippingAddress.address) {
    history.push("/jewshipping");
  } else if (!jewscart.jewpaymentMethod) {
    history.push("/jewpayment");
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  jewscart.itemsPrice = addDecimals(
    jewscart.jewCartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  jewscart.shippingPrice = addDecimals(jewscart.itemsPrice > 100 ? 0 : 100);
  jewscart.taxPrice = addDecimals(
    Number((0.15 * jewscart.itemsPrice).toFixed(2))
  );
  jewscart.totalPrice = (
    Number(jewscart.itemsPrice) +
    Number(jewscart.shippingPrice) +
    Number(jewscart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.jorderCreate);
  const { jeworderItems, success, error } = orderCreate;
  useEffect(() => {
    if (success) {
      history.push(`/jeworders/${jeworderItems._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: JEWORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: jewscart.jewCartItems,
        shippingAddress: jewscart.jewshippingAddress,
        paymentMethod: jewscart.jewpaymentMethod,
        itemsPrice: jewscart.itemsPrice,
        shippingPrice: jewscart.shippingPrice,
        taxPrice: jewscart.taxPrice,
        totalPrice: jewscart.totalPrice,
      })
    );
  };

  return (
    <>
      <Navbar />
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Shipping</h2>
              <p>
                <strong>Address:</strong>
                {jewscart.jewshippingAddress.address},{" "}
                {jewscart.jewshippingAddress.city}{" "}
                {jewscart.jewshippingAddress.postalCode},{" "}
                {jewscart.jewshippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {jewscart.jewpaymentMethod}
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Order Items</h2>
              {jewscart.jewCartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {jewscart.jewCartItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      classNameName="detail-card bounce-in-top"
                    >
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/megajew/${item.megajew}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card classNameName="detail-card bounce-in-top">
            <ListGroup variant="flush">
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Items</Col>
                  <Col>${jewscart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Shipping</Col>
                  <Col>${jewscart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Tax</Col>
                  <Col>${jewscart.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Total</Col>
                  <Col>${jewscart.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Button
                  type="button"
                  classNameName="btn-block"
                  disabled={jewscart.jewCartItems === 0}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default JewPlaceOrderScreen;
