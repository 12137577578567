import {
  JEWCART_ADD_ITEM,
  JEWCART_REMOVE_ITEM,
  JEWCART_SAVE_SHIPPING_ADDRESS,
  JEW_SAVE_PAYMENT_METHOD,
  JEWCART_CLEAR_ITEMS,
} from "../constants/jewscartConstants";

export const jewscartReducer = (
  state = { jewCartItems: [], jewshippingAddress: {} },
  action
) => {
  switch (action.type) {
    case JEWCART_ADD_ITEM:
      const item = action.payload;

      const existItem = state.jewCartItems.find(
        (x) => x.megajew === item.megajew
      );

      if (existItem) {
        return {
          ...state,
          jewCartItems: state.jewCartItems.map((x) =>
            x.megajew === existItem.megajew ? item : x
          ),
        };
      } else {
        return {
          ...state,
          jewCartItems: [...state.jewCartItems, item],
        };
      }
    case JEWCART_REMOVE_ITEM:
      return {
        ...state,
        jewCartItems: state.jewCartItems.filter(
          (x) => x.megajew !== action.payload
        ),
      };
    case JEWCART_SAVE_SHIPPING_ADDRESS:
      return {
        ...state,
        jewshippingAddress: action.payload,
      };
    case JEW_SAVE_PAYMENT_METHOD:
      return {
        ...state,
        jewpaymentMethod: action.payload,
      };
    case JEWCART_CLEAR_ITEMS:
      return {
        ...state,
        jewCartItems: [],
      };
    default:
      return state;
  }
};
