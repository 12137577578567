import React from "react";
import gpt3Logo from "../../assets/logo1.png";
import "./footer.css";

function YearCopyright() {
  return (
    <p id="copyright-year">
      @Made in Africa Mining Initiative {new Date().getFullYear()} <br />
      All Rights Reserved
    </p>
  );
}

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">
          Do you want to step in to the future before others
        </h1>
      </div>

      <div className="gpt3__footer-btn">
        <p>Request Early Access</p>
      </div>
      <a
        href="https://wa.me/263714928516?text=hi"
        className="wa-float-img-circle heartbeat"
        target="_blank"
      >
        <img
          src="https://repository-images.githubusercontent.com/500020161/090ee0ff-60fe-49c9-aee0-002f9b960490"
          alt="WhatsApp"
        />
      </a>
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3Logo} alt="gpt3_logo" style={{ height: "95px" }} />
          <p>
            Made in Africa Mining Initiative, <br /> All Rights Reserved
          </p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p>Overons</p>
          <p>Social Media</p>
          <p>Counters</p>
          <p>
            <a href="mailto:miamimento@gmail.com">Contact</a>
          </p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions </p>
          <p>Privacy Policy</p>
          <p>Contact</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Get in touch</h4>
          <p>Harare Zimbabwe</p>
          <p>+263 780255320</p>
          <p>miamimento@gmail.com</p>
          <p style={{ fontSize: "1px", color: "grey" }}>Created by D Jambani</p>
        </div>
      </div>

      <div className="gpt3__footer-copyright">
        {/* <i className="far fa-copyright"></i> */}
        <YearCopyright />
      </div>
    </div>
  );
};

export default Footer;
