import React,{useState, useEffect} from "react";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
  } from "../containers";
  
import { CTA, Brand, Navbar, Minerals } from "../components";
import { useUserContext } from "../context/userContext";
import { useHistory } from "react-router-dom";
import axios from "axios";

const PaymentResultPage = () => {
    const history = useHistory();
    const { user } = useUserContext();
    const [orderID, setID]=useState();
    const [paymentStatus, setStatus] = useState();

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const checkPaymentResult = async () => {
      console.log("orderID: " + orderID);
        try {
            const { data } = await axios.post(
              `/api/megajoules-coupons/paymentstatus`,
              {
                orderID: orderID
              },
              config
            );
          
            if (data.message === "success") {
                setStatus("Payment Successfully Processed. Megajoules have been credited to your account.");
            } else if(data.message === "already paid"){
                setStatus("Payment has already been processed");
            }else if(data.message ==="notpaid"){
              setStatus("Payment not Processed. Try Again");
            }else if(data.message ==="Order not found"){
              setStatus("Order Not Found. Try Again")
            }else{
              setStatus("Error has occurred while processing")
            }
          } catch (error) {
            console.log(error.response.data);
          }
    }
    useEffect(() => {
        // if(user){
        setID(localStorage.getItem('StoredorderID'))
        console.log(localStorage.getItem('StoredorderID'))
        checkPaymentResult()
        // }
      },[]);

    return (
        <>
            <div classNameName="gradieant__bg">
                <Navbar />
                <Header
                    heading="Payment Status"
                    content={paymentStatus}
                    image="paymentresult"
                />
            </div>
        </>
    )
}

export default PaymentResultPage