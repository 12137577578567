import {
  CONTRACT_REQUEST,
  CONTRACT_REQUEST_SUCCESS,
  CONTRACT_REQUEST_FAIL,
} from "../constants/contractConstants";

export const contractReduce = (
  state = { loading: true, contract: {} },
  action
) => {
  switch (action.type) {
    case CONTRACT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CONTRACT_REQUEST_SUCCESS:
      return {
        loading: false,
        contract: action.payload,
      };
    case CONTRACT_REQUEST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
