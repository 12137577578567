import React from "react";
import { Helmet } from "react-helmet";

const Meta = ({ title, description, keywords }) => {
  return (
    <Helmet>
      <title>{title}</title>
      {/* Js Plugins */}
      <script type="text/javascript" src="../../public/js/shop.js"></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery-3.3.1.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/bootstrap.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery.magnific-popup.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery-ui.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/mixitup.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery.countdown.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery.slicknav.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/owl.carousel.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/jquery.nicescroll.min.js"
      ></script>
      <script
        type="text/javascript"
        src="../../public/js/test/main.js"
      ></script>
      {/* End Test */}
      <meta name="description" content={description} />
      <meta name="keyword" content={keywords} />
    </Helmet>
  );
};

Meta.defaultProps = {
  title: "Welcome To MIAMI",
  description: "Buy and Invest in Megadew coin",
  keywords: "minerals, buy, cheap mineral",
};

export default Meta;
