import React, { useState, useEffect, useContext } from "react";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";

import { CTA, Brand, Navbar, Minerals, GridList } from "../components";
import { useUserContext } from "../context/userContext";
import { useHistory } from "react-router-dom";
import SyndicateContext from "../context/syndicateContext";
import axios from "axios";
import Users from "../components/Users";
import Stocks from "../components/Stocks";
import Assets from "../components/Assets";
import { Charts } from "../components/Charts";
import { auth } from "../firebase";
import './css/syndicate.css';

const SyndicateScreen = () => {
    const {
        syndicates,
        findSyndicates,
        creatteSyndicate,
        createSuccess,
        errorMessage,
        resetSyndicateState,
        loading,
        setLoading,
        dashboardData,
        path,
        setPath
    } = useContext(SyndicateContext);
    const history = useHistory();
    const user = auth.currentUser

    const [showMenu, setShowMenu] = useState(true);
    const [showUsers, setShowUsers] = useState(false)
    const [showStocks, setShowStocks] = useState(false)
    const [asset, setAsset] = useState(false)

    const viewUsers = () => {
        setShowUsers(true);
        setShowMenu(false)
    }

  

    const viewStocks = () =>{
        setShowStocks(true);
        setShowMenu(false)
    }

    const viewAssets =() =>{
        setAsset(true);
        setShowMenu(false)
    }

    const close = () => {
        setShowUsers(false);
        setShowStocks(false)
        setAsset(false)
        setShowMenu(true);
    }
    useEffect(() => {
        if (path !== null) {
            setPath(null);
        }

        if (dashboardData === null) {
            history.push('/profile');
        }
    }, [dashboardData]);

    return (
        <>
            {dashboardData && (
                <>
                    <div classNameName="gradieant__bg">
                        <Navbar />
                        {showMenu && (
                            <Header
                                heading={dashboardData.syndicateName}
                                content={dashboardData.syndicateBio}
                                image="syndicateDash"
                            />
                        )}
                    </div>
                    {showMenu && (
                        <div className="containeer bounce-in-top">
                            <div className="row mt-4">
                                {dashboardData.users && (
                                    <div className="col-12 col-sm-6 col-md-3" onClick={viewUsers}>
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa fa-users my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }}>Users</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total Users :{dashboardData.users.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.stocks && (
                                    <div className="col-12 col-sm-6 col-md-3" onClick={viewStocks}>
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">

                                                <span className="fa-solid fa-cubes-stacked my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }}>Stocks</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.stocks.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.refineries && (
                                    <div className="col-12 col-sm-6 col-md-3" onClick={viewAssets}>
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-bag-shopping my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }}>Assets</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.refineries.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.consumedCommodities && (
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-cart-flatbed-suitcase my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }}>Consumed Commodities</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.consumedCommodities.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.directors && (
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-briefcase my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }}>Directors</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.directors.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.installments && (
                                    <div className="col-12 col-sm-6 col-md-3">
                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-coins my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }} >Installments</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.installments.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.AgentApplications && (
                                    <div className="col-12 col-sm-6 col-md-3">

                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-file-contract my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }} >Agent Applications</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.AgentApplications.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.subscriptionRecord && (
                                    <div className="col-12 col-sm-6 col-md-3">

                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-regular fa-credit-card my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }} >Subscriptions</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.subscriptionRecord.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}

                                {dashboardData.mjConsumeRequests && (
                                    <div className="col-12 col-sm-6 col-md-3">

                                        <div className="card my-card" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}>
                                            <div className="my-auto mx-auto">
                                                <span className="fa-solid fa-layer-group my-icon py-3 px-4"></span>
                                            </div>
                                            <div className="my-auto px-4">
                                                <p className="mb-1" style={{ fontSize: "15px", fontSize: "20px" }} >MJ Consume Requests</p>
                                                <h2 style={{ color: "white", fontSize: "20px", fontWeight: "bold" }}>Total :{dashboardData.mjConsumeRequests.length}</h2>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                    {showUsers && (
                        <>
                            <div classNameName="cntainer bounce-in-top" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", width: "88%", marginLeft: "auto", marginRight: "auto", marginTop: '8px' }}>
                                <Users userPhone={user.phoneNumber} setclose={setShowUsers} showMenu={setShowMenu} close={close}/>
                            </div>
                        </>
                    )}
                   
                    {showStocks && (
                         <>
                         <div classNameName="cntainer bounce-in-top" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", width: "88%", marginLeft: "auto", marginRight: "auto", marginTop: '8px' }}>
                             <Stocks userPhone={user.phoneNumber} setclose={setShowStocks} showMenu={setShowMenu} close={close}/>
                         </div>
                        </>
                    )}

                    {asset && (
                        <>
                         <div classNameName="cntainer bounce-in-top" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", width: "88%", marginLeft: "auto", marginRight: "auto", marginTop: '8px' }}>
                            <Assets  userPhone={user.phoneNumber} setclose={setShowStocks} showMenu={asset} close={close}/>
                         </div>
                        </>
                    )}
                </>
            )}

        </>
    )
}

export default SyndicateScreen