export const JEWORDER_CREATE_REQUEST = "JEWORDER_CREATE_REQUEST";
export const JEWORDER_CREATE_SUCCESS = "JEWORDER_CREATE_SUCCESS";
export const JEWORDER_CREATE_FAIL = "JEWORDER_CREATE_FAIL";
export const JEWORDER_CREATE_RESET = "JEWORDER_CREATE_RESET";

export const JEWORDER_DETAILS_REQUEST = "JEWORDER_DETAILS_REQUEST";
export const JEWORDER_DETAILS_SUCCESS = "JEWORDER_DETAILS_SUCCESS";
export const JEWORDER_DETAILS_FAIL = "JEWORDER_DETAILS_FAIL";

export const JEWORDER_PAY_REQUEST = "JEWORDER_PAY_REQUEST";
export const JEWORDER_PAY_SUCCESS = "JEWORDER_PAY_SUCCESS";
export const JEWORDER_PAY_FAIL = "JEWORDER_PAY_FAIL";
export const JEWORDER_PAY_RESET = "JEWORDER_PAY_RESET";

export const JEWORDER_LIST_MY_REQUEST = "JEWORDER_LIST_MY_REQUEST";
export const JEWORDER_LIST_MY_SUCCESS = "JEWORDER_LIST_MY_SUCCESS";
export const JEWORDER_LIST_MY_FAIL = "JEWORDER_LIST_MY_FAIL";
export const JEWORDER_LIST_MY_RESET = "JEWORDER_LIST_MY_RESET";

export const JEWORDER_LIST_REQUEST = "JEWORDER_LIST_REQUEST";
export const JEWORDER_LIST_SUCCESS = "JEWORDER_LIST_SUCCESS";
export const JEWORDER_LIST_FAIL = "JEWORDER_LIST_FAIL";

export const JEWORDER_DELIVER_REQUEST = "JEWORDER_DELIVER_REQUEST";
export const JEWORDER_DELIVER_SUCCESS = "JEWORDER_DELIVER_SUCCESS";
export const JEWORDER_DELIVER_FAIL = "JEWORDER_DELIVER_FAIL";
export const JEWORDER_DELIVER_RESET = "JEWORDER_DELIVER_RESET";
