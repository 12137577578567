import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/jeworderActions";
import {
  JEWORDER_PAY_RESET,
  JEWORDER_DELIVER_RESET,
} from "../constants/jewsorderConstants";

const JewsOrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const [sdkReady, setSdkReady] = useState(false);

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.jorderDetails);
  const { jeworderItems, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.jorderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.jorderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    jeworderItems.itemsPrice = addDecimals(
      jeworderItems.orderItems.reduce(
        (acc, item) => acc + item.price * item.qty,
        0
      )
    );
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get("/api/config/paypal");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (
      !jeworderItems ||
      successPay ||
      successDeliver ||
      jeworderItems._id !== orderId
    ) {
      dispatch({ type: JEWORDER_PAY_RESET });
      dispatch({ type: JEWORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!jeworderItems.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, orderId, successPay, successDeliver, jeworderItems]);

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult);
    dispatch(payOrder(orderId, paymentResult));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(jeworderItems));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <div
      style={{
        width: "98%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "15px",
      }}
    >
      <Navbar />
      <h1 style={{ color: "orange" }}>Order {jeworderItems._id}</h1>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Shipping</h2>
              <p>
                <strong>Name: </strong> {jeworderItems.user.name}
              </p>
              <p>
                <strong>Email: </strong>{" "}
                <a href={`mailto:${jeworderItems.user.email}`}>
                  {jeworderItems.user.email}
                </a>
              </p>
              <p>
                <strong>Address:</strong>
                {jeworderItems.shippingAddress.address},{" "}
                {jeworderItems.shippingAddress.city}{" "}
                {jeworderItems.shippingAddress.postalCode},{" "}
                {jeworderItems.shippingAddress.country}
              </p>
              {jeworderItems.isDelivered ? (
                <Message variant="success">
                  Delivered on {jeworderItems.deliveredAt}
                </Message>
              ) : (
                <Message variant="danger">Not Delivered</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Payment Method</h2>
              <p>
                <strong>Method: </strong>
                {jeworderItems.paymentMethod}
              </p>
              {jeworderItems.isPaid ? (
                <Message variant="success">
                  Paid on {jeworderItems.paidAt}
                </Message>
              ) : (
                <Message variant="danger">Not Paid</Message>
              )}
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Order Items</h2>
              {jeworderItems.orderItems.length === 0 ? (
                <Message>Order is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {jeworderItems.orderItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      classNameName="detail-card bounce-in-top"
                    >
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/megajew/${item.megajew}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x ${item.price} = ${item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card classNameName="detail-card bounce-in-top">
            <ListGroup variant="flush">
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Items</Col>
                  <Col>${jeworderItems.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Shipping</Col>
                  <Col>${jeworderItems.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Tax</Col>
                  <Col>${jeworderItems.taxPrice}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Total</Col>
                  <Col>${jeworderItems.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
              {!jeworderItems.isPaid && (
                <ListGroup.Item classNameName="detail-card bounce-in-top">
                  {loadingPay && <Loader />}
                  {!sdkReady ? (
                    <Loader />
                  ) : (
                    <PayPalButton
                      amount={jeworderItems.totalPrice}
                      onSuccess={successPaymentHandler}
                    />
                  )}
                </ListGroup.Item>
              )}
              {loadingDeliver && <Loader />}
              {userInfo &&
                userInfo.isAdmin &&
                jeworderItems.isPaid &&
                !jeworderItems.isDelivered && (
                  <ListGroup.Item classNameName="detail-card bounce-in-top">
                    <Button
                      type="button"
                      classNameName="btn btn-block"
                      onClick={deliverHandler}
                    >
                      Mark As Delivered
                    </Button>
                  </ListGroup.Item>
                )}
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Footer />
    </div>
  );
};

export default JewsOrderScreen;
