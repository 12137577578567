import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  productListReducer,
  productDetailsReducer,
  productDeleteReducer,
  productCreateReducer,
  productUpdateReducer,
  productReviewCreateReducer,
  productTopRatedReducer,
} from "./reducers/productReducers";

import { contractReduce } from "./reducers/contractReducer";
import {
  megajewListReducer,
  megajewDetailsReducer,
  megajewDeleteReducer,
  megajewCreateReducer,
  megajewUpdateReducer,
  megajewReviewCreateReducer,
  megajewTopRatedReducer,
} from "./reducers/megajewReducers";

import { cartReducer } from "./reducers/cartReducers";
import { jewscartReducer } from "./reducers/jewscartReducers";

import {
  userLoginReducer,
  userRegisterReducer,
  userDetailsReducer,
  userUpdateProfileReducer,
  userListReducer,
  userDeleteReducer,
  userUpdateReducer,
} from "./reducers/userReducers";
import {
  orderCreateReducer,
  orderDetailsReducer,
  orderPayReducer,
  orderDeliverReducer,
  orderListMyReducer,
  orderListReducer,
} from "./reducers/orderReducers";

import {
  myAccountReducer,
  listAccountsReducer,
} from "./reducers/accountReducers";

import {
  jeworderCreateReducer,
  jeworderDetailsReducer,
  jeworderPayReducer,
  jeworderDeliverReducer,
  jeworderListMyReducer,
  jeworderListReducer,
} from "./reducers/jewsorderReducers";

const reducer = combineReducers({
  productList: productListReducer,
  productDetails: productDetailsReducer,
  productDelete: productDeleteReducer,
  productCreate: productCreateReducer,
  productUpdate: productUpdateReducer,
  productReviewCreate: productReviewCreateReducer,
  productTopRated: productTopRatedReducer,

  contract: contractReduce,

  megajewList: megajewListReducer,
  megajewDetails: megajewDetailsReducer,
  megajewDelete: megajewDeleteReducer,
  megajewCreate: megajewCreateReducer,
  megajewUpdate: megajewUpdateReducer,
  megajewReviewCreate: megajewReviewCreateReducer,
  megajewTopRated: megajewTopRatedReducer,

  cart: cartReducer,
  jewscart: jewscartReducer,

  userLogin: userLoginReducer,
  userRegister: userRegisterReducer,
  userDetails: userDetailsReducer,
  userUpdateProfile: userUpdateProfileReducer,
  userList: userListReducer,
  userDelete: userDeleteReducer,
  userUpdate: userUpdateReducer,
  orderCreate: orderCreateReducer,
  orderDetails: orderDetailsReducer,
  orderPay: orderPayReducer,
  orderDeliver: orderDeliverReducer,
  orderListMy: orderListMyReducer,
  orderList: orderListReducer,

  myAccount: myAccountReducer,
  listAccounts: listAccountsReducer,

  jorderCreate: jeworderCreateReducer,
  jorderDetails: jeworderDetailsReducer,
  jorderPay: jeworderPayReducer,
  jorderDeliver: jeworderDeliverReducer,
  jorderListMy: jeworderListMyReducer,
  jorderList: jeworderListReducer,
});

const cartItemsFromStorage = localStorage.getItem("cartItems")
  ? JSON.parse(localStorage.getItem("cartItems"))
  : [];

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const shippingAddressFromStorage = localStorage.getItem("shippingAddress")
  ? JSON.parse(localStorage.getItem("shippingAddress"))
  : {};

const jewsCartItemsFromStorage = localStorage.getItem("jewCartItems")
  ? JSON.parse(localStorage.getItem("jewCartItems"))
  : [];

const jewShippingAddressFromStorage = localStorage.getItem("jewshippingAddress")
  ? JSON.parse(localStorage.getItem("jewshippingAddress"))
  : {};

const initialState = {
  cart: {
    cartItems: cartItemsFromStorage,
    shippingAddress: shippingAddressFromStorage,
  },
  jewscart: {
    jewCartItems: jewsCartItemsFromStorage,
    jewshippingAddress: jewShippingAddressFromStorage,
  },
  userLogin: { userInfo: userInfoFromStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
