import React, { useRef, useEffect, useState } from "react";
import { Footer, Possibility, Features, Header, WhatGPT3 } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
// import gpt3Logo from '../assets/logo1.png';
const slideData = [
  {
    index: 0,
    headline: "Site Plan",
    button: "Read More",
    src: "https://img.freepik.com/free-vector/engineers-team-discussing-issues-construction-site_74855-4786.jpg?w=740&t=st=1666355254~exp=1666355854~hmac=750328dcf0c95d01d0cc3b9950c166b2514e2d4f9dfb8f65e4d58b15647a2906",
    link: "/contract",
  },
  {
    index: 1,
    headline: "Purchase Property",
    button: "Read More",
    src: "https://img.freepik.com/free-photo/realtor-giving-keys-new-owner_23-2147764253.jpg?w=740&t=st=1666357132~exp=1666357732~hmac=4e5676e6403ca44843f5270a0c9d2749ef1c4c5192ed24108af2704baf3f2319",
  },
  {
    index: 2,
    headline: "Agreement of Sale",
    button: "Read More",
    src: "https://img.freepik.com/free-vector/document-signing-partnership-deal-business-consultation-work-arrangement-client-assistant-writing-contract-cartoon-characters_335657-2342.jpg?w=740&t=st=1666356715~exp=1666357315~hmac=1dfb384db2925558e8f833b136a6fc031a859919ca27c0cab96e39bf01fc96fa",
    link: "/contract",
  },
  {
    index: 3,
    headline: "Plan Approval",
    button: "Read More",
    src: "https://img.freepik.com/free-vector/business-men-signing-contract-online-flat-vector-illustration-men-shaking-hands-through-mobile-phone-screens-electronic-devices-after-successful-deal-partnership-agreement-concept_74855-23975.jpg?w=740&t=st=1666356808~exp=1666357408~hmac=067efa6d58f01b12de4ebac1dda758e1fc06e9859f37e2bd7aea55c1c888afd2",
    link: "/contract",
  },
  {
    index: 4,
    headline: "Rates Clearance certificate",
    button: "Read More",
    src: "https://img.freepik.com/free-vector/abstract-geometric-business-invoice-template_1017-15474.jpg?w=740&t=st=1666356931~exp=1666357531~hmac=e8332787098fe24ce8279ef59af34cb39f88aa2653092b465f67ce4fbabcc1d1",
    link: "/contract",
  },
  {
    index: 5,
    headline: "Others",
    button: "Read More",
    src: "https://img.freepik.com/free-photo/real-estate-purchase-concept-idea_1421-84.jpg?w=740&t=st=1666357262~exp=1666357862~hmac=4918db94a8594cfaccf30efe8927f53bc9dfa2078c7b438374becb35833616a8",
  },
];

const featuresData = [
  {
    title: "Let's go digital",
    text: "Gone are the days of storing piles of paper documents. Step into the future and start using your smartphones as gateways to access information.",
  },
  {
    title: "Syndicates",
    text: "This is an invitation to all other businesses in the property sector to come aboard and utilize our platform for their services",
  },
  {
    title: "Syndicate Registration",
    text: "If you want to have your Real Business operate on our platform. You have to register on MIAMI and apply to be a Syndicate Operator",
  },
  {
    title: "Benefits",
    text: "By Applying we direct clients to your doorstep, and you get the priveledge to use our monetary transaction system, that always gives you a commission for each transaction done on it",
  },
];
const HousingScreen = () => {
  const [pageLoad, setPageLoad] = useState(false)
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    setPageLoad(true);
     
    setTimeout(() => {
      setPageLoad(false);
    }, 1000);
}, []);

  return (
    <>
    {pageLoad && (<Loader />)}
      <div classNameName="gradieant__bg">
        <Navbar />
        <Header
          heading="Cleverhills Investiments"
          content="Welcome to Cleverhill Investments Pvt Ltd. 
          With Clients feeling unsatisfied with the services provided, we have come up with a solution to overcome 
          all the short comings within Cleverhills. Our goal is to be the best Real Estate Experts, helping homeowners buy and sell properties. 
          Our team of Real estate Agents will have intimate knowledge of the local market. 
          Now that we know the ins and outs of the Industry, we now understand the market trends, 
          and plan to leverage our relationships with our clients and provide the best deals"
          image="house"
        />
      </div>
      {/* <Brand /> */}

      <div
        classNameName="text_slider_body bounce-in-top"
        style={{ marginBottom: "1rem" }}
      >
        <div className="typing-slider">
          <p>HOW CAN</p>
          <p>CLEVERHILLS</p>
          <p>ASSIST</p>
        </div>
      </div>

      {/* SERVICES */}
      <WhatGPT3
        featureTitle="Cooperation"
        featureText="Collaboration is key to success. We are in this together, Together we can scale greater heights and accomplish more."
        heading="Insipiration can build a settlement beyond imagination."
        headingText="Explore services"
        featureTitle_i="Negativity"
        featureText_i="Negativity is highly discouraged,let's not dwell on the negative but focus on the positive."
        featureTitle_ii="Registration"
        featureText_ii="Registeration is the first step to positive influence. This by large will help the development visualize the input requirements for the settlement."
        featureTitle_iii="How to Register"
        featureText_iii="To Register:
              1: sign up on MIAMI  
                2: Select Compliance on Clevehills Page
                3: Fill in the Form. 
                4: Return to the Compliance Page after two days and see feedback from Cleverhills. 
                5: If Approved your are ellible to request any services below"
      />
      <div className="containerdashcard">
        <div className="carddashcard">
          <div className="imgBx" data-text="Card 1">
            <img
              src="https://img.freepik.com/free-vector/business-men-signing-contract-online-flat-vector-illustration-men-shaking-hands-through-mobile-phone-screens-electronic-devices-after-successful-deal-partnership-agreement-concept_74855-23975.jpg?w=740&t=st=1666356808~exp=1666357408~hmac=067efa6d58f01b12de4ebac1dda758e1fc06e9859f37e2bd7aea55c1c888afd2"
              style={{ width: "95%" }}
            />
            <span style={{ color: "orangered" }}>REQUESTS</span>
          </div>
          <div className="content">
            <div>
              <h2>REQUESTS</h2>
              <p>
                1: SitePlan:'with Dimensiones' <br />
                2: SiteMap:'Satelite Positioning'
                <br />
                3: Copy:Aggreement Of Sale <br />
                4: Transaction Receipt <br />
                5: Rates Clearance Letter <br />
                6: Copy of approved House Plan <br />
                7: Property Status <br />
                8: Copy of Purchased Deed.
              </p>
            </div>
          </div>
        </div>
        <div className="carddashcard">
          <div className="imgBx" data-text="Card 2">
            <img
              src="https://img.freepik.com/free-photo/realtor-giving-keys-new-owner_23-2147764253.jpg?w=740&t=st=1666357132~exp=1666357732~hmac=4e5676e6403ca44843f5270a0c9d2749ef1c4c5192ed24108af2704baf3f2319"
              style={{ width: "95%" }}
            />
            <span style={{ color: "green" }}>PROPERTY PURCHASE</span>
          </div>
          <div className="content">
            <div>
              <h2>Cleverhills Properties</h2>
              <p>
                You can access a list of properties on sale, their sizes and the
                prices.Will the give you a list of sales agents, their cell
                numbers and where to find them.
              </p>
            </div>
          </div>
        </div>
        <div className="carddashcard">
          <div className="imgBx" data-text="Card 3">
            <img
              src="https://img.freepik.com/free-vector/document-signing-partnership-deal-business-consultation-work-arrangement-client-assistant-writing-contract-cartoon-characters_335657-2342.jpg?w=740&t=st=1666356715~exp=1666357315~hmac=1dfb384db2925558e8f833b136a6fc031a859919ca27c0cab96e39bf01fc96fa"
              style={{ width: "80%" }}
            />
            <span style={{ color: "blue" }}>AGREEMENT OF SALE</span>
          </div>
          <div className="content">
            <div>
              <h2>Agents Only</h2>
              <p>Apply to be a sales agent if not an Agent</p>
            </div>
          </div>
        </div>
        <Link to="/uploadplan" style={{ padding: "0" }}>
          <div className="carddashcard">
            <div className="imgBx" data-text="Card 3">
              <img
                src="https://img.freepik.com/free-vector/engineers-team-discussing-issues-construction-site_74855-4786.jpg?w=740&t=st=1666355254~exp=1666355854~hmac=750328dcf0c95d01d0cc3b9950c166b2514e2d4f9dfb8f65e4d58b15647a2906"
                style={{ width: "95%" }}
              />
              <span style={{ color: "orange" }}>HOUSE PLANS</span>
            </div>
            <div className="content">
              <div>
                <h2>HOUSE PLANS</h2>
                <p>
                  You can your House Plan done and approved, or an already
                  planned house approved through our Architectural Agents and be
                  assigned building Inspectors
                </p>
              </div>
            </div>
          </div>
        </Link>
        <Link to="/compliance" style={{ padding: "0" }}>
          <div className="carddashcard">
            <div className="imgBx" data-text="Card 3">
              <img
                src="https://img.freepik.com/free-vector/abstract-geometric-business-invoice-template_1017-15474.jpg?w=740&t=st=1666356931~exp=1666357531~hmac=e8332787098fe24ce8279ef59af34cb39f88aa2653092b465f67ce4fbabcc1d1"
                style={{ width: "80%" }}
              />
              <span style={{ color: "orange" }}>COMPLIANCE CHECKS</span>
            </div>
            <div className="content">
              <div>
                <h2>Property Registration</h2>
                <p>
                  To Register: 1: sign up on MIAMI <br />
                  2: Select Compliance on Clevehills Page <br />
                  3: Fill in the Form. <br />
                  4: Return to the Compliance Page after two days and see
                  feedback from Cleverhills.
                  <br />
                  5: If Approved your are ellible to request any services below
                </p>
              </div>
            </div>
          </div>
        </Link>
      </div>

      <Features
        featuresD={featuresData}
        heading=" The Opportunity is Now. You Just Need to Realize It. Step into the Future Today. & Make it Happen with CLEVERHILLS"
        contentP="Browse through our services"
      />

      <h1 className="heading gradient__text">Apartments Coming Soon</h1>

      <div className="containergallery">
        <div className="gallery">
          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://img.freepik.com/free-photo/modern-studio-apartment-design-with-bedroom-living-space_1262-12375.jpg?w=740&t=st=1667895913~exp=1667896513~hmac=de0566a071c0bb7dd466165cf4d43d628654c7a352a4ba579f36110ec6a4550b"
              alt="person writing in a notebook beside by an iPad, laptop, printed photos, spectacles, and a cup of coffee on a saucer"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://st3.depositphotos.com/29384342/33867/i/450/depositphotos_338671828-stock-photo-facade-modern-building-hamburg-germany.jpg"
              alt="sunset behind San Francisco city skyline"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://st4.depositphotos.com/2082379/31461/i/450/depositphotos_314617864-stock-photo-sidewalk-in-a-cozy-courtyard.jpg"
              alt="people holding umbrellas on a busy street at night lit by street lights and illuminated signs in Tokyo, Japan"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://static9.depositphotos.com/1008005/1173/i/450/depositphotos_11737715-stock-photo-villas.jpg"
              alt="car interior from central back seat position showing driver and blurred view through windscreen of a busy road at night"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://st2.depositphotos.com/13846464/44018/i/450/depositphotos_440187454-stock-photo-reims-france-january-2021-view.jpg"
              alt="back view of woman wearing a backpack and beanie waiting to cross the road on a busy street at night in New York City, USA"
            />
          </div>

          <div className="gallery-item">
            <img
              className="gallery-image"
              src="https://st4.depositphotos.com/4743681/27965/i/450/depositphotos_279658126-stock-photo-residential-area-in-the-city.jpg"
              alt="man wearing a black jacket, white shirt, blue jeans, and brown boots, playing a white electric guitar while sitting on an amp"
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default HousingScreen;
