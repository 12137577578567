import axios from "axios";
import {
  MEGAJEW_LIST_REQUEST,
  MEGAJEW_LIST_SUCCESS,
  MEGAJEW_LIST_FAIL,
  MEGAJEW_DETAILS_REQUEST,
  MEGAJEW_DETAILS_SUCCESS,
  MEGAJEW_DETAILS_FAIL,
  MEGAJEW_DELETE_SUCCESS,
  MEGAJEW_DELETE_REQUEST,
  MEGAJEW_DELETE_FAIL,
  MEGAJEW_CREATE_REQUEST,
  MEGAJEW_CREATE_SUCCESS,
  MEGAJEW_CREATE_FAIL,
  MEGAJEW_UPDATE_REQUEST,
  MEGAJEW_UPDATE_SUCCESS,
  MEGAJEW_UPDATE_FAIL,
  MEGAJEW_CREATE_REVIEW_REQUEST,
  MEGAJEW_CREATE_REVIEW_SUCCESS,
  MEGAJEW_CREATE_REVIEW_FAIL,
  MEGAJEW_TOP_REQUEST,
  MEGAJEW_TOP_SUCCESS,
  MEGAJEW_TOP_FAIL,
} from "../constants/megajewConstants";
import { logout } from "./userActions";

export const listMegajews = (keyword = "", pageNumber = "") => async (
  dispatch
) => {
  try {
    dispatch({ type: MEGAJEW_LIST_REQUEST });

    const { data } = await axios.get(
      `/api/megajews?keyword=${keyword}&pageNumber=${pageNumber}`
    );

    dispatch({
      type: MEGAJEW_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEGAJEW_LIST_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const listMegajewDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: MEGAJEW_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/megajews/${id}`);

    dispatch({
      type: MEGAJEW_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEGAJEW_DETAILS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const deleteMegajew = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEGAJEW_DELETE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.delete(`/api/megajews/${id}`, config);

    dispatch({
      type: MEGAJEW_DELETE_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: MEGAJEW_DELETE_FAIL,
      payload: message,
    });
  }
};

export const createMegajew = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEGAJEW_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/megajews`, {}, config);

    dispatch({
      type: MEGAJEW_CREATE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: MEGAJEW_CREATE_FAIL,
      payload: message,
    });
  }
};

export const updateMegajew = (megajew) => async (dispatch, getState) => {
  try {
    dispatch({
      type: MEGAJEW_UPDATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/megajews/${megajew._id}`,
      megajew,
      config
    );

    dispatch({
      type: MEGAJEW_UPDATE_SUCCESS,
      payload: data,
    });
    dispatch({ type: MEGAJEW_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: MEGAJEW_UPDATE_FAIL,
      payload: message,
    });
  }
};

export const createMegajewReview = (megajewId, review) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: MEGAJEW_CREATE_REVIEW_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    await axios.post(`/api/megajews/${megajewId}/reviews`, review, config);

    dispatch({
      type: MEGAJEW_CREATE_REVIEW_SUCCESS,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: MEGAJEW_CREATE_REVIEW_FAIL,
      payload: message,
    });
  }
};

export const listTopMegajews = () => async (dispatch) => {
  try {
    dispatch({ type: MEGAJEW_TOP_REQUEST });

    const { data } = await axios.get(`/api/megajews/top`);

    dispatch({
      type: MEGAJEW_TOP_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: MEGAJEW_TOP_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};
