import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import FormContainer from "../components/FormContainer";
import CheckoutSteps from "../components/CheckoutSteps";
import { saveJewShippingAddress } from "../actions/jewscartActions";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";

const JewsShippingScreen = ({ history }) => {
  const jewscart = useSelector((state) => state.jewscart);
  const { jewshippingAddress } = jewscart;

  const [address, setAddress] = useState(jewshippingAddress.address);
  const [city, setCity] = useState(jewshippingAddress.city);
  const [postalCode, setPostalCode] = useState(jewshippingAddress.postalCode);
  const [country, setCountry] = useState(jewshippingAddress.country);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveJewShippingAddress({ address, city, postalCode, country }));
    history.push("/jewpayment");
  };

  return (
    <>
      <Navbar />
      <div
        style={{
          width: "60%",
          marginLeft: "auto",
          marginRight: "auto",
          marginBottom: "15px",
        }}
      >
        <CheckoutSteps step1 step2 />
        <h1 style={{ color: "orange" }}>Personal Details</h1>
        <Form
          onSubmit={submitHandler}
          classNameName="detail-card bounce-in-top"
          style={{ width: "100%" }}
        >
          <Form.Group controlId="address">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter address"
              value={address}
              required
              onChange={(e) => setAddress(e.target.value)}
              style={{ width: "99%", marginBottom: "15px" }}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="city">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter city"
              value={city}
              required
              onChange={(e) => setCity(e.target.value)}
              style={{ width: "99%", marginBottom: "15px" }}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="postalCode">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter postal code"
              value={postalCode}
              required
              onChange={(e) => setPostalCode(e.target.value)}
              style={{ width: "99%", marginBottom: "15px" }}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="country">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter country"
              value={country}
              required
              onChange={(e) => setCountry(e.target.value)}
              style={{ width: "99%", marginBottom: "15px" }}
            ></Form.Control>
          </Form.Group>

          <Button type="submit" variant="primary">
            Continue
          </Button>
        </Form>
      </div>
      <Footer />
    </>
  );
};

export default JewsShippingScreen;
