import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Button, Row, Col, ListGroup, Image, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import CheckoutSteps from "../components/CheckoutSteps";
import { createOrder } from "../actions/orderActions";
import { ORDER_CREATE_RESET } from "../constants/orderConstants";
import { USER_DETAILS_RESET } from "../constants/userConstants";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";

const PlaceOrderScreen = ({ history }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  if (!cart.shippingAddress.address) {
    history.push("/shipping");
  } else if (!cart.paymentMethod) {
    history.push("/payment");
  }
  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 100);
  cart.taxPrice = addDecimals(Number((0.15 * cart.itemsPrice).toFixed(2)));
  cart.totalPrice = (
    Number(cart.itemsPrice) +
    Number(cart.shippingPrice) +
    Number(cart.taxPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;

  useEffect(() => {
    if (success) {
      history.push(`/order/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  const placeOrderHandler = () => {
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  return (
    <>
      <Navbar />
      <CheckoutSteps step1 step2 step3 step4 />
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Personal Details</h2>
              <p>
                <strong>Address:</strong>
                {cart.shippingAddress.address}, {cart.shippingAddress.city}{" "}
                {cart.shippingAddress.postalCode},{" "}
                {cart.shippingAddress.country}
              </p>
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Payment Method</h2>
              <strong>Method: </strong>
              {cart.paymentMethod}
            </ListGroup.Item>

            <ListGroup.Item classNameName="detail-card bounce-in-top">
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message>Your cart is empty</Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item
                      key={index}
                      classNameName="detail-card bounce-in-top"
                    >
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>
                        <Col>
                          <Link to={`/product/${item.product}`}>
                            {item.name}
                          </Link>
                        </Col>
                        <Col md={4}>
                          {item.qty} x{" "}
                          <span style={{ color: "green", fontSize: "small" }}>
                            MJT
                          </span>
                          &nbsp;
                          {item.price}&nbsp; ={" "}
                          <img
                            src="/images/coins.png"
                            alt="gold icon"
                            style={{
                              width: "10%",
                            }}
                          />{" "}
                          &nbsp;{" "}
                          <span style={{ color: "green", fontSize: "small" }}>
                            MJT
                          </span>
                          &nbsp; {item.qty * item.price}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={4}>
          <Card classNameName="detail-card bounce-in-top">
            <ListGroup variant="flush">
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2>Order Summary</h2>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Items</Col>
                  <Col>
                    <img
                      src="/images/coins.png"
                      alt="gold icon"
                      style={{
                        width: "10%",
                      }}
                    />{" "}
                    &nbsp;{" "}
                    <span style={{ color: "green", fontSize: "small" }}>
                      MJT :
                    </span>
                    &nbsp;{cart.itemsPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Shipping</Col>
                  <Col>
                    <img
                      src="/images/coins.png"
                      alt="gold icon"
                      style={{
                        width: "10%",
                      }}
                    />{" "}
                    &nbsp;{" "}
                    <span style={{ color: "green", fontSize: "small" }}>
                      MJT :
                    </span>{" "}
                    &nbsp;{cart.shippingPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Tax</Col>
                  <Col>
                    <img
                      src="/images/coins.png"
                      alt="gold icon"
                      style={{
                        width: "10%",
                      }}
                    />{" "}
                    &nbsp;{" "}
                    <span style={{ color: "green", fontSize: "small" }}>
                      MJT :
                    </span>
                    &nbsp;{cart.taxPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Row>
                  <Col>Total</Col>
                  <Col>
                    <img
                      src="/images/coins.png"
                      alt="gold icon"
                      style={{
                        width: "10%",
                      }}
                    />{" "}
                    &nbsp;{" "}
                    <span style={{ color: "green", fontSize: "small" }}>
                      MJT :
                    </span>
                    &nbsp;{cart.totalPrice}
                  </Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                {error && <Message variant="danger">{error}</Message>}
              </ListGroup.Item>
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <Button
                  type="button"
                  classNameName="btn-block btn-warning"
                  disabled={cart.cartItems === 0}
                  onClick={placeOrderHandler}
                >
                  Place Order
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
      <Footer />
    </>
  );
};

export default PlaceOrderScreen;
