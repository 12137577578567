import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import { listUsers, deleteUser } from "../actions/userActions";

const UserListScreen = ({ history }) => {
  const dispatch = useDispatch();

  const userList = useSelector((state) => state.userList);
  const { loading, error, users } = userList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userDelete = useSelector((state) => state.userDelete);
  const { success: successDelete } = userDelete;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listUsers());
    } else {
      history.push("/login");
    }
  }, [dispatch, history, successDelete, userInfo]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteUser(id));
    }
  };

  return (
    <>
      <Link
        to="/partner"
        classNameName="btn btn-outline-info"
        style={{ marginLeft: "10%" }}
      >
        Back
      </Link>
      <h1
        style={{
          color: "white",
          textAlign: "center",
          backgroundColor: "black",
        }}
      >
        MIAMI USERS
      </h1>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div classNameName="table-header">
            <span classNameName="heading-1">ID</span>
            <span classNameName="heading-2">NAME</span>
            <span classNameName="heading-3">EMAIL</span>
            <span classNameName="heading-4">ADMIN</span>
            <span classNameName="heading-5">OPTIONS</span>
          </div>
          <div className="table-users">
            <table>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    {" "}
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <i classNameName="fas fa-check"></i>
                    ) : (
                      <i classNameName="fas fa-times"></i>
                    )}
                  </td>
                  <td>
                    {" "}
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" classNameName="btn-sm">
                        <i classNameName="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      classNameName="btn-sm"
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i classNameName="fas fa-trash"></i>
                    </Button>{" "}
                  </td>
                </tr>
              ))}
            </table>
          </div>

          {/* <Table striped bordered hover responsive classNameName="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>EMAIL</th>
                <th>ADMIN</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user._id}>
                  <td>{user._id}</td>
                  <td>{user.name}</td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>
                    {user.isAdmin ? (
                      <i
                        classNameName="fas fa-check"
                        style={{ color: "green" }}
                      ></i>
                    ) : (
                      <i classNameName="fas fa-times" style={{ color: "red" }}></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer to={`/admin/user/${user._id}/edit`}>
                      <Button variant="light" classNameName="btn-sm">
                        <i classNameName="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      classNameName="btn-sm"
                      onClick={() => deleteHandler(user._id)}
                    >
                      <i classNameName="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
        </>
      )}
    </>
  );
};

export default UserListScreen;
