import * as ttapi from "@tomtom-international/web-sdk-services";
import "./css/maps.css";
import "bootstrap/dist/css/bootstrap.min.css";
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import axios from "axios";
import "@tomtom-international/web-sdk-maps/dist/maps.css";
import * as tt from "@tomtom-international/web-sdk-maps";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";




function MapScreen() {
    const mapElement = useRef()
    const [map, setMap] = useState({})


    // create a function to post data and print to console results using axios
const postToServer = async () => {
    const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

    try {
      // make a post request to the server
      const response = await axios.post(    
     "https://api.tomtom.com/routing/matrix/2?key=6n7m8rBSBr9kgpglqJJMerDOp24FTHJP",
     config,
        { data: "test" }
      );
      // print the response to the console
      console.log(response);
    } catch (error) {
      // print any errors to the console
      console.log(error);
    }
  };

    const convertToPoints = (lngLat) => {
        return {
            point: {
                latitude: lngLat.lat,
                longitude: lngLat.lng
            }
        }

    }
    const drawRoute = (geoJson, map) => {
        if (map.getLayer('route')) {
            map.removeLayer('route')
            map.removeSource('route')
        }

        map.addLayer({
            id: 'route',
            type: 'line',
            source: {
                type: 'geojson',
                data: geoJson
            },
            paint: {
                'line-color': 'orange',
                'line-width': 6
            }
        })
    }


    const addDeliveryMarker = (lngLat, map) => {
        const element = document.createElement('div')
        element.classNameName = "marker-delivery"
        const popupOffset = {
            bottom: [0, -25]
        }
        const popup = new tt.Popup({ offset: popupOffset }).setHTML('Agent')
        const deliveryM = new tt.Marker({
            element: element
        })
            .setLngLat(lngLat)
            .addTo(map)
        deliveryM.setPopup(popup).togglePopup()
    }


    
    const [longitude, setLongitude] = useState(null)  //31.053028
    const [latitude, setLatitude] = useState(null)  //-17.824858
    // const [location, setLocation] = useState(null);

    useEffect(() => {
        navigator.geolocation.watchPosition((position) => {
            const { latitude, longitude } = position.coords;
            setLongitude(longitude)
            setLatitude(latitude)
            //   setLocation({ latitude, longitude });
        });
    }, [longitude, latitude]);

    useEffect(() => {
        const origin = {
            lng: longitude,
            lat: latitude,
        }
        const destinations = [];
        let map = tt.map({
            key: "6n7m8rBSBr9kgpglqJJMerDOp24FTHJP",
            container: mapElement.current,
            center: [longitude, latitude],
            stylesVisibility: {
                trafficIncidents: true,
                trafficFlow: true
            },
            zoom: 14,
        })

        setMap(map)

        const addMarker = () => {
            // Set the offset for the popup so that it appears above the marker.
            const popupOffset = {
                bottom: [0, -25]
            }
            // Create a popup object.
            const popup = new tt.Popup({ offset: popupOffset }).setHTML('This is you')
            // Create a marker element.
            const element = document.createElement('div')
            element.classNameName = "marker"
            // Create a marker object.
            const marker = new tt.Marker({
                draggable: false,
                element: element
            })
                .setLngLat([longitude, latitude])
                .addTo(map)
            // Add the popup to the marker and then show it.
            marker.setPopup(popup).togglePopup()
        }


        addMarker()

        const sortDestinations = (locations) => {
            const pointsForDestinations = locations.map((destination) => {
                return convertToPoints(destination)
            })

            const callParameters = {
                key: "6n7m8rBSBr9kgpglqJJMerDOp24FTHJP",
                destinations: pointsForDestinations,
                origins: [convertToPoints(origin)],
            }

            return new Promise((resolve, reject) => {
                ttapi.services
                    .matrixRouting(callParameters)
                    .then((matrixAPIResults) => {
                        const results = matrixAPIResults.matrix[0];// matrix API response
                        const resultsArray = results.map((result, index) => {
                            return {
                                location: locations[index],
                                drivingtime: result.response.routeSummary.travelTimeInSeconds
                            }

                        })

                        resultsArray.sort((a, b) => {
                            return a.drivingtime - b.drivingtime
                        })

                        const sortedLocations = resultsArray.map((result) => {
                            return result.location
                        })

                        resolve(sortedLocations)
                    })
            })
        }



        const recalculateRoutes = () => {
            sortDestinations(destinations).then((sorted) => {
                sorted.unshift(origin)
                ttapi.services
                    .calculateRoute({
                        key: "6n7m8rBSBr9kgpglqJJMerDOp24FTHJP",
                        locations: sorted,
                    })
                    .then((routeData) => {
                        const geojson = routeData.toGeoJson()
                        drawRoute(geojson, map)
                    })
            })
        }



        map.on('click', (e) => {
            destinations.push(e.lngLat)
            addDeliveryMarker(e.lngLat, map)
            recalculateRoutes()
        })

        return () => map.remove()




    }, [longitude, latitude]);


    return (
        <>
            {longitude === null ? (<h1 classNameName="gradient__text bounce-in-top" style={{ textAlign: 'center', fontSize: "35px" }}>Loading ...</h1>) : (
                <h1 classNameName="gradient__text bounce-in-top" style={{ textAlign: 'center', fontSize: "35px" }}>Locations</h1>
            )}

            <div
                classNameName="gpt3__possibility section__padding bounce-in-top"
                id="possibility"
            >
                <div classNameName="gpt3__possibility-image">

                    {map && <div classNameName="MapContainer">
                        <div ref={mapElement} classNameName="map" />
                    </div>}
                </div>
                <div classNameName="gpt3__possibility-content">
                    {longitude === null && <Loader />}
                    <h4>Find Cleverhill Agents</h4>
                    <h1 classNameName="gradient__text">Agents</h1>
                    <p>Wer</p>

                    <div classNameName="wrapb">
                        <Link
                            onClick={postToServer}
                            style={{
                                backgroundColor: "orangered",
                                color: "white",
                                padding: "5px 40px",
                                borderRadius: "15px",
                            }}
                        >
                            Home
                        </Link>
                    </div>
                </div>
            </div>
        </>
    );
}

export default MapScreen;
