import React, { useState, useEffect, useRef, useContext } from 'react';
import { toast, Toaster } from "react-hot-toast";
import SyndicateContext from "../context/syndicateContext";
import PhoneInput from "react-phone-input-2";
import Loader from "./Loader";

const Users = ({ userPhone, close, setclose, showMenu }) => {
    const [name, setName] = useState("");
    const {
        syndicates,
        findSyndicates,
        creatteSyndicate,
        createSuccess,
        errorMessage,
        resetSyndicateState,
        loading,
        setLoading,
        dashboardData,
        path,
        setPath,
        getAgent,
        agent,
        addToSyndicatte,
        deleteAgent,
        setDashboard
    } = useContext(SyndicateContext);

    const [foundUsers, setFoundUsers] = useState(dashboardData.users);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalDelete, setModalDelete] = useState(false)
    const [addUser, setAddUser] = useState(false)
    const [phone, setPhone] = useState("");
    const [viewUser, setUser] = useState(null)
    const [showUserCard, setshowUserCard] = useState(false)
    const [addedUserField, setAddedUserField] = useState('stocks')
    const [delete_id, setDeleteId] = useState(null)


    const handleOpenModal = () => {
        setModalOpen(true);
    };

    const handleAddUserModal = () => {
        setAddUser(true)
    }
    const handleCloseModal = () => {
        setModalOpen(false);
        setAddUser(false);
        setshowUserCard(false)
        setModalDelete(false)
    };

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== "") {
            const results = dashboardData.users.filter((user) => {
                return user.userName.toLowerCase().startsWith(keyword.toLowerCase());
                // Use the toLowerCase() method to make it case-insensitive
            });
            setFoundUsers(results);
        } else {
            setFoundUsers(dashboardData.users);
            // If the text field is empty, show all users
        }

        setName(keyword);
    };

    const handleAddField = (e) => {
        setAddedUserField(e.target.value)
    }

    // Add to Syndicate
    const addToSyndicate = async (phoneNumber) => {
        // Check if the phone number is already part of the syndicate
        const existingUser = dashboardData.users.find(user => user.userAccount === phoneNumber)
        if (existingUser) {
            toast.error("You can't add a number that is already part of the syndicate")
            return
        }

        // Add the phone number to the syndicate
        try {
            await addToSyndicatte(phoneNumber, addedUserField, dashboardData.syndicatAccount, userPhone)
            toast.success("Agent Added Successfully")
            close()
        } catch (error) {
            // Handle the error
            toast.error(error.message)
        }
    }

    //Show User
    const ShowUserModal = ({ modalOpen, handleCloseModal, data }) => {
        if (!modalOpen) {
            return null;
        }

        return (
            <>
                {loading && (<Loader />)}
                <div classNameName="modall">
                    <div classNameName="modal-content">
                        <button style={{ width: "20px", float: "right", backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", margin: "1px", padding: "3px", marginTop: "3px" }} onClick={handleCloseModal}>X</button>
                        <img src={data.profilePic} alt="profile" style={{ width: "200px", height: "200px" }} />
                        <h1 style={{ color: "orange" }}>{data.name}</h1>
                        <label for="cars" style={{ fontSize: "14px", color: "#EEEEEE" }}>Account: {data.accountNumber}</label>
                        <label for="cars" style={{ fontSize: "14px", color: "#EEEEEE" }}>Email: {data.email}</label>
                        <p style={{ color: "white", fontSize: "17px" }}>Bio: {data.bio}</p>
                        <label for="cars" style={{ fontSize: "14px", color: "#EEEEEE" }}>Assign a field:</label>
                        <select name="cars" id="cars"
                            style={{ height: '36px', fontSize: "16px", marginBottom: "5px" }}
                            value={addedUserField}
                            onChange={handleAddField}
                        >
                            <option value="stocks" selected>Stocks</option>
                            <option value="commodities">Commodities</option>
                            <option value="sales">Sales</option>
                            <option value="stocksalescomodities">Stocks and Sales and Commodities</option>
                            <option value="stockssales">Stocks and Sales</option>
                            <option value="salescommodities">Sales and Commodities</option>
                            <option value="admin">Admin</option>
                        </select>
                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(#3ee169, #09656f)", color: "white" }}
                            onClick={(e) => addToSyndicate(data.accountNumber)}
                        >
                            Add To Syndicate
                        </a>
                    </div>
                </div>
            </>
        );
    };

    //Modal For Assigning Field
    const Modal = ({ modalOpen, handleCloseModal, dataTable }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)

        const handleChangeField = (e) => {
            if (dataTable.users.length === 1) {
                toast.error("Can't Change Field When you are the only admin")
                setModalOpen(false);
            } else {
                setModalOpen(false);
            }
        }

        const saveField = () => {
            if (dataTable.users.length === 1) {
                toast.error("Can't Change Field When you are the only admin")
            } else {
                setShowModal(false)
            }
        }

        if (!modalOpen) {
            return null;
        }

        return (
            <>
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                            <h1 style={{ color: "orange" }}>Options</h1>
                            <label for="cars" style={{ fontSize: "14px", color: "white" }}>Change Field:</label>
                            <select name="cars" id="cars"
                                style={{ height: '36px', fontSize: "16px" }}
                                value={field}
                                onChange={handleChangeField}
                            >
                                <option value="stocks" >Stocks</option>
                                <option value="commodities">Commodities</option>
                                <option value="sales">Sales</option>
                                <option value="All">SSC</option>
                                <option value="Admin" selected>Admin</option>
                            </select>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }}
                                onClick={saveField}
                            >
                                Save
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleCloseModal}>
                                Close
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

    //Add User
    const AddUserModal = ({ modalOpen, handleCloseModal, dataTable }) => {
        const searchUser = () => {
            getAgent(phone, (agent) => {
                if (agent) {
                    console.log("Agent Found Sir " + agent);
                    setUser(agent)
                    setAddUser(false)
                    setshowUserCard(true)
                } else {
                    console.log("Agent Not Found Sir ");
                }
            });
        }

        const inputRef = useRef();

        const focusInput = () => {
            inputRef.current.focus();
        };

        if (!modalOpen) {
            return null;
        }

        useEffect(() => {
            if (agent) {
                console.log("Found Agent :" + agent)
            }
        }, [loading]);

        return (
            <>
                {loading && (<Loader />)}
                <div classNameName="modall">
                    <div classNameName="modal-content">
                        <button style={{ width: "20px", float: "right", backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", margin: "1px", padding: "3px", marginTop: "3px" }} onClick={handleCloseModal}>X</button>
                        <h1 style={{ color: "orange" }}>Add Agent</h1>
                        <label for="cars" style={{ fontSize: "14px", color: "white" }}>Enter USER account:</label>
                        <PhoneInput country={"zw"} value={phone} onChange={setPhone} style={{ width: "80%", marginBottom: "10px", fontSize: "15px", color: "black" }} />
                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(#3ee169, #09656f)", color: "white" }}
                            onClick={searchUser}
                        >
                            Search
                        </a>
                    </div>
                </div>
            </>
        );
    };

    //Modal For Assigning Field
    const DeleteUser = ({ modalOpen, handleCloseModal, dataTable }) => {
        const [field, setField] = useState(null)
        const [showModal, setShowModal] = useState(true)

        const handleDeleteAgent = (e) => {
            if (dashboardData.users.length === 1) {
                toast.error("You can't delete the user");
            } else {
                deleteAgent(delete_id, dashboardData.syndicatAccount)
                if (errorMessage === null && loading === false) {
                    toast.success("Delete Succefull");
                    setclose(false)
                    showMenu(true)
                    window.location.reload();
                }
            }

        }


        if (!modalOpen) {
            return null;
        }

        useEffect(() => {
            if (dashboardData) {
                console.log("Found Syndicate :" + dashboardData)
            }
        }, [dashboardData]);

        return (
            <>
                {loading && (<Loader />)}
                {showModal && (
                    <div classNameName="modall">
                        <div classNameName="modal-content">
                            <h1 style={{ color: "orange" }}>Are you sure you want to delete?</h1>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(#3ee169, #09656f)", color: "white" }} onClick={handleCloseModal}
                            >
                                No
                            </a>
                            <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={handleDeleteAgent}>
                                Yes
                            </a>
                        </div>
                    </div>
                )}

            </>
        );
    };

    const handleDelete = (e) => {
        setDeleteId(e)
        setModalDelete(true)
    }



    useEffect(() => {
        if (dashboardData !== null) {
            console.log("Found Data" + dashboardData);
        }
    }, [dashboardData]);

    return (
        <div classNameName="containerUsers bounce-in-top">
            <button style={{ width: "20px", float: "right", backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", margin: "1px", padding: "3px", marginTop: "30px" }} onClick={close}>X</button>
            <div className="wrap">
                <div className="search">
                    <input type="text" value={name} onChange={filter} className="searchTerm" placeholder="Who are you looking for?" />
                    <button type="submit" className="searchButton">
                        <i className="fa fa-search"></i>
                    </button>
                </div>
            </div>
            <Toaster toastOptions={{ duration: 4000 }} />
            <div classNameName="cardGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                <div classNameName="ContainerGrid" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)" }}>
                    <div classNameName="CardStack">
                        {foundUsers && foundUsers.length > 0 ? (
                            foundUsers.map((user) => (
                                <div classNameName="Cardgrid" key={user._id} >
                                    <div classNameName="AvatarWrap">
                                        <img classNameName="Avatar" src="https://cdn.pixabay.com/photo/2016/08/20/05/51/avatar-1606939_1280.png" alt="" />
                                    </div>
                                    <div classNameName="Title">{user.userName}</div>
                                    <div classNameName="Description">
                                        Field: {user.assignedField} <br />
                                        Account Number: {user.userAccount}

                                    </div>
                                    <div classNameName="Actions">
                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #3b2742, #8f3b59, #d56346, #e7ac0f)", color: "white" }} onClick={() => handleDelete(user._id)}>
                                            Delete
                                        </a>

                                        <a type="button" classNameName="Primary" href="#" style={{ backgroundImage: "linear-gradient(to right top, #020306, #353537, #686869, #9f9fa0, #dadada)", color: "white" }} onClick={handleOpenModal}>
                                            Options
                                        </a>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <h1 style={{ color: "orange" }}>No results found!</h1>
                        )}

                        <DeleteUser modalOpen={modalDelete} handleCloseModal={handleCloseModal} />
                        <Modal modalOpen={modalOpen} dataTable={dashboardData.users} handleCloseModal={handleCloseModal} />
                        <AddUserModal modalOpen={addUser} dataTable={dashboardData.users} handleCloseModal={handleCloseModal} />
                        <ShowUserModal modalOpen={showUserCard} data={viewUser} handleCloseModal={handleCloseModal} />
                    </div>

                </div>
            </div>
            <button type="button" style={{
                backgroundImage: "linear-gradient(#3ee169, #09656f)",
                width: "40%", fontSize: "17px", marginLeft: "auto", marginRight: "auto"
            }} onClick={handleAddUserModal}>Add User</button>
        </div>
    );
}


export default Users;
