import axios from "axios";
import { JEWCART_CLEAR_ITEMS } from "../constants/jewscartConstants";
import {
  JEWORDER_CREATE_REQUEST,
  JEWORDER_CREATE_SUCCESS,
  JEWORDER_CREATE_FAIL,
  JEWORDER_DETAILS_FAIL,
  JEWORDER_DETAILS_SUCCESS,
  JEWORDER_DETAILS_REQUEST,
  JEWORDER_PAY_FAIL,
  JEWORDER_PAY_SUCCESS,
  JEWORDER_PAY_REQUEST,
  JEWORDER_LIST_MY_REQUEST,
  JEWORDER_LIST_MY_SUCCESS,
  JEWORDER_LIST_MY_FAIL,
  JEWORDER_LIST_FAIL,
  JEWORDER_LIST_SUCCESS,
  JEWORDER_LIST_REQUEST,
  JEWORDER_DELIVER_FAIL,
  JEWORDER_DELIVER_SUCCESS,
  JEWORDER_DELIVER_REQUEST,
} from "../constants/jewsorderConstants";
import { logout } from "./userActions";

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JEWORDER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.post(`/api/jeworders`, order, config);

    dispatch({
      type: JEWORDER_CREATE_SUCCESS,
      payload: data,
    });
    dispatch({
      type: JEWCART_CLEAR_ITEMS,
      payload: data,
    });
    localStorage.removeItem("jewCartItems");
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_CREATE_FAIL,
      payload: message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JEWORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/jeworders/${id}`, config);

    dispatch({
      type: JEWORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_DETAILS_FAIL,
      payload: message,
    });
  }
};

export const payOrder = (orderId, paymentResult) => async (
  dispatch,
  getState
) => {
  try {
    dispatch({
      type: JEWORDER_PAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/jeworders/${orderId}/pay`,
      paymentResult,
      config
    );

    dispatch({
      type: JEWORDER_PAY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_PAY_FAIL,
      payload: message,
    });
  }
};

export const deliverOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: JEWORDER_DELIVER_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.put(
      `/api/jeworders/${order._id}/deliver`,
      {},
      config
    );

    dispatch({
      type: JEWORDER_DELIVER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_DELIVER_FAIL,
      payload: message,
    });
  }
};

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: JEWORDER_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/jeworders/myorders`, config);

    dispatch({
      type: JEWORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_LIST_MY_FAIL,
      payload: message,
    });
  }
};

export const listOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: JEWORDER_LIST_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/jeworders`, config);

    dispatch({
      type: JEWORDER_LIST_SUCCESS,
      payload: data,
    });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: JEWORDER_LIST_FAIL,
      payload: message,
    });
  }
};
