import React, { useEffect } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Table, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import Paginate from "../components/Paginate";
import {
  listProducts,
  deleteProduct,
  createProduct,
} from "../actions/productActions";
import { PRODUCT_CREATE_RESET } from "../constants/productConstants";

const ProductListScreen = ({ history, match }) => {
  const pageNumber = match.params.pageNumber || 1;

  const dispatch = useDispatch();

  const productList = useSelector((state) => state.productList);
  const { loading, error, products, page, pages } = productList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    dispatch({ type: PRODUCT_CREATE_RESET });

    if (!userInfo || !userInfo.isAdmin) {
      history.push("/login");
    }

    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts("", pageNumber));
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
    pageNumber,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Are you sure")) {
      dispatch(deleteProduct(id));
    }
  };

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  return (
    <>
      <Row classNameName="align-items-center">
        <Col>
          <h1 style={{ color: "orange", margin: "1rem" }}>Products</h1>
        </Col>
        <Col classNameName="text-right">
          <Link
            to="/profile"
            classNameName="btn btn-outline-info"
            style={{ marginRight: "3rem" }}
          >
            Back
          </Link>
          <Button classNameName="my-3" onClick={createProductHandler}>
            <i classNameName="fas fa-plus"></i> Create Product
          </Button>
        </Col>
      </Row>
      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <>
          <div classNameName="table-wrapper detail-card bounce-in-top">
            <table classNameName="table bounce-in-top" id="table">
              <thead classNameName="bounce-in-top">
                <tr classNameName="table-head detail-card bounce-in-top">
                  <th classNameName="table-cell align-left">ID</th>
                  <th classNameName="table-cell align-left">NAME</th>
                  <th classNameName="table-cell align-left">PRICE</th>
                  <th classNameName="table-cell align-left">CATEGORY</th>
                  <th classNameName="table-cell align-left">BRAND</th>
                  <th classNameName="table-cell align-left">OPTIONS</th>
                </tr>
              </thead>

              <tbody style={{ color: "white" }}>
                {products.map((product) => (
                  <tr key={product._id}>
                    <td>{product._id}</td>
                    <td style={{ paddingLeft: "1rem" }}>
                      {product.name.substring(0, 10)}
                    </td>
                    <td style={{ paddingLeft: "1rem" }}>
                      <sup style={{ color: "green" }}>MJT</sup>
                      {product.price}
                    </td>
                    <td style={{ paddingLeft: "1rem" }}>{product.category}</td>
                    <td style={{ paddingLeft: "1rem" }}>{product.brand}</td>
                    <td style={{ paddingLeft: "1rem" }}>
                      <LinkContainer to={`/admin/product/${product._id}/edit`}>
                        <Button variant="light" classNameName="btn-sm">
                          <i
                            classNameName="fas fa-edit"
                            aria-hidden="true"
                            style={{ color: "green" }}
                          ></i>
                        </Button>
                      </LinkContainer>

                      <Button
                        variant="danger"
                        classNameName="btn-sm"
                        onClick={() => deleteHandler(product._id)}
                      >
                        <i classNameName="fas fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>

            <div classNameName="no-results hidden" id="no-results">
              <p classNameName="no-results-message">No results found.</p>
            </div>
            {/* <!-- /#no-results --> */}
          </div>
          {/* <Table striped bproducted hover responsive classNameName="table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>NAME</th>
                <th>
                  <span style={{ color: "green", fontSize: "small" }}>
                    MJT :
                  </span>{" "}
                  &nbsp;PRICE
                </th>
                <th>CATEGORY</th>
                <th>BRAND</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {products.map((product) => (
                <tr key={product._id}>
                  <td>{product._id}</td>
                  <td>{product.name}</td>
                  <td>
                    <span style={{ color: "green", fontSize: "small" }}>
                      MJT :
                    </span>{" "}
                    &nbsp;{product.price}
                  </td>
                  <td>{product.category}</td>
                  <td>{product.brand}</td>
                  <td>
                    <LinkContainer to={`/admin/product/${product._id}/edit`}>
                      <Button variant="light" classNameName="btn-sm">
                        <i classNameName="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant="danger"
                      classNameName="btn-sm"
                      onClick={() => deleteHandler(product._id)}
                    >
                      <i classNameName="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table> */}
          <Paginate pages={pages} page={page} isAdmin={true} />
        </>
      )}
    </>
  );
};

export default ProductListScreen;
