import React from "react";
import Article from "../../components/article/Article";
// import { blog01, blog02, blog03, blog04, blog05 } from "./imports";
import "./certificates.css";

const Certificates = ({ contract }) => {
  return (
    <div className="gpt3__blog section__padding bounce-in-top" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="gradient__text">Compliance Certifications</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container_groupA">
          <div
            style={{ color: "white", textAlign: "left" }}
            className="agreementOFS"
          >
            <h3 style={{ textAlign: "center" }} className="gradient__text">
              MEMORANDUM OF AGREEMENT OF SALE
            </h3>
            <br />
            <h5 style={{ textAlign: "center" }}>
              MADE AND ENTERED INTO BY AND BETWEEN{" "}
            </h5>{" "}
            <br />
            <h5 style={{ textAlign: "center" }}>
              HOUSEFIN (PVT) LTD (HEREAFTER{" "}
              {contract.ESTATEagent === undefined
                ? "...................................."
                : contract.ESTATEagent}{" "}
              ) REPRESENTED BY
            </h5>{" "}
            <br />
            <p>
              I{" "}
              <span className="gradient__text">
                {contract.ESTATEagent === undefined
                  ? "...................................."
                  : contract.ESTATEagent}
              </span>{" "}
              registered with limited liability in accordance with the laws of
              Zimbabwe of c/o 1st Floor, Fidelity Life Tower, Harare herein
              representing
              <span className="gradient__text">
                {contract.PURCHASER === undefined
                  ? "...................................."
                  : contract.PURCHASER}
              </span>{" "}
              <br />{" "}
            </p>{" "}
            <p style={{ textAlign: "center" }}>And</p>
            <br />
            <p>
              <span className="gradient__text">
                ....................................
              </span>{" "}
              (hereinafter referred to as “the Purchasers”), of the other part,
              WHEREAS the seller is the registered owner of certain piece of
              land situate in the district of Salisbury measuring{" "}
              <span className="gradient__text">
                {contract.AREAsqm === undefined
                  ? "...................................."
                  : contract.AREAsqm}
              </span>{" "}
              square meters called{" "}
              <span className="gradient__text">
                {contract.PROPERTYdescription === undefined
                  ? "...................................."
                  : contract.PROPERTYdescription}
              </span>{" "}
              (hereinafter referred to as “the property”);
            </p>{" "}
            <br />{" "}
            <p>
              AND WHEREAS the seller is desirous of selling the property to the
              Purchaser who has agreed to purchase the same; AND WHEREAS the
              parties hereto have agreed to enter into this agreement for the
              purpose of recording their business transaction;{" "}
            </p>{" "}
            <br />
            <h4 style={{ textAlign: "center" }}>
              NOW, THEREFORE, IT IS HEREBY RECORDED THAT THE PARTIES HERETO HAVE
              AGREED AS FOLLOWS{" "}
            </h4>{" "}
            <br />
            <p>
              1. The effective date of this agreement shall be date of its final
              signature by the parties.
            </p>{" "}
            <br />
            <p>
              2. The seller hereby sells and the Purchasers hereby purchase the
              property
            </p>{" "}
            <br />
            <p>
              3. The purchase price for the property with all amounts
              denominated and payable in currency notes of
              <span className="gradient__text">
                {contract.PURCHASEcurrency === undefined
                  ? "...................................."
                  : contract.PURCHASEcurrency}
              </span>{" "}
              is the sum of
              <span className="gradient__text">
                {contract.PURCHASEprice === undefined
                  ? "...................................."
                  : contract.PURCHASEprice}
              </span>{" "}
              and the same shall be payable as follows:
            </p>{" "}
            <br />
            <p>
              3.1. <span className="gradient__text">............</span>{" "}
              <span className="gradient__text">
                ....................................
              </span>{" "}
              Initial deposit
            </p>{" "}
            <br />
            <p>
              3.2.
              <span classNameName="gradient__text">
                {contract.PURCHASEcurrency === undefined
                  ? "...................................."
                  : contract.PURCHASEcurrency}
              </span>{" "}
              <span classNameName="gradient__text">
                {contract.DEPOSIT === undefined
                  ? "...................................."
                  : contract.DEPOSIT}
              </span>{" "}
              Deposit
            </p>
            <p>
              3.3. <span classNameName="gradient__text">............</span>{" "}
              <span classNameName="gradient__text">
                ...........................
              </span>{" "}
              Monthly Installments <br />
            </p>{" "}
            <br />
            <p>
              for
              <span classNameName="gradient__text">
                {contract.INSTALMENTS === undefined
                  ? "...................................."
                  : contract.INSTALMENTS}
              </span>
              months.
            </p>{" "}
            <p>
              Amounts to be paid to: <br />
              Local Clients:
            </p>
            &nbsp;&nbsp;<p>One Money: 0714928544</p> <br />
            Diaspora Clients: <br />
            <p>
              A/C Name:{" "}
              <span classNameName="gradient__text">
                {contract.ACname === undefined
                  ? "...................................."
                  : contract.ACname}
              </span>
            </p>
            <p>
              Bank :{" "}
              <span classNameName="gradient__text">
                {contract.BANK === undefined
                  ? "...................................."
                  : contract.BANK}
              </span>
            </p>
            <p>
              Sort Code :{" "}
              <span classNameName="gradient__text">
                {contract.SORTcode === undefined
                  ? "...................................."
                  : contract.SORTcode}
              </span>
            </p>
            <p>
              Acc No.:{" "}
              <span classNameName="gradient__text">
                {contract.ACnum === undefined
                  ? "...................................."
                  : contract.ACnum}
              </span>
            </p>
            <p>
              IBAN:{" "}
              <span classNameName="gradient__text">
                {contract.IBAN === undefined
                  ? "...................................."
                  : contract.IBAN}
              </span>
            </p>
            <p>
              Swift code:{" "}
              <span classNameName="gradient__text">
                {contract.SWIFTcode === undefined
                  ? "...................................."
                  : contract.SWIFTcode}
              </span>
            </p>{" "}
            <br />
            <p>
              4. Transfer of the property from the seller to the purchasers
              shall be effected by Messers
              <span classNameName="gradient__text">
                {contract.CONVEYANCER === undefined
                  ? "...................................."
                  : contract.CONVEYANCER}
              </span>
              In this respect the purchasers shall pay all transfer charges
              levied by the conveyancers.
            </p>
            <br />
            <p>
              5. The Sellers shall be responsible for the payment of Value Added
              Tax being{" "}
              <span classNameName="gradient__text">
                ............(15%)...............
              </span>{" "}
              of the purchase price.
            </p>
            <br />
            <p>
              {" "}
              6. The Purchaser hereby authorizes the release of the purchase
              price to the seller before transfer of the property to the
              Purchasers.
            </p>
            <br />
            <p>
              7. The Agent`s commission and in respect of this sale shall be
              <span classNameName="gradient__text">
                ............(7.5%)...............
              </span>{" "}
              of the gross amount paid by the Purchasers including interest and
              shall be payable by the seller. The transfer of the property shall
              not be registered unless and until the Agent`s commission on this
              sale shall have been paid or secured to the satisfaction of
              Crowhill Estate Township and the seller hereby authorizes the said
              Agent to deduct the commission from the purchase money when the
              same becomes payable to him. Commission shall be deemed to be
              earned on the date of the last signature to the agreement. The
              parties to this agreement acknowledge that the commission shall be
              deducted from the purchasers’ deposit. Should any party to this
              Agreement breach the terms of such agreement causing the agreement
              to be cancelled, the defaulting party shall be liable for the
              agent`s commission.
            </p>
            <br />
            <p>
              8. The Purchasers shall take vacant possession and occupation of
              the property upon payment of the full purchase price.
            </p>{" "}
            <br />
            <p>
              9. The risk and profit in the property hereby sold shall pass from
              the Seller to the Purchasers on the date on which vacant
              possession is given to the Purchasers or the date on which
              transfer is passed to the Purchasers, whichever shall be the
              sooner, and from that date the Purchasers shall be entitled to all
              rents and other profits (if any) accruing from the said property
              and shall be liable for all rates and taxes and other imposts
              levied thereon and all other expenses of whatsoever nature in
              respect of the said property.
            </p>{" "}
            <br />
            <p>
              10. The Purchasers acknowledge that they have made themselves
              fully acquainted with the property, with any servitude or
              servitudes, lease or leases to which it is subject, with its
              nature, extent, boundaries, beacons and locality and with all the
              terms and conditions imposed by the Town Planning Authority or any
              other Board, body, authority or person
            </p>{" "}
            <br />
            <p>
              11. In the event that either party (hereinafter referred to as
              “the breaching party”) commits a material breach of any obligation
              or warranty owed or given in terms of this agreement to the other
              party (hereinafter referred to as “the aggrieved party”) and
              remains in default for either 7 (seven) days following despatch by
              the aggrieved party to the breaching party of a registered letter
              requiring remedy of such default or for 3 (three) days following
              delivery to the breaching party of a letter from the aggrieved
              party requiring remedy of such default, the aggrieved party shall
              have the immediate right:-
              <br /> <br />
              (a) either to enforce the aggrieved party’s rights in terms of
              this agreement and to claim payment or performance of whatever may
              be overdue or requisite, together with interest on any amount of
              money owed at the commercial bank prime lending rate from the date
              of default until the date of payment, with all interest accruals
              being capitalised monthly on the first day of each month <br />{" "}
              <br />
              (b) or to cancel this agreement and claim such damages as have
              been caused by the breach and/or cancellation (together with
              interest on such damages at the aforesaid rate, capitalised as
              aforesaid); <br /> <br />
              (c) or, if it is the Purchaser in breach, the seller shall demand
              forthwith his exclusive right of occupation of the property; and,
              in addition, the aggrieved party shall be entitled to institute
              legal proceedings in the High Court of Zimbabwe and to obtain
              summary judgement and recover legal costs on the Attorney-Client
              scale and any collection commission properly charged by the
              aggrieved party`s legal practitioners(s).
            </p>{" "}
            <br /> <br />
            <p>
              12. This agreement represents the entire contract between the
              parties. No purported amendment or waiver of or addition to any
              provision hereof or amendment or waiver of any notice given in
              terms hereof or collateral or replacement agreement in relation to
              the subject matter hereof shall be of any force or effect unless
              and until reduced to writing in a document or series of documents,
              duly signed by the parties, each before 2(two) witnesses.
            </p>{" "}
            <br /> <br />
            <p>
              {" "}
              13. The seller and the Purchasers each choose domicilium citandi
              et executandi at the address given after their respective names in
              the preamble to this agreement.
            </p>{" "}
            <br /> <br />
            <p>
              14. The costs of the preparation, drafting and printing of this
              agreement in the sum of $50 shall be paid by the Purchaser.
            </p>{" "}
            <br /> <br />
            <h3>EFFECTED BY THE SELLER AT HARARE THIS </h3>
            <span classNameName="gradient__text">
              {contract.purchaseDATE === undefined
                ? "...................................."
                : contract.purchaseDATE}
            </span>{" "}
            <br />
            {contract.SITEplan === undefined ? (
              <button>GET SITE PLAN</button>
            ) : (
              <a
                classNameName="btn cta"
                href={`/images/siteplans/${contract.SITEplan}.pdf`}
                id="pdf"
              >
                {" "}
                Get Site Plan
              </a>
            )}
            <br />
            <span>PROOF OF PAYMENT PDF</span>{" "}
            <span classNameName="gradient__text">
              {contract.RATESpaymentUPDATE === undefined
                ? "...................................."
                : contract.RATESpaymentUPDATE}
            </span>{" "}
            <br />
            AGREEMENT OF SALE PDF.
            <span classNameName="gradient__text">
              ...........................
            </span>{" "}
            <br />
            RATES AND TAX CLEARANCE.{" "}
            <span classNameName="gradient__text">
              ...........................
            </span>{" "}
            <br /> <br /> EFFECTED BY CONSTITUTIONS <br /> <br /> HANDAC <br />
            CROWHILL & CHARLOTTE BROOKE <br /> <br />
            Note: Once you get an authorised site plan from us, contact The
            Building Plan Administrator Georgona Table for building plan
            approvals: +263 77 399 4242
            {/* <Article
          //   imgUrl={blog01}
          date="Sep 26, 2021"
          text="Mineral Market. Buy and Find Minerals on MIAMI."
        /> */}
          </div>
        </div>
        <div classNameName="gpt3__blog-container_groupB">
          <div
            classNameName="ratesClearance"
            style={{ color: "white", marginBottom: "2rem" }}
          >
            <h3 style={{ textAlign: "center" }} classNameName="gradient__text">
              RATES CLEARANCE CERTIFICATE
            </h3>{" "}
            <br />
            <span classNameName="gradient__text" style={{ textAlign: "center" }}>
              {contract.RATESreceiptNo === undefined
                ? "...................................."
                : contract.RATESreceiptNo}
            </span>
            <br /> <br />
            <h3 style={{ textAlign: "center" }} classNameName="gradient__text">
              OWNERS ASSOCIATION
            </h3>{" "}
            <p style={{ textAlign: "center" }}>I</p>
            <span classNameName="gradient__text">
              {contract.CHAIRMAN === undefined
                ? "...................................."
                : contract.CHAIRMAN}
            </span>
            , in my capacity as{" "}
            <span classNameName="gradient__text">
              .............CHAIRMAN..............
            </span>
            <p>
              Have completed and lodged the form of acceptance and have compiled
              with the necessary formalities to entitle them to Membership of
              the Association.
            </p>{" "}
            <br />{" "}
            <p>
              The Property:- <br />
              An undivided <br />
              <span classNameName="gradient__text">
                {contract.PROPERTYdescription === undefined
                  ? "...................................."
                  : contract.PROPERTYdescription}
              </span>
            </p>{" "}
            <br /> <br />
            <span classNameName="gradient__text">...........................</span>
            <p>
              Share being
              <span classNameName="gradient__text">
                {contract.AREAsqm === undefined
                  ? "...................................."
                  : contract.AREAsqm}
              </span>
              piece of land situated in the District of Salisbury called Rumani
              of Borrowdale Estate
            </p>
            <br />
            <br />
            Signed at Harare this{" "}
            <span classNameName="gradient__text">
              {contract.CHAIRMAN === undefined
                ? "...................................."
                : contract.CHAIRMAN}
            </span>
            (Chairman) <br />
            PS: Current Rates Reciept Number:
            <span classNameName="gradient__text">
              {contract.RATESpaymentUPDATE === undefined
                ? "...................................."
                : contract.RATESpaymentUPDATE}
            </span>{" "}
            <br /> Development Receipt Number:{" "}
            <span classNameName="gradient__text">
              {contract.DEVlevyPAYMENTupdate === undefined
                ? "...................................."
                : contract.DEVlevyPAYMENTupdate}
            </span>
          </div>

          <div classNameName="plansApprval" style={{ color: "white" }}>
            <h3
              style={{ textAlign: "center", marginBottom: "2rem" }}
              classNameName="gradient__text"
            >
              APPLICATION FOR APPROVAL OF PLANS OF BUILDING AND/OR SEWERAGE
              WORKS
            </h3>{" "}
            <br />
            STAND /SHARE NO:{" "}
            <span classNameName="gradient__text">
              {contract.STANDno === undefined
                ? "..........................."
                : contract.STANDno}
            </span>
            <br />
            <br />
            PLAN No:{" "}
            <span classNameName="gradient__text">
              {contract.pdfBUILDINGplans === undefined
                ? "..........................."
                : contract.pdfBUILDINGplans}
            </span>{" "}
            <br />
            <br />
            <p style={{ textAlign: "right" }}> For Official Use</p>
            <p>
              2.NAME AND MOBILE NUMBER OF SUBMITTING ARCHITECT:
              <span classNameName="gradient__text">
                .
                {contract.PROPERTYdescription === undefined
                  ? "..........................."
                  : contract.PROPERTYdescription}
              </span>
            </p>{" "}
            <br />
            <br />
            <p>
              3. CHARGES PAYABLE:
              <span classNameName="gradient__text">.......................</span>
            </p>{" "}
            <br />
            <br />
            (a) Building Plan Fees $
            <span classNameName="gradient__text">
              {contract.PLANfees === undefined
                ? "..........................."
                : contract.PLANfees}
            </span>{" "}
            <br />
            <br />
            (b) RATES CLEARANCE AND/OR DEVELOPMENT LEVY DATED
            <span classNameName="gradient__text">
              ...........................
            </span>{" "}
            <br /> <br />
            4. AREAS: Area of Main Building on ground floor (including outside
            walls)*
            <span classNameName="gradient__text">
              {contract.BUILDINGsqmSIZEonGROUND === undefined
                ? "..........................."
                : contract.BUILDINGsqmSIZEonGROUND}
            </span>{" "}
            sq.m Total area of all floors (including outside walls)*
            <span classNameName="gradient__text">
              {contract.BUILDINGtotalAREA === undefined
                ? "..........................."
                : contract.BUILDINGtotalAREA}
            </span>
            sq.m Area of Outbuildings (includingOutside walls)”
            <span classNameName="gradient__text">
              {contract.AREAoutbuilding === undefined
                ? "..........................."
                : contract.AREAoutbuilding}
            </span>{" "}
            sq.m When the building project is an addition or additions, inset
            here only the area of such addition or additions
            <span classNameName="gradient__text">
              {" "}
              <br /> <br />
              5. BUILDING OWNER INFORMATION:
            </span>
            <span classNameName="gradient__text">
              {contract.PURCHASER === undefined
                ? "..........................."
                : contract.PURCHASER}
            </span>
            <br /> <br />
            6.INFORMATION OF REGISTERED OWNER OF THE STAND:{" "}
            <span classNameName="gradient__text">
              {contract.PURCHASER === undefined
                ? "..........................."
                : contract.PURCHASER}
            </span>
            <br /> <br />
            7.SUPERVISION DECLARATION: <br /> <br />
            Architect or Engineer will supervise the work
            <span classNameName="gradient__text">
              {contract.CONSTRCTNsupervisorDATA === undefined
                ? "..........................."
                : contract.CONSTRCTNsupervisorDATA}
            </span>{" "}
            Date
            <span classNameName="gradient__text">
              {contract.regDATE === undefined
                ? "..........................."
                : contract.regDATE}
            </span>{" "}
            <br /> <br />
            <p style={{ textAlign: "right" }}>
              Signature of Owner of Authorised Agent
            </p>{" "}
            <br /> <br />
            <h3>ELECTRONIC DRAWINGS TO ACCOMPANY THIS FORM</h3> <br /> <br />
            <p>a. All plans to be submitted in PDF FORMAT.</p> <br /> <br />
            <p>
              b. Block Plan to a SCALE of 1:500 with metric dimensions, one copy
              to be coloured.
            </p>{" "}
            <br /> <br />
            <p>
              c. Working drawing, plans sections and elevations, to a scale of
              1:100 metric, one copy to be coloured.
            </p>{" "}
            <br /> <br />
            <p>
              d. Where applicable Structural Engineer's drawings and
              calculations and structural certificate NOTE: Refence should be
              made to the City of HARARE Model building By Laws 1977 for full
              details of drawings required to be submitted.
            </p>{" "}
            <br /> <br />
            <p>ENQUIRIES TO:</p>
          </div>

          <div
            classNameName="rumaniEstat"
            style={{ color: "white", marginBottom: "2rem" }}
          >
            <h3 style={{ textAlign: "center" }} classNameName="gradient__text">
              RUMANI ESTATES (PVT) LTD
            </h3>{" "}
            <br />
            22 February 2022
            <br />
            <br /> The Administrator
            <br />
            <br />
            Goromonzi Rural District Council RUWA
            <br />
            <br />
            <p>
              Dear Sir/Madam RE: CONFIRMATION OF STAND NUMBER IN RESPECT OF
              <span classNameName="gradient__text">
                {contract.PROPERTYdescription === undefined
                  ? "..........................."
                  : contract.PROPERTYdescription}
              </span>{" "}
            </p>{" "}
            <br />
            <p>
              The above matter refers. <br />
              Please be advised that
              <span classNameName="gradient__text">
                {contract.STANDno === undefined
                  ? "..........................."
                  : contract.STANDno}
              </span>{" "}
              <br /> <br />
              registered to
              <span classNameName="gradient__text">
                {contract.PURCHASER === undefined
                  ? "..........................."
                  : contract.PURCHASER}
              </span>{" "}
              Under Deed of Transfer Number 9180/2003 is Stand Number 719 at
              Rumani Estate, Charlottes Brooke. <br /> <br />
              Thank you. <br /> <br />
              <span classNameName="gradient__text">
                {contract.CHAIRMAN === undefined
                  ? "..........................."
                  : contract.CHAIRMAN}
              </span>{" "}
              <br /> <br />
              FOR AND ON BEHALF OF RUMANI ESTATES
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificates;
