import React, { useState, useEffect, useContext } from "react";
import {
    Footer,
    Blog,
    Possibility,
    Features,
    WhatGPT3,
    Header,
    Services,
} from "../containers";

import { CTA, Brand, Navbar, Minerals, GridList } from "../components";
import Loader from "../components/Loader";
import Category from "../components/Category";
import { useProducts } from "../context/productContext";
import { Link } from "react-router-dom";
// import CustomResults from "../components/custom-result";
// import { Filter, Input, Pagination } from "@sajari/react-search-ui";
import './css/bazaar.css';
import logo from "../assets/logo1.png";

// Navigation component
const SearchAsset = () => {
    return (
      <div id="search">
        <svg viewBox="0 0 420 60" xmlns="http://www.w3.org/2000/svg">
          <rect className="bar"/>
          
          <g className="magnifier">
            <circle className="glass"/>
            <line className="handle" x1="32" y1="32" x2="44" y2="44"></line>
          </g>
  
          <g className="sparks">
            <circle className="spark"/>
            <circle className="spark"/>
            <circle className="spark"/>
          </g>
  
          <g className="burst pattern-one">
            <circle className="particle circle"/>
            <path className="particle triangle"/>
            <circle className="particle circle"/>
            <path className="particle plus"/>
            <rect className="particle rect"/>
            <path className="particle triangle"/>
          </g>
          <g className="burst pattern-two">
            <path className="particle plus"/>
            <circle className="particle circle"/>
            <path className="particle triangle"/>
            <rect className="particle rect"/>
            <circle className="particle circle"/>
            <path className="particle plus"/>
          </g>
          <g className="burst pattern-three">
            <circle className="particle circle"/>
            <rect className="particle rect"/>
            <path className="particle plus"/>
            <path className="particle triangle"/>
            <rect className="particle rect"/>
            <path className="particle plus"/>
          </g>
        </svg>
        <input type="search" name="q" aria-label="Search for inspiration" style={{width: "319px"}}/>
      </div>
    );
  }
  
// Main content component
const Content = () => {
    return (
        <div>
          <div className="linebar">
            <h1 style={{color: "#FFC94A"}}><img src={logo}  style={{width: "100px", marginLeft: "auto", marginRight: "auto"}}/> MIAMI TRADING HALL</h1>
            <SearchAsset />
          </div>
           
          <div className="linebar2">
            <ul>
              <li><Link to="/">HOME</Link></li>
              <li><Link to="/banking">Housefin</Link></li>
              <li><Link to="/commodity/garlpex">Growthhub</Link></li>
              <li><Link to="/profile">Profile</Link></li>
            </ul>
          </div>
          <div className="bazarheader">
            <div className="content1 headerr">
              <h1 className="catalog">Swap<p>Assets</p><button id="bazzarbutton">EXPLORE</button></h1>
            </div>
            <div className="content2 bazarproduct">
              <div className="circledesign">Coal</div>
              <div className="ImageContent2" style={{backgroundImage:"url(https://cdn.pixabay.com/photo/2016/07/16/13/24/coal-1521718_1280.jpg)", backgroundSize:"100% 100%"}}></div>
            </div>
            <div className="content2 bazarproduct">
              <div className="circledesign">Cluster Housing</div>
              <div className="ImageContent" style={{backgroundImage:"url(https://firebasestorage.googleapis.com/v0/b/miami-auth-58a82.appspot.com/o/MIAMI%20PHOTOSE%2Fhousing.png?alt=media&token=c6a71e84-54ef-4f7e-ae84-727a53e60f7e)", backgroundSize:"100% 100%"}}></div>
            </div>
            <div className="content2 bazarproduct">
              <div className="circledesign">Ferrochrome</div>
              <div className="ImageContent" style={{backgroundImage:"url(https://zxferroalloy.com/wp-content/uploads/2022/06/high-carbon-ferro-chrome.jpg)", backgroundSize:"100% 100%"}}></div>
            </div>
            <div className="content2 bazarproduct">
              <div className="circledesign">Gold</div>
              <div className="ImageContent" style={{backgroundImage:"url(https://live-production.wcms.abc-cdn.net.au/9e90152f4a6a7a7da30db1dd82a43b88?impolicy=wcms_crop_resize&cropH=1152&cropW=2048&xPos=0&yPos=192&width=862&height=485)", backgroundSize:"100% 100%"}}></div>
            </div>
          </div>
        </div>
      );
    
}

const BazaarScreen = () => {
    return (
        <>
            <Content />
        </>
    );
}

export default BazaarScreen