import React, { useRef, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import Loader from "../components/Loader";
import { contractd } from "../actions/contractActions";
import Message from "../components/Message";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReactPhoneInput from "react-phone-input-2";

import {
  Footer,
  Blog,
  Possibility,
  Features,
  WhatGPT3,
  Header,
  Certificates,
} from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import "./css/compliance.css";

const featuresData = [
  {
    title: "Rates Clearance",
    text: "Those who want to do rates clearance, MIAMI is here to assist you.",
  },
  {
    title: "Site Plans",
    text: "For those who aren't aware of their property positions, MIAMI is ready to supply with site plans",
  },
  {
    title: "Agreement of Sale",
    text: "If you want to check on property ownership compliance, and need assistance with building plans approval, MIAMI is your solution",
  },
  {
    title: "Property Purchases",
    text: "Find and Buy Property with MIAMI",
  },
];

const ContractScreen = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const contractD = useSelector((state) => state.contract);
  const { loading, error, contract } = contractD;
  const [mandateOwner, setMandateOwner] = useState("");
  const [agent, setAgent] = useState("");
  const [purchaser, setPurchaser] = useState("");
  const [shareNumber, setShareNumber] = useState("");
  const [area, setArea] = useState("");
  const [township, setTownship] = useState("");
  const [price, setPrice] = useState("");
  const [deposit, setDeposit] = useState("");
  const [installment, setInstallment] = useState("");
  const [period, setPeriod] = useState("");
  const [totalAmountPaid, setTotalAmountPaid] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [titleDNumber, setTitleDNumber] = useState("");
  const [date, setDate] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhone] = useState("");

  const [showResults, setShowResults] = useState(false);
  const [showCompliance, setShowCompliance] = useState(true);

  const onClick = () => setShowResults(true);
  const closeForm = () => setShowResults(false);

  let history = useHistory();
  const routeChange = () => {
    let path = `/login`;
    history.push(path);
  };

  async function submitHandler(e) {
    e.preventDefault();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    if (mandateOwner === "") {
      alert.show("Provide Seller");
      // setMessage("");
    } else if (agent === "") {
      alert.show("Provide Agent");
      return;
    } else if (purchaser === "") {
      alert.show("Provide Purchaser");
      return;
    } else if (totalAmountPaid === "") {
      alert.show("Provide Total Amount Paid");
      return;
    } else if (shareNumber === "") {
      alert.show("Provide ShareNumber");
      return;
    } else if (area === "") {
      alert.show("Provide Area");
      return;
    } else if (township === "") {
      alert.show("Provide Township");
      return;
    } else if (price === "") {
      alert.show("Provide Price");
      return;
    } else if (deposit === "") {
      alert.show("Provide Deposit");
      return;
    } else if (installment === "") {
      alert.show("Provide Installment");
      return;
    } else if (period === "") {
      alert.show("Provide Period Of Payment");
      return;
    } else if (receiptNo === "") {
      alert.show("Provide Receipt Number");
      return;
    } else if (titleDNumber === "") {
      alert.show("Provide Deed Number");
      return;
    } else if (date === "") {
      alert.show("Provide Date");
      return;
    } else if (email === "") {
      alert.show("Provide Email");
      return;
    } else if (phoneNumber === "") {
      alert.show("Provide Phone Number");
      return;
    } else {
      const formData = {
        miamiUserID: userInfo._id,
        mandateOwner,
        agent,
        purchaser,
        totalAmountPaid,
        shareNumber,
        area,
        township,
        price,
        deposit,
        installment,
        period,
        receiptNo,
        titleDNumber,
        date,
        email,
      };

      const { data } = await axios.post(
        `/api/contracts/compliance`,
        formData,
        config
      );
      if (data) {
        console.log(data);
      }
    }
  }

  return (
    <>
      <div classNameName="gradieant__bg">
        <Navbar />
        <Header
          heading="Cleverhills Property Registration"
          content="To get our services and Documents, Cleverhills requires that you should be registered in the database. Fill In the form below to be Registered. You will get feed back within 48 hours, after filling in all the fields"
          image="approval"
        />
      </div>


      <div
        classNameName="gpt3__whatgpt3 section__margin detail-card bounce-in-top"
        id="wgpt3">
          <div className="contact-us">
            <form className="complianceForm">
              <input
                placeholder="SELLER FULL NAME"
                name="mandateOwner"
                required="true"
                value={mandateOwner}
                onChange={(e) => setMandateOwner(e.target.value)}
                style={{ color: "black" }}
                type="text"
              />

              <input
                placeholder="AGENT FULL NAME"
                required="true"
                name="agent"
                value={agent}
                onChange={(e) => setAgent(e.target.value)}
                type="text"
                style={{ color: "black" }}
              />

              <input
                placeholder="PURCHASER FULL NAMES"
                required="true"
                type="text"
                value={purchaser}
                onChange={(e) => setPurchaser(e.target.value)}
                style={{ color: "black" }}
              />

              <input
                placeholder="SHARE NUMBER"
                required="true"
                type="text"
                value={shareNumber}
                onChange={(e) => setShareNumber(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="AREA IN SQUARE METERS"
                required="true"
                type="text"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="TOWNSHIP"
                required="true"
                type="text"
                value={township}
                onChange={(e) => setTownship(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="PURCHASE PRICE (eg: USD 00000)"
                required="true"
                type="text"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="DEPOSIT (eg: USD 00000)"
                required="true"
                type="text"
                value={deposit}
                onChange={(e) => setDeposit(e.target.value)}
                style={{ color: "black" }}
              />

              <input
                placeholder="INSTALMENT AMOUNT (eg: USD 00000)"
                required="true"
                type="text"
                value={installment}
                onChange={(e) => setInstallment(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="PERIOD OF PAYMENT IN MONTHS (eg: 04)"
                required="true"
                type="text"
                value={period}
                onChange={(e) => setPeriod(e.target.value)}
                style={{ color: "black" }}
              />

              <input
                placeholder="TOTAL AMOUNT PAID TO DATE (e.g USD 1999 or Paid In full)"
                required="true"
                type="text"
                value={totalAmountPaid}
                onChange={(e) => setTotalAmountPaid(e.target.value)}
                style={{ color: "black" }}
              />

              <input
                placeholder="PURCHASED DEED No(share that one has purchased)"
                required="true"
                type="text"
                value={receiptNo}
                onChange={(e) => setReceiptNo(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="TRANSFERED DEED No(been transfered to the purchaser)*Optional"
                type="text"
                value={titleDNumber}
                onChange={(e) => setTitleDNumber(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                placeholder="DATE OF AGREEMENT"
                required="true"
                type="text"
                value={date}
                onChange={(e) => setDate(e.target.value)}
                style={{ color: "black" }}
              />
              <input
                name="customerEmail"
                placeholder="EMAIL"
                type="email"
                required="true"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                style={{ color: "black" }}
              />
              <label>PHONE</label>
              <ReactPhoneInput
                preferredCountries={["zw", "us"]}
                id="phone"
                disableAreaCodes
                value={phoneNumber}
                onChange={(phoneNumber) => setPhone(phoneNumber)}
                style={{ marginBottom: "20px" }}
              />
              {userInfo ? (
                <button type="button" onClick={submitHandler}>
                  Get Approved
                </button>
              ) : (
                <button type="button" onClick={routeChange}>
                  Sign In
                </button>
              )}
            </form>
          </div>
        

        {/* Form Begin */}
        {showResults ? (
          <>
            <div>
              <div classNameName="flex-align-center-justify-center-direction-column view-height p-all">
                <img
                  classNameName="foresight-logo m-bottom"
                  src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/312465/Foresight%20Logo-01.svg"
                  alt=""
                />
                <div classNameName="foresight-search-bar fill-space m-bottom">
                  <input
                    type="text"
                    placeholder="Enter Search code"
                    id="accountNumber"
                    name="searchQueryInput"
                  />
                  <i classNameName="material-icons">search</i>
                </div>
                <div classNameName="m-bottom">
                  <button
                    classNameName="btn primary m-right"
                    id="searchQuerySubmit"
                    type="submit"
                    name="searchQuerySubmit"
                  >
                    Search
                  </button>
                  <Link to="/housing" styl={{ padding: "0" }}>
                    <button
                      classNameName="btn primary m-right"
                      id="searchQuerySubmit"
                    >
                      Back
                    </button>
                  </Link>
                  <button classNameName="btn dark-grey" onClick={onClick}>
                    Properties
                  </button>
                </div>
                <div classNameName="text-center text-grey font-lighter">
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
            <Certificates contract={contract} />
          </>
        ) : null}

        {/* End Form */}
      </div>

      <Possibility
        subheading="Request Early Access to MIAMI Property Management System"
        heading="The possibilities are beyond your imagination with MIAMI Property Management System"
        content="From buying Properties to sorting out contracts. All is done on MIAMI"
      />
      <Features
        featuresD={featuresData}
        heading=" The Future is Now and You Just Need to Realize It. Step into Future Today. & Make it Happen with MIAMI"
        contentP="Browse through our services"
      />
      <Footer />
    </>
    // <div>
    //   <div
    //     classNameName="jumbotron"
    //     style={{
    //       backgroundColor: "#F0A500",
    //       padding: "4rem",
    //       width: "100%",
    //       marginBottom: "2rem",
    //       backgroundImage: "url(/images/properties1.png)",
    //       backgroundSize: "cover",
    //       backgroundRepeat: "none",
    //     }}
    //   >
    //     {/* <h1 style={{ fontSize: "3rem", color: "#BB371A" }}>
    //         World className trading platform
    //       </h1> */}
    //     <Row>
    //       <Col md={6}>
    //         <h1
    //           classNameName="display-1 fs-md-5 fs-lg-6 fs-xl-8"
    //           style={{
    //             fontSize: "4em",
    //             color: "grey",
    //             backgroundColor: "rgba(255,255,255,0.5)",
    //             padding: "0.5rem",
    //           }}
    //         >
    //           Confirm Status of Property
    //         </h1>
    //         <form onSubmit={submitHandler} style={{ width: "100%" }}>
    //           <div classNameName="wrapper" style={{ zIndex: "2" }}>
    //             <div
    //               classNameName="label"
    //               style={{
    //                 color: "grey",
    //                 fontWeight: "bold",
    //                 backgroundColor: "rgba(255,255,255,0.5)",
    //                 padding: "0.5rem",
    //               }}
    //             >
    //               Submit your search
    //             </div>
    //             <div classNameName="searchBar">
    //               <input
    //                 id="accountNumber"
    //                 type="text"
    //                 name="searchQueryInput"
    //                 placeholder="Search"
    //               />
    //               <button
    //                 id="searchQuerySubmit"
    //                 type="submit"
    //                 name="searchQuerySubmit"
    //               >
    //                 <svg
    //                   style={{ width: "24px", height: "24px" }}
    //                   viewBox="0 0 24 24"
    //                 >
    //                   <path
    //                     fill="#666666"
    //                     d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
    //                   />
    //                 </svg>
    //               </button>
    //             </div>
    //           </div>
    //         </form>
    //         {/* <h2
    //             classNameName="text-800 mb-5 fs-4"
    //             style={{ fontSize: "2.0736rem", color: "#FFFDE3" }}
    //           >
    //             {" "}
    //             MJ(Mega Joules) offer top tier liquidity, allowing users to
    //             <br classNameName="d-none d-xxl-block" />
    //             easily invest, exchange goods and services or store value in
    //             form of mineral.
    //           </h2> */}
    //         <p>
    //           <Link
    //             classNameName="btn btn-lg"
    //             role="button"
    //             style={{ backgroundColor: "#F4DFBA" }}
    //             to="/"
    //           >
    //             Learn more
    //           </Link>
    //         </p>
    //       </Col>
    //       <Col md={4}>
    //         <div classNameName="flex-wrapper">
    //           <div classNameName="single-chart">
    //             <svg viewBox="0 0 36 36" classNameName="circular-chart orange">
    //               <path
    //                 classNameName="circle-bg"
    //                 d="M18 2.0845
    //       a 15.9155 15.9155 0 0 1 0 31.831
    //       a 15.9155 15.9155 0 0 1 0 -31.831"
    //               />
    //               <path
    //                 classNameName="circle"
    //                 stroke-dasharray="30, 100"
    //                 d="M18 2.0845
    //       a 15.9155 15.9155 0 0 1 0 31.831
    //       a 15.9155 15.9155 0 0 1 0 -31.831"
    //               />
    //               <text x="18" y="20.35" classNameName="percentage">
    //                 30%
    //               </text>
    //             </svg>
    //           </div>

    //           <div classNameName="single-chart">
    //             <svg viewBox="0 0 36 36" classNameName="circular-chart green">
    //               <path
    //                 classNameName="circle-bg"
    //                 d="M18 2.0845
    //       a 15.9155 15.9155 0 0 1 0 31.831
    //       a 15.9155 15.9155 0 0 1 0 -31.831"
    //               />
    //               <path
    //                 className="circle"
    //                 stroke-dasharray="0, 100"
    //                 d="M18 2.0845
    //       a 15.9155 15.9155 0 0 1 0 31.831
    //       a 15.9155 15.9155 0 0 1 0 -31.831"
    //               />
    //               <text x="18" y="20.35" classNameName="percentage">
    //                 0%
    //               </text>
    //             </svg>
    //           </div>
    //         </div>
    //       </Col>
    //     </Row>
    //   </div>
    //   {loading ? (
    //     <>
    //       <Loader />
    //       <div classNameName="container-xxl py-5 category">
    //         <div classNameName="container">
    //           {/* <!-- PARTICLE SYSTEM --> */}
    //           <div classNameName="page-bg"></div>
    //           <div classNameName="animation-wrapper" style={{ zIndex: "-1" }}>
    //             <div classNameName="particle particle-1"></div>
    //             <div classNameName="particle particle-2"></div>
    //             <div classNameName="particle particle-3"></div>
    //             <div classNameName="particle particle-4"></div>
    //           </div>
    //           {/* <div>{contract}</div> */}
    //           <div classNameName="text-center wow fadeInUp" data-wow-delay="0.1s">
    //             {/* <div classNameName="input-group mb-3">
    //             <form onSubmit={submitHandler} style={{ width: "100%" }}>
    //               <input
    //                 type="text"
    //                 classNameName="form-control"
    //                 placeholder="Registration Number"
    //                 aria-label="Recipient's username"
    //                 aria-describedby="button-addon2"
    //                 style={{
    //                   marginRight: "1rem",
    //                   display: "inline",
    //                   width: "80%",
    //                 }}
    //                 id="accountNumber"
    //               />

    //               <input
    //                 type="submit"
    //                 value="Search"
    //                 classNameName="btn btn-info"
    //                 style={{ display: "inline" }}
    //               />
    //             </form>
    //           </div> */}
    //           </div>
    //           <div classNameName="row g-3">
    //             <div classNameName="col-lg-5 col-md-6">
    //               <div classNameName="row g-3">
    //                 {/* <!-- SECTION 1 --> */}

    //                 <div
    //                   classNameName="col-lg-12 col-md-12 wow zoomIn"
    //                   data-wow-delay="0.1s"
    //                   style={{
    //                     borderTop: "1px solid lightgrey",
    //                     boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
    //                     padding: " 1rem",
    //                   }}
    //                 >
    //                   <h3 style={{ textAlign: "center" }}>
    //                     APPLICATION FOR APPROVAL OF PLANS OF BUILDING AND/OR
    //                     SEWERAGE WORKS
    //                   </h3>
    //                   STAND /SHARE NO:{" "}
    //                   <span classNameName="propertyDesc" style={{ color: "green" }}>
    //                     .............
    //                     {contract.STANDno === undefined
    //                       ? "........"
    //                       : contract.STANDno}
    //                     ...............
    //                   </span>{" "}
    //                   <br />
    //                   <br />
    //                   PLAN No:{" "}
    //                   <span classNameName="live planNo" style={{ color: "green" }}>
    //                     .............
    //                     {contract.pdfBUILDINGplans === undefined
    //                       ? "........"
    //                       : contract.pdfBUILDINGplans}
    //                     ...............
    //                   </span>{" "}
    //                   <br /> <br />
    //                   <p style={{ textAlign: "right" }}>For Official Use</p>
    //                   <h4 style={{ textAlign: "left" }}>
    //                     2.NAME AND MOBILE NUMBER OF SUBMITTING ARCHITECT:{" "}
    //                   </h4>{" "}
    //                   <br />
    //                   <br />
    //                   <span
    //                     classNameName="live propertyDesc"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.PROPERTYdescription === undefined
    //                       ? "........"
    //                       : contract.PROPERTYdescription}
    //                     ...............
    //                   </span>{" "}
    //                   <br /> <br />
    //                   <h4 style={{ textAlign: "left" }}>
    //                     3. CHARGES PAYABLE:{" "}
    //                   </h4>
    //                   (a) Building Plan Fees $
    //                   <span classNameName="live ag" style={{ color: "green" }}>
    //                     .............
    //                     {contract.PLANfees === undefined
    //                       ? "........"
    //                       : contract.PLANfees}
    //                     ...............
    //                   </span>
    //                   <br /> <br />
    //                   (b) RATES CLEARANCE AND/OR DEVELOPMENT LEVY DATED
    //                   <span classNameName="live dated">
    //                     .............................
    //                   </span>
    //                   <br /> <br />
    //                   <h4 style={{ textAlign: "left" }}> 4. AREAS: </h4>
    //                   Area of Main Building on ground floor (including outside
    //                   walls)*
    //                   <span classNameName="live ah" style={{ color: "green" }}>
    //                     .............
    //                     {contract.BUILDINGsqmSIZEonGROUND === undefined
    //                       ? "........"
    //                       : contract.BUILDINGsqmSIZEonGROUND}
    //                     ...............
    //                   </span>{" "}
    //                   sq.m <br /> <br />
    //                   <p>
    //                     Total area of all floors (including outside walls)*{" "}
    //                     <span classNameName="live ai" style={{ color: "green" }}>
    //                       .............
    //                       {contract.BUILDINGtotalAREA === undefined
    //                         ? "........"
    //                         : contract.BUILDINGtotalAREA}
    //                       ...............
    //                     </span>
    //                     sq.m Area of Outbuildings (includingOutside walls)”
    //                     <span classNameName="live aj" style={{ color: "green" }}>
    //                       .............
    //                       {contract.AREAoutbuilding === undefined
    //                         ? "........"
    //                         : contract.AREAoutbuilding}
    //                       ...............
    //                     </span>{" "}
    //                     sq.m
    //                   </p>
    //                   <p>
    //                     When the building project is an addition or additions,
    //                     inset here only the area of such addition or additions
    //                   </p>
    //                   <h4 style={{ textAlign: "left" }}>
    //                     {" "}
    //                     5. BUILDING OWNER INFORMATION:{" "}
    //                     <span
    //                       classNameName="live mandateOwner"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.PURCHASER === undefined
    //                         ? "........"
    //                         : contract.PURCHASER}
    //                       ...............
    //                     </span>{" "}
    //                   </h4>{" "}
    //                   <br />
    //                   <br />
    //                   <h4 style={{ textAlign: "left" }}>
    //                     {" "}
    //                     6. INFORMATION OF REGISTERED OWNER OF THE STAND:{" "}
    //                   </h4>
    //                   <span
    //                     classNameName="live mandateOwner"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.PURCHASER === undefined
    //                       ? "........"
    //                       : contract.PURCHASER}
    //                     ...............
    //                   </span>{" "}
    //                   <br /> <br />
    //                   <h4 style={{ textAlign: "left" }}>
    //                     {" "}
    //                     7. SUPERVISION DECLARATION:{" "}
    //                   </h4>
    //                   Architect or Engineer will supervise the work
    //                   <span classNameName="live af" style={{ color: "green" }}>
    //                     .............
    //                     {contract.CONSTRCTNsupervisorDATA === undefined
    //                       ? "........"
    //                       : contract.CONSTRCTNsupervisorDATA}
    //                     ...............
    //                   </span>{" "}
    //                   <br /> <br />
    //                   <span> Date </span>
    //                   <span
    //                     classNameName="live agreement"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.regDATE === undefined
    //                       ? "........"
    //                       : contract.regDATE}
    //                     ...............
    //                   </span>
    //                   <p style={{ textAlign: "right" }}>
    //                     <span classNameName="live al">
    //                       .............................
    //                     </span>
    //                   </p>
    //                   <p style={{ textAlign: "right" }}>
    //                     Signature of Owner of Authorised Agent
    //                   </p>{" "}
    //                   <br /> <br />
    //                   <h3 style={{ textAlign: "center" }}>
    //                     ELECTRONIC DRAWINGS TO ACCOMPANY THIS FORM
    //                   </h3>{" "}
    //                   <br /> <br />
    //                   <p style={{ textAlign: "left" }}>
    //                     a. All plans to be submitted in PDF FORMAT.
    //                   </p>
    //                   <p style={{ textAlign: "left" }}>
    //                     b. Block Plan to a SCALE of 1:500 with metric
    //                     dimensions, one copy to be coloured.
    //                   </p>
    //                   <p style={{ textAlign: "left" }}>
    //                     c. Working drawing, plans sections and elevations, to a
    //                     scale of 1:100 metric, one copy to be coloured.
    //                   </p>
    //                   <p style={{ textAlign: "left" }}>
    //                     d. Where applicable Structural Engineer's drawings and
    //                     calculations and structural certificate
    //                   </p>
    //                   <p>
    //                     NOTE: Refence should be made to the City of HARARE Model
    //                     building By Laws 1977 for full details of drawings
    //                     required to be submitted.
    //                   </p>
    //                   <br /> <br />
    //                   <h3 style={{ textAlign: "left" }}>ENQUIRIES TO:</h3>
    //                 </div>

    //                 {/* <!-- SECTION 2 --> */}
    //                 <div
    //                   classNameName="col-lg-6 col-md-12 wow zoomIn"
    //                   data-wow-delay="0.3s"
    //                   style={{
    //                     borderTop: "1px solid lightgrey",
    //                     boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //                     padding: "1rem",
    //                   }}
    //                 >
    //                   <h4
    //                     classNameName="section-heading"
    //                     style={{ textAlign: "center" }}
    //                   >
    //                     RATES CLEARANCE CERTIFICATE
    //                   </h4>
    //                   <br /> <br />
    //                   <h4
    //                     classNameName="section-heading"
    //                     style={{ textAlign: "center" }}
    //                   >
    //                     <span
    //                       classNameName="live address2Pur"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.RATESreceiptNo === undefined
    //                         ? "........"
    //                         : contract.RATESreceiptNo}
    //                       ...............
    //                     </span>{" "}
    //                     OWNERS ASSOCIATION
    //                   </h4>
    //                   <p style={{ textAlign: "center" }}>
    //                     I{" "}
    //                     <span
    //                       classNameName="live chairman"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.CHAIRMAN === undefined
    //                         ? "........"
    //                         : contract.CHAIRMAN}
    //                       ...............
    //                     </span>
    //                     , in my capacity as{" "}
    //                     <span
    //                       classNameName="live address2Pur"
    //                       style={{ color: "green" }}
    //                     >
    //                       ...........CHAIRMAN.............
    //                     </span>
    //                   </p>
    //                   <br /> <br />
    //                   <p style={{ textAlign: "center" }}>
    //                     <span classNameName="live mandateOwner">
    //                       .............................
    //                     </span>
    //                   </p>{" "}
    //                   <br /> <br />
    //                   <p>
    //                     Have completed and lodged the form of acceptance and
    //                     have compiled with the necessary formalities to entitle
    //                     them to Membership of the Association.
    //                   </p>{" "}
    //                   <br /> <br />
    //                   <h4
    //                     classNameName="section-heading"
    //                     style={{ textAlign: "center" }}
    //                   >
    //                     The Property:-
    //                   </h4>
    //                   <p>
    //                     An undivided{" "}
    //                     <span classNameName="live share" style={{ color: "green" }}>
    //                       .............
    //                       {contract.PROPERTYdescription === undefined
    //                         ? "........"
    //                         : contract.PROPERTYdescription}
    //                       ...............
    //                     </span>
    //                     Share being
    //                     <span
    //                       classNameName="live propertyDesc"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.AREAsqm === undefined
    //                         ? "........"
    //                         : contract.AREAsqm}
    //                       ...............
    //                     </span>
    //                     piece of land situated in the District of Salisbury
    //                     called Rumani of Borrowdale Estate
    //                   </p>
    //                   <p>
    //                     Signed at Harare this
    //                     <span classNameName="live dated">
    //                       .............................
    //                     </span>
    //                   </p>
    //                   <p>
    //                     {" "}
    //                     <span
    //                       classNameName="live chairman"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.CHAIRMAN === undefined
    //                         ? "........"
    //                         : contract.CHAIRMAN}
    //                       ...............
    //                     </span>{" "}
    //                     (Chairman)
    //                   </p>
    //                   <p>
    //                     PS: Current Rates Reciept Number:{" "}
    //                     <span
    //                       classNameName="live propertyDesc"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.RATESpaymentUPDATE === undefined
    //                         ? "........"
    //                         : contract.RATESpaymentUPDATE}
    //                       ...............
    //                     </span>
    //                   </p>
    //                   <p>
    //                     Development Receipt Number:
    //                     <span
    //                       classNameName="live propertyDesc"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.DEVlevyPAYMENTupdate === undefined
    //                         ? "........"
    //                         : contract.DEVlevyPAYMENTupdate}
    //                       ...............
    //                     </span>
    //                   </p>
    //                 </div>
    //                 {/* <!-- SECTION 2 End --> */}

    //                 {/* <!-- SECTION 3 --> */}
    //                 <div
    //                   classNameName="col-lg-6 col-md-12 wow zoomIn"
    //                   data-wow-delay="0.5s"
    //                   style={{
    //                     borderTop: "1px solid lightgrey",
    //                     boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //                     padding: "1rem",
    //                   }}
    //                 >
    //                   <h4
    //                     classNameName="section-heading"
    //                     style={{ textAlign: "center" }}
    //                   >
    //                     RUMANI ESTATES (PVT) LTD
    //                   </h4>

    //                   <p>22 February 2022</p>

    //                   <p>The Administrator</p>

    //                   <p>Goromonzi Rural District Council</p>
    //                   <h4
    //                     classNameName="section-heading"
    //                     style={{
    //                       textAlign: "left",
    //                       textDecorationColor: "underline",
    //                     }}
    //                   >
    //                     RUWA
    //                   </h4>

    //                   <p>Dear Sir/Madam</p>

    //                   <h4
    //                     classNamename="section-heading"
    //                     style={{
    //                       textAlign: "left",
    //                       textDecorationColor: "underline",
    //                     }}
    //                   >
    //                     RE: CONFIRMATION OF STAND NUMBER IN RESPECT OF{" "}
    //                     <span
    //                       classNameName="live deposit"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.PROPERTYdescription === undefined
    //                         ? "........"
    //                         : contract.PROPERTYdescription}
    //                       ...............
    //                     </span>
    //                   </h4>

    //                   <p>The above matter refers.</p>

    //                   <p>
    //                     Please be advised that{" "}
    //                     <span
    //                       classNameName="live deposit"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.STANDno === undefined
    //                         ? "........"
    //                         : contract.STANDno}
    //                       ...............
    //                     </span>{" "}
    //                     registered to{" "}
    //                     <span
    //                       classNameName="live deposit"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.PURCHASER === undefined
    //                         ? "........"
    //                         : contract.PURCHASER}
    //                       ...............
    //                     </span>
    //                     Under Deed of Transfer Number 9180/2003 is Stand Number
    //                     719 at Rumani Estate, Charlottes Brooke.
    //                   </p>

    //                   <p>
    //                     Thank you. <br />
    //                     <span
    //                       classNameName="live deposit"
    //                       style={{ color: "green" }}
    //                     >
    //                       .............
    //                       {contract.CHAIRMAN === undefined
    //                         ? "........"
    //                         : contract.CHAIRMAN}
    //                       ...............
    //                     </span>
    //                   </p>

    //                   <h4
    //                     classNamename="section-heading"
    //                     style={{
    //                       textAlign: "center",
    //                       textDecorationColor: "underline",
    //                     }}
    //                   >
    //                     FOR AND ON BEHALF OF RUMANI ESTATES
    //                   </h4>
    //                 </div>
    //                 {/* <!-- SECTION 3 END --> */}
    //               </div>
    //             </div>

    //             {/* <!-- END SECTION 4 --> */}

    //             <div
    //               classNameName="col-lg-7 col-md-6 wow zoomIn"
    //               data-wow-delay="0.7s"
    //               style={{
    //                 minHeight: "200px",
    //                 borderTop: "1px solid lightgrey",
    //                 boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //                 padding: "1rem",
    //               }}
    //             >
    //               <h4 classNameName="mb-3" style={{ textAlign: "center" }}>
    //                 MEMORANDUM OF AGREEMENT OF SALE
    //               </h4>
    //               <h6
    //                 classNameName="bg-white text-center px-3"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 MADE AND ENTERED INTO BY AND BETWEEN{" "}
    //               </h6>
    //               <h3
    //                 classNameName="section-heading"
    //                 style={{ color: "grey", textAlign: "center" }}
    //               >
    //                 HOUSEFIN (PVT) LTD (HEREAFTER{" "}
    //                 <span
    //                   classNameName="live mandateOwner"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.ESTATEagent === undefined
    //                     ? "........"
    //                     : contract.ESTATEagent}
    //                   ...............
    //                 </span>{" "}
    //                 ) REPRESENTED BY
    //               </h3>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 I{" "}
    //                 <span
    //                   classNameName="live estateAgent"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.ESTATEagent === undefined
    //                     ? "........"
    //                     : contract.ESTATEagent}
    //                   ...............
    //                 </span>{" "}
    //                 registered with limited liability in accordance with the
    //                 laws of Zimbabwe of c/o 1st Floor, Fidelity Life Tower,
    //                 Harare herein representing
    //                 <span
    //                   classNameName="live mandateOwner"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PURCHASER === undefined
    //                     ? "........"
    //                     : contract.PURCHASER}
    //                   ...............
    //                 </span>
    //                 .
    //               </p>
    //               <br />
    //               <h4
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 And
    //               </h4>
    //               <p style={{ textAlign: "center" }}>
    //                 <span classNameName="live purchaser">
    //                   .............................
    //                 </span>
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 (hereinafter referred to as “the Purchasers”), of the other
    //                 part, WHEREAS the seller is the registered owner of certain
    //                 piece of land situate in the district of Salisbury measuring{" "}
    //                 <span classNameName="live area" style={{ color: "green" }}>
    //                   .............
    //                   {contract.AREAsqm === undefined
    //                     ? "........"
    //                     : contract.AREAsqm}
    //                   ...............
    //                 </span>
    //                 square meters called{" "}
    //                 <span
    //                   classNameName="live propertyDesc"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PROPERTYdescription === undefined
    //                     ? "........"
    //                     : contract.PROPERTYdescription}
    //                   ...............
    //                 </span>{" "}
    //                 (hereinafter referred to as “the property”);
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 AND WHEREAS the seller is desirous of selling the property
    //                 to the Purchaser who has agreed to purchase the same; AND
    //                 WHEREAS the parties hereto have agreed to enter into this
    //                 agreement for the purpose of recording their business
    //                 transaction;
    //               </p>
    //               <h4
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 NOW, THEREFORE, IT IS HEREBY RECORDED THAT THE PARTIES
    //                 HERETO HAVE AGREED AS FOLLOWS
    //               </h4>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 1. <br />
    //                 The effective date of this agreement shall be date of its
    //                 final signature by the parties.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 2. <br />
    //                 The seller hereby sells and the Purchasers hereby purchase
    //                 the property
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 3. <br />
    //                 The purchase price for the property with all amounts
    //                 denominated and payable in currency notes of
    //                 <span
    //                   classNameName="live sellingCurrency"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PURCHASEcurrency === undefined
    //                     ? "........"
    //                     : contract.PURCHASEcurrency}
    //                   ...............
    //                 </span>{" "}
    //                 is the sum of
    //                 <span
    //                   classNameName="live sellingPrice"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PURCHASEprice === undefined
    //                     ? "........"
    //                     : contract.PURCHASEprice}
    //                   ...............
    //                 </span>{" "}
    //                 and the same shall be payable as follows:
    //                 <br />
    //               </p>
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 3.1.{" "}
    //                 <span classNameName="live sellingCurrency">
    //                   .............................
    //                 </span>
    //                 <span classNameName="live initialDep">
    //                   .............................
    //                 </span>{" "}
    //                 Initial deposit <br />
    //                 3.2.{" "}
    //                 <span
    //                   classNameName="live sellingCurrency"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PURCHASEcurrency === undefined
    //                     ? "........"
    //                     : contract.PURCHASEcurrency}
    //                   ...............
    //                 </span>
    //                 <span classNameName="live deposit" style={{ color: "green" }}>
    //                   .............
    //                   {contract.DEPOSIT === undefined
    //                     ? "........"
    //                     : contract.DEPOSIT}
    //                   ...............
    //                 </span>{" "}
    //                 Deposit <br />
    //                 3.3.{" "}
    //                 <span classNameName="live sellingCurrency">
    //                   .............................
    //                 </span>
    //                 <span classNameName="live installments">
    //                   .............................
    //                 </span>{" "}
    //                 Monthly Installments for
    //                 <span
    //                   classNameName="live installmentsMonths"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.INSTALMENTS === undefined
    //                     ? "........"
    //                     : contract.INSTALMENTS}
    //                   ...............
    //                 </span>
    //                 months.
    //               </p>
    //               <br /> <br />
    //               <h4 classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 Amounts to be paid to:
    //               </h4>
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 <h5
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "left" }}
    //                 >
    //                   Local Clients:
    //                 </h5>
    //                 &nbsp;&nbsp; One Money:{" "}
    //                 <span classNameName="live">0714928544</span> <br />
    //                 <br />
    //                 <h5
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "left" }}
    //                 >
    //                   Diaspora Clients:
    //                 </h5>
    //                 &nbsp;&nbsp; A/C Name:{" "}
    //                 <span classNameName="live">
    //                   ...........
    //                   {contract.ACname === undefined
    //                     ? "........"
    //                     : contract.ACname}
    //                   ..................
    //                 </span>{" "}
    //                 <br />
    //                 &nbsp;&nbsp; Bank :{" "}
    //                 <span classNameName="live">
    //                   ...........
    //                   {contract.BANK === undefined ? "........" : contract.BANK}
    //                   ..................
    //                 </span>{" "}
    //                 <br />
    //                 &nbsp;&nbsp; Sort Code :{" "}
    //                 <span classNameName="live">
    //                   .............
    //                   {contract.SORTcode === undefined
    //                     ? "........"
    //                     : contract.SORTcode}
    //                   ................
    //                 </span>{" "}
    //                 <br />
    //                 &nbsp;&nbsp; Acc No.:{" "}
    //                 <span classNameName="live">
    //                   ............
    //                   {contract.ACnum === undefined
    //                     ? "........"
    //                     : contract.ACnum}
    //                   .................
    //                 </span>{" "}
    //                 <br />
    //                 &nbsp;&nbsp; IBAN:{" "}
    //                 <span classNameName="live">
    //                   .............
    //                   {contract.IBAN === undefined ? "........" : contract.IBAN}
    //                   ................
    //                 </span>{" "}
    //                 <br />
    //                 &nbsp;&nbsp; Swift code:{" "}
    //                 <span classNameName="live">
    //                   .............
    //                   {contract.SWIFTcode === undefined
    //                     ? "........"
    //                     : contract.SWIFTcode}
    //                   ................
    //                 </span>{" "}
    //                 <br />
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 4. <br />
    //                 Transfer of the property from the seller to the purchasers
    //                 shall be effected by Messers
    //                 <span
    //                   classNameName="live conveyancer"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.CONVEYANCER === undefined
    //                     ? "........"
    //                     : contract.CONVEYANCER}
    //                   ...............
    //                 </span>
    //                 In this respect the purchasers shall pay all transfer
    //                 charges levied by the conveyancers.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 5. <br />
    //                 The Purchaser shall be responsible for the payment of Value
    //                 Added Tax being{" "}
    //                 <span classNameName="live tax" style={{ color: "green" }}>
    //                   ...........(15%)..................
    //                 </span>{" "}
    //                 of the purchase price.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 6. <br />
    //                 The Purchaser hereby authorizes the release of the purchase
    //                 price to the seller before transfer of the property to the
    //                 Purchasers.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 7. <br />
    //                 The Agent`s commission and in respect of this sale shall be{" "}
    //                 <span
    //                   classNameName="live  buildingARCHT"
    //                   style={{ color: "green" }}
    //                 >
    //                   ...........(7.5%).................
    //                 </span>{" "}
    //                 of the gross amount paid by the Purchasers including
    //                 interest and shall be payable by the seller. The transfer of
    //                 the property shall not be registered unless and until the
    //                 Agent`s commission on this sale shall have been paid or
    //                 secured to the satisfaction of Crowhill Estate Township and
    //                 the seller hereby authorizes the said Agent to deduct the
    //                 commission from the purchase money when the same becomes
    //                 payable to him. Commission shall be deemed to be earned on
    //                 the date of the last signature to the agreement. The parties
    //                 to this agreement acknowledge that the commission shall be
    //                 deducted from the purchasers’ deposit. Should any party to
    //                 this Agreement breach the terms of such agreement causing
    //                 the agreement to be cancelled, the defaulting party shall be
    //                 liable for the agent`s commission.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 8. <br />
    //                 The Purchasers shall take vacant possession and occupation
    //                 of the property upon payment of the full purchase price.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 9. <br />
    //                 The risk and profit in the property hereby sold shall pass
    //                 from the Seller to the Purchasers on the date on which
    //                 vacant possession is given to the Purchasers or the date on
    //                 which transfer is passed to the Purchasers, whichever shall
    //                 be the sooner, and from that date the Purchasers shall be
    //                 entitled to all rents and other profits (if any) accruing
    //                 from the said property and shall be liable for all rates and
    //                 taxes and other imposts levied thereon and all other
    //                 expenses of whatsoever nature in respect of the said
    //                 property.
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 10. <br />
    //                 The Purchasers acknowledge that they have made themselves
    //                 fully acquainted with the property, with any servitude or
    //                 servitudes, lease or leases to which it is subject, with its
    //                 nature, extent, boundaries, beacons and locality and with
    //                 all the terms and conditions imposed by the Town Planning
    //                 Authority or any other Board, body, authority or person
    //               </p>
    //               <p
    //                 classNameName="section-heading"
    //                 style={{ textAlign: "center" }}
    //               >
    //                 11. <br />
    //                 In the event that either party (hereinafter referred to as
    //                 “the breaching party”) commits a material breach of any
    //                 obligation or warranty owed or given in terms of this
    //                 agreement to the other party (hereinafter referred to as
    //                 “the aggrieved party”) and remains in default for either 7
    //                 (seven) days following despatch by the aggrieved party to
    //                 the breaching party of a registered letter requiring remedy
    //                 of such default or for 3 (three) days following delivery to
    //                 the breaching party of a letter from the aggrieved party
    //                 requiring remedy of such default, the aggrieved party shall
    //                 have the immediate right:-
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 (a) either to enforce the aggrieved party’s rights in terms
    //                 of this agreement and to claim payment or performance of
    //                 whatever may be overdue or requisite, together with interest
    //                 on any amount of money owed at the commercial bank prime
    //                 lending rate from the date of default until the date of
    //                 payment, with all interest accruals being capitalised
    //                 monthly on the first day of each month
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 (b) or to cancel this agreement and claim such damages as
    //                 have been caused by the breach and/or cancellation (together
    //                 with interest on such damages at the aforesaid rate,
    //                 capitalised as aforesaid);
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 (c) or, if it is the Purchaser in breach, the seller shall
    //                 demand forthwith his exclusive right of occupation of the
    //                 property;
    //                 <br />
    //                 and, in addition, the aggrieved party shall be entitled to
    //                 institute legal proceedings in the High Court of Zimbabwe
    //                 and to obtain summary judgement and recover legal costs on
    //                 the Attorney-Client scale and any collection commission
    //                 properly charged by the aggrieved party`s legal
    //                 practitioners(s).
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 12. <br />
    //                 This agreement represents the entire contract between the
    //                 parties. No purported amendment or waiver of or addition to
    //                 any provision hereof or amendment or waiver of any notice
    //                 given in terms hereof or collateral or replacement agreement
    //                 in relation to the subject matter hereof shall be of any
    //                 force or effect unless and until reduced to writing in a
    //                 document or series of documents, duly signed by the parties,
    //                 each before 2(two) witnesses.
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 13. The seller and the Purchasers each choose domicilium
    //                 citandi et executandi at the address given after their
    //                 respective names in the preamble to this agreement.
    //               </p>
    //               <br /> <br />
    //               <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 14. <br />
    //                 The costs of the preparation, drafting and printing of this
    //                 agreement in the sum of $50 shall be paid by the Purchaser.
    //               </p>
    //               <br /> <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "150%" }}
    //               >
    //                 EFFECTED BY THE SELLER AT HARARE THIS{" "}
    //               </span>{" "}
    //               <span classNameName="live agreement" style={{ color: "green" }}>
    //                 .............
    //                 {contract.purchaseDATE === undefined
    //                   ? "........"
    //                   : contract.purchaseDATE}
    //                 ...............
    //               </span>
    //               <br /> <br />
    //               <div classNameName="container">
    //                 {contract.SITEplan === undefined ? (
    //                   <a
    //                     classNameName="btns cta"
    //                     href="/images/siteplans/compliance.pdf"
    //                     id="pdf"
    //                   >
    //                     Get Site Plan
    //                   </a>
    //                 ) : (
    //                   <a
    //                     classNameName="btn cta"
    //                     href={`/images/siteplans/${contract.SITEplan}.pdf`}
    //                     id="pdf"
    //                   >
    //                     {" "}
    //                     Get Site Plan
    //                   </a>
    //                 )}
    //               </div>
    //               <br /> <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "150%" }}
    //               >
    //                 Proof of payment PDF &nbsp;
    //                 <span classNameName="live" style={{ color: "green" }}>
    //                   .............
    //                   {contract.RATESpaymentUPDATE === undefined
    //                     ? "........"
    //                     : contract.RATESpaymentUPDATE}
    //                 </span>
    //               </span>
    //               <br />
    //               <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{
    //                   textAlign: "left",
    //                   fontSize: "100%",
    //                   borderRadius: "14px",
    //                 }}
    //               >
    //                 Agreement of Sale PDF. &nbsp;
    //                 <span classNameName="live">.............................</span>
    //               </span>
    //               <br />
    //               <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "150%" }}
    //               >
    //                 Rates and tax clearance. &nbsp;
    //                 <span classNameName="live">.............................</span>
    //               </span>
    //               <br /> <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "100%" }}
    //               >
    //                 EFFECTED BY CONSTITUTIONS{" "}
    //               </span>{" "}
    //               <br /> <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "100%" }}
    //               >
    //                 <a
    //                   href="assets/HANDAC.pdf"
    //                   id="pdf"
    //                   classNameName="btn-warning"
    //                   style={{ padding: " 0.5rem" }}
    //                 >
    //                   HANDAC
    //                 </a>
    //               </span>
    //               <br /> <br />
    //               <span
    //                 classNameName="section-heading text-uppercase"
    //                 style={{ textAlign: "left", fontSize: "100%" }}
    //               >
    //                 <a
    //                   href="assets/CONSTITUTION.pdf"
    //                   id="pdf"
    //                   classNameName="btn-warning"
    //                   style={{ padding: " 0.5rem" }}
    //                 >
    //                   CROWHILL & CHARLOTTE BROOKE
    //                 </a>
    //               </span>
    //               <br /> <br />
    //               <h6
    //                 style={{
    //                   textAlign: "center",
    //                   marginTop: "3rem",
    //                   padding: "1rem",
    //                   color: "orange",
    //                 }}
    //               >
    //                 Note: Once you get an authorised site plan from us, contact
    //                 The Building Plan Administrator Georgona Table for building
    //                 plan approvals:
    //                 <br />
    //                 <span>+263 77 399 4242</span>
    //               </h6>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </>
    //   ) : error ? (
    //     <Message variant="danger">{error}</Message>
    //   ) : (
    //     <div classNameName="container-xxl py-5 category">
    //       <div classNameName="container">
    //         {/* <!-- PARTICLE SYSTEM --> */}
    //         <div classNameName="page-bg"></div>
    //         <div classNameName="animation-wrapper" style={{ zIndex: "-1" }}>
    //           <div classNameName="particle particle-1"></div>
    //           <div classNameName="particle particle-2"></div>
    //           <div classNameName="particle particle-3"></div>
    //           <div classNameName="particle particle-4"></div>
    //         </div>
    //         {/* <div>{contract}</div> */}
    //         <div classNameName="text-center wow fadeInUp" data-wow-delay="0.1s">
    //           {/* <div classNameName="input-group mb-3">
    //             <form onSubmit={submitHandler} style={{ width: "100%" }}>
    //               <input
    //                 type="text"
    //                 classNameName="form-control"
    //                 placeholder="Registration Number"
    //                 aria-label="Recipient's username"
    //                 aria-describedby="button-addon2"
    //                 style={{
    //                   marginRight: "1rem",
    //                   display: "inline",
    //                   width: "80%",
    //                 }}
    //                 id="accountNumber"
    //               />

    //               <input
    //                 type="submit"
    //                 value="Search"
    //                 classNameName="btn btn-info"
    //                 style={{ display: "inline" }}
    //               />
    //             </form>
    //           </div> */}
    //         </div>
    //         <div classNameName="row g-3">
    //           <div classNameName="col-lg-5 col-md-6">
    //             <div classNameName="row g-3">
    //               {/* <!-- SECTION 1 --> */}

    //               <div
    //                 classNameName="col-lg-12 col-md-12 wow zoomIn"
    //                 data-wow-delay="0.1s"
    //                 style={{
    //                   borderTop: "1px solid lightgrey",
    //                   boxShadow: " 0 3px 10px rgb(0 0 0 / 0.2)",
    //                   padding: " 1rem",
    //                 }}
    //               >
    //                 <h3 style={{ textAlign: "center" }}>
    //                   APPLICATION FOR APPROVAL OF PLANS OF BUILDING AND/OR
    //                   SEWERAGE WORKS
    //                 </h3>
    //                 STAND /SHARE NO:{" "}
    //                 <span classNameName="propertyDesc" style={{ color: "green" }}>
    //                   .............
    //                   {contract.STANDno === undefined
    //                     ? "........"
    //                     : contract.STANDno}
    //                   ...............
    //                 </span>{" "}
    //                 <br />
    //                 <br />
    //                 PLAN No:{" "}
    //                 <span classNameName="live planNo" style={{ color: "green" }}>
    //                   .............
    //                   {contract.pdfBUILDINGplans === undefined
    //                     ? "........"
    //                     : contract.pdfBUILDINGplans}
    //                   ...............
    //                 </span>{" "}
    //                 <br /> <br />
    //                 <p style={{ textAlign: "right" }}>For Official Use</p>
    //                 <h4 style={{ textAlign: "left" }}>
    //                   2.NAME AND MOBILE NUMBER OF SUBMITTING ARCHITECT:{" "}
    //                 </h4>{" "}
    //                 <br />
    //                 <br />
    //                 <span
    //                   classNameName="live propertyDesc"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PROPERTYdescription === undefined
    //                     ? "........"
    //                     : contract.PROPERTYdescription}
    //                   ...............
    //                 </span>{" "}
    //                 <br /> <br />
    //                 <h4 style={{ textAlign: "left" }}>3. CHARGES PAYABLE: </h4>
    //                 (a) Building Plan Fees $
    //                 <span classNameName="live ag" style={{ color: "green" }}>
    //                   .............
    //                   {contract.PLANfees === undefined
    //                     ? "........"
    //                     : contract.PLANfees}
    //                   ...............
    //                 </span>
    //                 <br /> <br />
    //                 (b) RATES CLEARANCE AND/OR DEVELOPMENT LEVY DATED
    //                 <span classNameName="live dated">
    //                   .............................
    //                 </span>
    //                 <br /> <br />
    //                 <h4 style={{ textAlign: "left" }}> 4. AREAS: </h4>
    //                 Area of Main Building on ground floor (including outside
    //                 walls)*
    //                 <span classNameName="live ah" style={{ color: "green" }}>
    //                   .............
    //                   {contract.BUILDINGsqmSIZEonGROUND === undefined
    //                     ? "........"
    //                     : contract.BUILDINGsqmSIZEonGROUND}
    //                   ...............
    //                 </span>{" "}
    //                 sq.m <br /> <br />
    //                 <p>
    //                   Total area of all floors (including outside walls)*{" "}
    //                   <span classNameName="live ai" style={{ color: "green" }}>
    //                     .............
    //                     {contract.BUILDINGtotalAREA === undefined
    //                       ? "........"
    //                       : contract.BUILDINGtotalAREA}
    //                     ...............
    //                   </span>
    //                   sq.m Area of Outbuildings (includingOutside walls)”
    //                   <span classNameName="live aj" style={{ color: "green" }}>
    //                     .............
    //                     {contract.AREAoutbuilding === undefined
    //                       ? "........"
    //                       : contract.AREAoutbuilding}
    //                     ...............
    //                   </span>{" "}
    //                   sq.m
    //                 </p>
    //                 <p>
    //                   When the building project is an addition or additions,
    //                   inset here only the area of such addition or additions
    //                 </p>
    //                 <h4 style={{ textAlign: "left" }}>
    //                   {" "}
    //                   5. BUILDING OWNER INFORMATION:{" "}
    //                   <span
    //                     classNameName="live mandateOwner"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.PURCHASER === undefined
    //                       ? "........"
    //                       : contract.PURCHASER}
    //                     ...............
    //                   </span>{" "}
    //                 </h4>{" "}
    //                 <br />
    //                 <br />
    //                 <h4 style={{ textAlign: "left" }}>
    //                   {" "}
    //                   6. INFORMATION OF REGISTERED OWNER OF THE STAND:{" "}
    //                 </h4>
    //                 <span
    //                   classNameName="live mandateOwner"
    //                   style={{ color: "green" }}
    //                 >
    //                   .............
    //                   {contract.PURCHASER === undefined
    //                     ? "........"
    //                     : contract.PURCHASER}
    //                   ...............
    //                 </span>{" "}
    //                 <br /> <br />
    //                 <h4 style={{ textAlign: "left" }}>
    //                   {" "}
    //                   7. SUPERVISION DECLARATION:{" "}
    //                 </h4>
    //                 Architect or Engineer will supervise the work
    //                 <span classNameName="live af" style={{ color: "green" }}>
    //                   .............
    //                   {contract.CONSTRCTNsupervisorDATA === undefined
    //                     ? "........"
    //                     : contract.CONSTRCTNsupervisorDATA}
    //                   ...............
    //                 </span>{" "}
    //                 <br /> <br />
    //                 <span> Date </span>
    //                 <span classNameName="live agreement" style={{ color: "green" }}>
    //                   .............
    //                   {contract.regDATE === undefined
    //                     ? "........"
    //                     : contract.regDATE}
    //                   ...............
    //                 </span>
    //                 <p style={{ textAlign: "right" }}>
    //                   <span classNameName="live al">
    //                     .............................
    //                   </span>
    //                 </p>
    //                 <p style={{ textAlign: "right" }}>
    //                   Signature of Owner of Authorised Agent
    //                 </p>{" "}
    //                 <br /> <br />
    //                 <h3 style={{ textAlign: "center" }}>
    //                   ELECTRONIC DRAWINGS TO ACCOMPANY THIS FORM
    //                 </h3>{" "}
    //                 <br /> <br />
    //                 <p style={{ textAlign: "left" }}>
    //                   a. All plans to be submitted in PDF FORMAT.
    //                 </p>
    //                 <p style={{ textAlign: "left" }}>
    //                   b. Block Plan to a SCALE of 1:500 with metric dimensions,
    //                   one copy to be coloured.
    //                 </p>
    //                 <p style={{ textAlign: "left" }}>
    //                   c. Working drawing, plans sections and elevations, to a
    //                   scale of 1:100 metric, one copy to be coloured.
    //                 </p>
    //                 <p style={{ textAlign: "left" }}>
    //                   d. Where applicable Structural Engineer's drawings and
    //                   calculations and structural certificate
    //                 </p>
    //                 <p>
    //                   NOTE: Refence should be made to the City of HARARE Model
    //                   building By Laws 1977 for full details of drawings
    //                   required to be submitted.
    //                 </p>
    //                 <br /> <br />
    //                 <h3 style={{ textAlign: "left" }}>ENQUIRIES TO:</h3>
    //               </div>

    //               {/* <!-- SECTION 2 --> */}
    //               <div
    //                 classNameName="col-lg-6 col-md-12 wow zoomIn"
    //                 data-wow-delay="0.3s"
    //                 style={{
    //                   borderTop: "1px solid lightgrey",
    //                   boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //                   padding: "1rem",
    //                 }}
    //               >
    //                 <h4
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "center" }}
    //                 >
    //                   RATES CLEARANCE CERTIFICATE
    //                 </h4>
    //                 <br /> <br />
    //                 <h4
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "center" }}
    //                 >
    //                   <span
    //                     classNameName="live address2Pur"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.RATESreceiptNo === undefined
    //                       ? "........"
    //                       : contract.RATESreceiptNo}
    //                     ...............
    //                   </span>{" "}
    //                   OWNERS ASSOCIATION
    //                 </h4>
    //                 <p style={{ textAlign: "center" }}>
    //                   I{" "}
    //                   <span
    //                     classNameName="live chairman"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.CHAIRMAN === undefined
    //                       ? "........"
    //                       : contract.CHAIRMAN}
    //                     ...............
    //                   </span>
    //                   , in my capacity as{" "}
    //                   <span
    //                     classNameName="live address2Pur"
    //                     style={{ color: "green" }}
    //                   >
    //                     ...........CHAIRMAN.............
    //                   </span>
    //                 </p>
    //                 <br /> <br />
    //                 <p style={{ textAlign: "center" }}>
    //                   <span classNameName="live mandateOwner">
    //                     .............................
    //                   </span>
    //                 </p>{" "}
    //                 <br /> <br />
    //                 <p>
    //                   Have completed and lodged the form of acceptance and have
    //                   compiled with the necessary formalities to entitle them to
    //                   Membership of the Association.
    //                 </p>{" "}
    //                 <br /> <br />
    //                 <h4
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "center" }}
    //                 >
    //                   The Property:-
    //                 </h4>
    //                 <p>
    //                   An undivided{" "}
    //                   <span classNameName="live share" style={{ color: "green" }}>
    //                     .............
    //                     {contract.PROPERTYdescription === undefined
    //                       ? "........"
    //                       : contract.PROPERTYdescription}
    //                     ...............
    //                   </span>
    //                   Share being
    //                   <span
    //                     classNameName="live propertyDesc"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.AREAsqm === undefined
    //                       ? "........"
    //                       : contract.AREAsqm}
    //                     ...............
    //                   </span>
    //                   piece of land situated in the District of Salisbury called
    //                   Rumani of Borrowdale Estate
    //                 </p>
    //                 <p>
    //                   Signed at Harare this
    //                   <span classNameName="live dated">
    //                     .............................
    //                   </span>
    //                 </p>
    //                 <p>
    //                   {" "}
    //                   <span
    //                     classNameName="live chairman"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.CHAIRMAN === undefined
    //                       ? "........"
    //                       : contract.CHAIRMAN}
    //                     ...............
    //                   </span>{" "}
    //                   (Chairman)
    //                 </p>
    //                 <p>
    //                   PS: Current Rates Reciept Number:{" "}
    //                   <span
    //                     classNameName="live propertyDesc"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.RATESpaymentUPDATE === undefined
    //                       ? "........"
    //                       : contract.RATESpaymentUPDATE}
    //                     ...............
    //                   </span>
    //                 </p>
    //                 <p>
    //                   Development Receipt Number:
    //                   <span
    //                     classNameName="live propertyDesc"
    //                     style={{ color: "green" }}
    //                   >
    //                     .............
    //                     {contract.DEVlevyPAYMENTupdate === undefined
    //                       ? "........"
    //                       : contract.DEVlevyPAYMENTupdate}
    //                     ...............
    //                   </span>
    //                 </p>
    //               </div>
    //               {/* <!-- SECTION 2 End --> */}

    //               {/* <!-- SECTION 3 --> */}
    //               <div
    //                 classNameName="col-lg-6 col-md-12 wow zoomIn"
    //                 data-wow-delay="0.5s"
    //                 style={{
    //                   borderTop: "1px solid lightgrey",
    //                   boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //                   padding: "1rem",
    //                 }}
    //               >
    //                 <h4
    //                   classNameName="section-heading"
    //                   style={{ textAlign: "center" }}
    //                 >
    //                   RUMANI ESTATES (PVT) LTD
    //                 </h4>

    //                 <p>22 February 2022</p>

    //                 <p>The Administrator</p>

    //                 <p>Goromonzi Rural District Council</p>
    //                 <h4
    //                   classNameName="section-heading"
    //                   style={{
    //                     textAlign: "left",
    //                     textDecorationColor: "underline",
    //                   }}
    //                 >
    //                   RUWA
    //                 </h4>

    //                 <p>Dear Sir/Madam</p>

    //                 <h4
    //                   classNamename="section-heading"
    //                   style={{
    //                     textAlign: "left",
    //                     textDecorationColor: "underline",
    //                   }}
    //                 >
    //                   RE: CONFIRMATION OF STAND NUMBER IN RESPECT OF{" "}
    //                   <span classNameName="live deposit" style={{ color: "green" }}>
    //                     .............
    //                     {contract.PROPERTYdescription === undefined
    //                       ? "........"
    //                       : contract.PROPERTYdescription}
    //                     ...............
    //                   </span>
    //                 </h4>

    //                 <p>The above matter refers.</p>

    //                 <p>
    //                   Please be advised that{" "}
    //                   <span classNameName="live deposit" style={{ color: "green" }}>
    //                     .............
    //                     {contract.STANDno === undefined
    //                       ? "........"
    //                       : contract.STANDno}
    //                     ...............
    //                   </span>{" "}
    //                   registered to{" "}
    //                   <span classNameName="live deposit" style={{ color: "green" }}>
    //                     .............
    //                     {contract.PURCHASER === undefined
    //                       ? "........"
    //                       : contract.PURCHASER}
    //                     ...............
    //                   </span>
    //                   Under Deed of Transfer Number 9180/2003 is Stand Number
    //                   719 at Rumani Estate, Charlottes Brooke.
    //                 </p>

    //                 <p>
    //                   Thank you. <br />
    //                   <span classNameName="live deposit" style={{ color: "green" }}>
    //                     .............
    //                     {contract.CHAIRMAN === undefined
    //                       ? "........"
    //                       : contract.CHAIRMAN}
    //                     ...............
    //                   </span>
    //                 </p>

    //                 <h4
    //                   classNamename="section-heading"
    //                   style={{
    //                     textAlign: "center",
    //                     textDecorationColor: "underline",
    //                   }}
    //                 >
    //                   FOR AND ON BEHALF OF RUMANI ESTATES
    //                 </h4>
    //               </div>
    //               {/* <!-- SECTION 3 END --> */}
    //             </div>
    //           </div>

    //           {/* <!-- END SECTION 4 --> */}

    //           <div
    //             classNameName="col-lg-7 col-md-6 wow zoomIn"
    //             data-wow-delay="0.7s"
    //             style={{
    //               minHeight: "200px",
    //               borderTop: "1px solid lightgrey",
    //               boxShadow: "0 3px 10px rgb(0 0 0 / 0.2)",
    //               padding: "1rem",
    //             }}
    //           >
    //             <h4 classNameName="mb-3" style={{ textAlign: "center" }}>
    //               MEMORANDUM OF AGREEMENT OF SALE
    //             </h4>
    //             <h6
    //               classNameName="bg-white text-center px-3"
    //               style={{ textAlign: "center" }}
    //             >
    //               MADE AND ENTERED INTO BY AND BETWEEN{" "}
    //             </h6>
    //             <h3
    //               classNameName="section-heading"
    //               style={{ color: "grey", textAlign: "center" }}
    //             >
    //               HOUSEFIN (PVT) LTD REPRESENTED BY
    //             </h3>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               {" "}
    //               <span classNameName="live estateAgent" style={{ color: "green" }}>
    //                 .............
    //                 {contract.ESTATEagent === undefined
    //                   ? "........"
    //                   : contract.ESTATEagent}
    //                 ...............
    //               </span>{" "}
    //               of No. 1499 CG Msipa drive, Cleverhills, Crowhill Harare
    //               Zimbabwe (Hereinafter referred to as the Seller)
    //               <span
    //                 classNameName="live mandateOwner"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PURCHASER === undefined
    //                   ? "........"
    //                   : contract.PURCHASER}
    //                 ...............
    //               </span>
    //               .
    //             </p>
    //             <br />
    //             <h4 classNameName="section-heading" style={{ textAlign: "center" }}>
    //               And
    //             </h4>
    //             <p style={{ textAlign: "center" }}>
    //               <span classNameName="live purchaser">
    //                 ........... .............
    //                 {contract.MANDATEowner === undefined
    //                   ? "........"
    //                   : contract.MANDATEowner}
    //                 .................................
    //               </span>
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               (hereinafter referred to as “the Purchasers”), of the other
    //               part, WHEREAS the seller is the registered owner of certain
    //               piece of land situate in the district of Salisbury measuring{" "}
    //               <span classNameName="live area" style={{ color: "green" }}>
    //                 .............
    //                 {contract.AREAsqm === undefined
    //                   ? "........"
    //                   : contract.AREAsqm}
    //                 ...............
    //               </span>
    //               square meters called{" "}
    //               <span
    //                 classNameName="live propertyDesc"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PROPERTYdescription === undefined
    //                   ? "........"
    //                   : contract.PROPERTYdescription}
    //                 ...............
    //               </span>{" "}
    //               (hereinafter referred to as “the property”);
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               AND WHEREAS the seller is desirous of selling the property to
    //               the Purchaser who has agreed to purchase the same; AND WHEREAS
    //               the parties hereto have agreed to enter into this agreement
    //               for the purpose of recording their business transaction;
    //             </p>
    //             <h4 classNameName="section-heading" style={{ textAlign: "center" }}>
    //               NOW, THEREFORE, IT IS HEREBY RECORDED THAT THE PARTIES HERETO
    //               HAVE AGREED AS FOLLOWS
    //             </h4>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               1. <br />
    //               The effective date of this agreement shall be date of its
    //               final signature by the parties.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               2. <br />
    //               The seller hereby sells and the Purchasers hereby purchase the
    //               property
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               3. <br />
    //               The purchase price for the property with all amounts
    //               denominated and payable in currency notes of
    //               <span
    //                 classNameName="live sellingCurrency"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PURCHASEcurrency === undefined
    //                   ? "........"
    //                   : contract.PURCHASEcurrency}
    //                 ...............
    //               </span>{" "}
    //               is the sum of
    //               <span
    //                 classNameName="live sellingPrice"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PURCHASEprice === undefined
    //                   ? "........"
    //                   : contract.PURCHASEprice}
    //                 ...............
    //               </span>{" "}
    //               and the same shall be payable as follows:
    //               <br />
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               3.1.{" "}
    //               <span classNameName="live sellingCurrency">
    //                 .... .............
    //                 {contract.PURCHASEcurrency === undefined
    //                   ? "........"
    //                   : contract.PURCHASEcurrency}
    //                 .....................
    //               </span>
    //               <span classNameName="live initialDep">
    //                 .................
    //                 {contract.STANDno === undefined
    //                   ? "........"
    //                   : contract.STANDno}
    //                 .....................
    //               </span>{" "}
    //               <br />
    //               3.2.{" "}
    //               <span
    //                 classNameName="live sellingCurrency"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PURCHASEcurrency === undefined
    //                   ? "........"
    //                   : contract.PURCHASEcurrency}
    //                 ...............
    //               </span>
    //               <span classNameName="live deposit" style={{ color: "green" }}>
    //                 .............
    //                 {contract.DEPOSIT === undefined
    //                   ? "........"
    //                   : contract.DEPOSIT}
    //                 ...............
    //               </span>{" "}
    //               Deposit <br />
    //               3.3.{" "}
    //               <span
    //                 classNameName="live sellingCurrency"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PURCHASEcurrency === undefined
    //                   ? "........"
    //                   : contract.PURCHASEcurrency}
    //                 ...............
    //               </span>
    //               <span classNameName="live installments">
    //                 .............
    //                 {contract.INSTALMENTS === undefined
    //                   ? "........"
    //                   : contract.INSTALMENTS}
    //                 ...............
    //               </span>{" "}
    //               Installment Monthly Installments for
    //               <span
    //                 classNameName="live installmentsMonths"
    //                 style={{ color: "green" }}
    //               >
    //                 .............
    //                 {contract.PERIODofPAYMENT === undefined
    //                   ? "........"
    //                   : contract.PERIODofPAYMENT}
    //                 ...............
    //               </span>
    //               months.
    //             </p>
    //             <br /> <br />
    //             <h4 classNameName="section-heading" style={{ textAlign: "left" }}>
    //               Amounts to be paid:
    //             </h4>
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               &nbsp;&nbsp;
    //               <span classNameName="live">
    //                 {" "}
    //                 .............
    //                 {contract.RATESpaymentUPDATE === undefined
    //                   ? "........"
    //                   : contract.RATESpaymentUPDATE}
    //                 ...............
    //               </span>{" "}
    //               <br />
    //               <br />
    //               <h5 classNameName="section-heading" style={{ textAlign: "left" }}>
    //                 Or to the following account:
    //               </h5>
    //               &nbsp;&nbsp; A/C Name:{" "}
    //               <span classNameName="live">.............................</span>{" "}
    //               <br />
    //               &nbsp;&nbsp; Bank :{" "}
    //               <span classNameName="live">
    //                 .............................
    //               </span>{" "}
    //               <br />
    //               &nbsp;&nbsp; Sort Code :{" "}
    //               <span classNameName="live">
    //                 .............................
    //               </span>{" "}
    //               <br />
    //               &nbsp;&nbsp; Acc No.:{" "}
    //               <span classNameName="live">
    //                 .............................
    //               </span>{" "}
    //               <br />
    //               &nbsp;&nbsp; IBAN:{" "}
    //               <span classNameName="live">
    //                 .............................
    //               </span>{" "}
    //               <br />
    //               &nbsp;&nbsp; Swift code:{" "}
    //               <span classNameName="live">
    //                 .............................
    //               </span>{" "}
    //               <br />
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               4. <br />
    //               Transfer of the property from the seller to the purchasers
    //               shall be effected by
    //               <span classNameName="live conveyancer" style={{ color: "green" }}>
    //                 .............
    //                 {contract.CONVEYANCER === undefined
    //                   ? "........"
    //                   : contract.CONVEYANCER}
    //                 ...............
    //               </span>
    //               In this respect the purchasers shall pay all transfer charges
    //               levied by the conveyancers.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               5. <br />
    //               The Sellers shall be responsible for the payment of Value
    //               Added Tax being{" "}
    //               <span classNameName="live tax" style={{ color: "green" }}>
    //                 ...........(15%)..................
    //               </span>{" "}
    //               of the purchase price.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               6. <br />
    //               The Purchaser hereby authorizes the release of the purchase
    //               price to the seller before transfer of the property to the
    //               Purchasers.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               7. <br />
    //               The Agent`s commission and in respect of this sale shall be{" "}
    //               <span
    //                 classNameName="live  buildingARCHT"
    //                 style={{ color: "green" }}
    //               >
    //                 ...........(7.5%).................
    //               </span>{" "}
    //               of the gross amount paid by the Purchasers including interest
    //               and shall be payable by the seller. The transfer of the
    //               property shall not be registered unless and until the Agent`s
    //               commission on this sale shall have been paid or secured to the
    //               satisfaction of Crowhill Estate Township and the seller hereby
    //               authorizes the said Agent to deduct the commission from the
    //               purchase money when the same becomes payable to him.
    //               Commission shall be deemed to be earned on the date of the
    //               last signature to the agreement. The parties to this agreement
    //               acknowledge that the commission shall be deducted from the
    //               purchasers’ deposit. Should any party to this Agreement breach
    //               the terms of such agreement causing the agreement to be
    //               cancelled, the defaulting party shall be liable for the
    //               agent`s commission.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               8. <br />
    //               The Purchasers shall take vacant possession and occupation of
    //               the property upon payment of the full purchase price.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               9. <br />
    //               The risk and profit in the property hereby sold shall pass
    //               from the Seller to the Purchasers on the date on which vacant
    //               possession is given to the Purchasers or the date on which
    //               transfer is passed to the Purchasers, whichever shall be the
    //               sooner, and from that date the Purchasers shall be entitled to
    //               all rents and other profits (if any) accruing from the said
    //               property and shall be liable for all rates and taxes and other
    //               imposts levied thereon and all other expenses of whatsoever
    //               nature in respect of the said property.
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               10. <br />
    //               The Purchasers acknowledge that they have made themselves
    //               fully acquainted with the property, with any servitude or
    //               servitudes, lease or leases to which it is subject, with its
    //               nature, extent, boundaries, beacons and locality and with all
    //               the terms and conditions imposed by the Town Planning
    //               Authority or any other Board, body, authority or person
    //             </p>
    //             <p classNameName="section-heading" style={{ textAlign: "center" }}>
    //               11. <br />
    //               In the event that either party (hereinafter referred to as
    //               “the breaching party”) commits a material breach of any
    //               obligation or warranty owed or given in terms of this
    //               agreement to the other party (hereinafter referred to as “the
    //               aggrieved party”) and remains in default for either 7 (seven)
    //               days following despatch by the aggrieved party to the
    //               breaching party of a registered letter requiring remedy of
    //               such default or for 3 (three) days following delivery to the
    //               breaching party of a letter from the aggrieved party requiring
    //               remedy of such default, the aggrieved party shall have the
    //               immediate right:-
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               (a) either to enforce the aggrieved party’s rights in terms of
    //               this agreement and to claim payment or performance of whatever
    //               may be overdue or requisite, together with interest on any
    //               amount of money owed at the commercial bank prime lending rate
    //               from the date of default until the date of payment, with all
    //               interest accruals being capitalised monthly on the first day
    //               of each month
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               (b) or to cancel this agreement and claim such damages as have
    //               been caused by the breach and/or cancellation (together with
    //               interest on such damages at the aforesaid rate, capitalised as
    //               aforesaid);
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               (c) or, if it is the Purchaser in breach, the seller shall
    //               demand forthwith his exclusive right of occupation of the
    //               property;
    //               <br />
    //               and, in addition, the aggrieved party shall be entitled to
    //               institute legal proceedings in the High Court of Zimbabwe and
    //               to obtain summary judgement and recover legal costs on the
    //               Attorney-Client scale and any collection commission properly
    //               charged by the aggrieved party`s legal practitioners(s).
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               12. <br />
    //               This agreement represents the entire contract between the
    //               parties. No purported amendment or waiver of or addition to
    //               any provision hereof or amendment or waiver of any notice
    //               given in terms hereof or collateral or replacement agreement
    //               in relation to the subject matter hereof shall be of any force
    //               or effect unless and until reduced to writing in a document or
    //               series of documents, duly signed by the parties, each before
    //               2(two) witnesses.
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               13. The seller and the Purchasers each choose domicilium
    //               citandi et executandi at the address given after their
    //               respective names in the preamble to this agreement.
    //             </p>
    //             <br /> <br />
    //             <p classNameName="section-heading" style={{ textAlign: "left" }}>
    //               14. <br />
    //               The costs of the preparation, drafting and printing of this
    //               agreement is 0.5% of the selling price, and shall be paid by
    //               the Purchaser.
    //             </p>
    //             <br /> <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "150%" }}
    //             >
    //               EFFECTED BY THE SELLER AT HARARE THIS{" "}
    //             </span>{" "}
    //             <span classNameName="live agreement" style={{ color: "green" }}>
    //               .............
    //               {contract.purchaseDATE === undefined
    //                 ? "........"
    //                 : contract.purchaseDATE}
    //               ...............
    //             </span>
    //             <br /> <br />
    //             <div classNameName="container">
    //               {contract.SITEplan === undefined ? (
    //                 <a
    //                   classNameName="btn cta"
    //                   href="/images/siteplans/compliance.pdf"
    //                   id="pdf"
    //                 >
    //                   Get Site Plan
    //                 </a>
    //               ) : (
    //                 <a
    //                   classNameName="btn cta"
    //                   href={`/images/siteplans/${contract.SITEplan}.pdf`}
    //                   id="pdf"
    //                 >
    //                   {" "}
    //                   Get Site Plan
    //                 </a>
    //               )}
    //             </div>
    //             <br /> <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "150%" }}
    //             >
    //               Proof of payment PDF &nbsp;
    //               <span classNameName="live" style={{ color: "green" }}>
    //                 .............
    //                 {contract.RATESpaymentUPDATE === undefined
    //                   ? "........"
    //                   : contract.RATESpaymentUPDATE}
    //               </span>
    //             </span>
    //             <br />
    //             <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{
    //                 textAlign: "left",
    //                 fontSize: "100%",
    //                 borderRadius: "14px",
    //               }}
    //             >
    //               Agreement of Sale PDF. &nbsp;
    //               <span classNameName="live">.............................</span>
    //             </span>
    //             <br />
    //             <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "150%" }}
    //             >
    //               Rates and tax clearance. &nbsp;
    //               <span classNameName="live">.............................</span>
    //             </span>
    //             <br /> <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "100%" }}
    //             >
    //               EFFECTED BY CONSTITUTIONS{" "}
    //             </span>{" "}
    //             <br /> <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "100%" }}
    //             >
    //               <a
    //                 href="assets/HANDAC.pdf"
    //                 id="pdf"
    //                 classNameName="btn-warning"
    //                 style={{ padding: " 0.5rem" }}
    //               >
    //                 HANDAC
    //               </a>
    //             </span>
    //             <br /> <br />
    //             <span
    //               classNameName="section-heading text-uppercase"
    //               style={{ textAlign: "left", fontSize: "100%" }}
    //             >
    //               <a
    //                 href="assets/CONSTITUTION.pdf"
    //                 id="pdf"
    //                 classNameName="btn-warning"
    //                 style={{ padding: " 0.5rem" }}
    //               >
    //                 CROWHILL & CHARLOTTE BROOKE
    //               </a>
    //             </span>
    //             <br /> <br />
    //             <h6
    //               style={{
    //                 textAlign: "center",
    //                 marginTop: "3rem",
    //                 padding: "1rem",
    //                 color: "orange",
    //               }}
    //             >
    //               Note: Once you get an authorised site plan from us, contact
    //               The Building Plan Administrator Georgona Table for building
    //               plan approvals:
    //               <br />
    //               <span>+263 77 399 4242</span>
    //             </h6>
    //           </div>
    //           {/* <!-- END SECTION 4 --> */}

    //           {/* <!-- END COMMENT SECTION --> */}
    //         </div>
    //       </div>
    //     </div>
    //   )}
    // </div>
  );
};

export default ContractScreen;
