import {
  JEWORDER_CREATE_REQUEST,
  JEWORDER_CREATE_SUCCESS,
  JEWORDER_CREATE_FAIL,
  JEWORDER_DETAILS_REQUEST,
  JEWORDER_DETAILS_SUCCESS,
  JEWORDER_DETAILS_FAIL,
  JEWORDER_PAY_REQUEST,
  JEWORDER_PAY_FAIL,
  JEWORDER_PAY_SUCCESS,
  JEWORDER_PAY_RESET,
  JEWORDER_LIST_MY_REQUEST,
  JEWORDER_LIST_MY_SUCCESS,
  JEWORDER_LIST_MY_FAIL,
  JEWORDER_LIST_MY_RESET,
  JEWORDER_LIST_FAIL,
  JEWORDER_LIST_SUCCESS,
  JEWORDER_LIST_REQUEST,
  JEWORDER_DELIVER_FAIL,
  JEWORDER_DELIVER_SUCCESS,
  JEWORDER_DELIVER_REQUEST,
  JEWORDER_DELIVER_RESET,
  JEWORDER_CREATE_RESET,
} from "../constants/jewsorderConstants";

export const jeworderCreateReducer = (state = {}, action) => {
  switch (action.type) {
    case JEWORDER_CREATE_REQUEST:
      return {
        loading: true,
      };
    case JEWORDER_CREATE_SUCCESS:
      return {
        loading: false,
        success: true,
        jeworderItems: action.payload,
      };
    case JEWORDER_CREATE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case JEWORDER_CREATE_RESET:
      return {};
    default:
      return state;
  }
};

export const jeworderDetailsReducer = (
  state = { loading: true, jeworderItems: [], jewshippingAddress: {} },
  action
) => {
  switch (action.type) {
    case JEWORDER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case JEWORDER_DETAILS_SUCCESS:
      return {
        loading: false,
        jeworderItems: action.payload,
      };
    case JEWORDER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const jeworderPayReducer = (state = {}, action) => {
  switch (action.type) {
    case JEWORDER_PAY_REQUEST:
      return {
        loading: true,
      };
    case JEWORDER_PAY_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case JEWORDER_PAY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case JEWORDER_PAY_RESET:
      return {};
    default:
      return state;
  }
};

export const jeworderDeliverReducer = (state = {}, action) => {
  switch (action.type) {
    case JEWORDER_DELIVER_REQUEST:
      return {
        loading: true,
      };
    case JEWORDER_DELIVER_SUCCESS:
      return {
        loading: false,
        success: true,
      };
    case JEWORDER_DELIVER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case JEWORDER_DELIVER_RESET:
      return {};
    default:
      return state;
  }
};

export const jeworderListMyReducer = (
  state = { jeworderItems: [] },
  action
) => {
  switch (action.type) {
    case JEWORDER_LIST_MY_REQUEST:
      return {
        loading: true,
      };
    case JEWORDER_LIST_MY_SUCCESS:
      return {
        loading: false,
        jeworderItems: action.payload,
      };
    case JEWORDER_LIST_MY_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case JEWORDER_LIST_MY_RESET:
      return { jeworderItems: [] };
    default:
      return state;
  }
};

export const jeworderListReducer = (state = { jeworderItems: [] }, action) => {
  switch (action.type) {
    case JEWORDER_LIST_REQUEST:
      return {
        loading: true,
      };
    case JEWORDER_LIST_SUCCESS:
      return {
        loading: false,
        jeworderItems: action.payload,
      };
    case JEWORDER_LIST_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
