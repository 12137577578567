import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Provider as AlertProvider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { UserContextProvider } from "./context/userContext";
import { Auth0Provider } from '@auth0/auth0-react';

const options = {
  position: "top center",
  timeout: 12000,
  offset: "30px",
  transition: "scale",
};

// const auth0Config = {
//   domain: 'dev-p31ciya5dsgdlkqx.us.auth0.com',
//   clientId: 'I8kMuHnxKmqfSyHzZvV9tQRSQDUVrg1w',
//   redirectUri: window.location.origin + "/portal",
//   audience: 'https://made-in-africa-mining-intiative-api.com',
//   scope: 'openid profile email'
// };

ReactDOM.render(
  <Auth0Provider
    domain="dev-p31ciya5dsgdlkqx.us.auth0.com"
    clientId="I8kMuHnxKmqfSyHzZvV9tQRSQDUVrg1w"
    authorizationParams={{
      redirect_uri: window.location.origin+"/commodity/garlpex",
      audience: "https://made-in-africa-mining-intiative-api.com",
    }}
  >
    <AlertProvider template={AlertTemplate} {...options}>
      <Provider store={store}>
        <UserContextProvider>
          <App />  {/* Render App directly */}
        </UserContextProvider>
      </Provider>
    </AlertProvider>
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();