import React from "react";
import styled from "styled-components";

export default function Card({ image, url, isActive }) {
  return (
    <CardWrapper href={url} isActive={isActive} tabIndex={isActive ? 0 : -1}>
      <img src={image.src} alt={image.alt} />
    </CardWrapper>
  );
}

const CardWrapper = styled.a`
  margin-left: 20px;
  opacity: ${(props) => (props.isActive ? 1 : 0.5)};
  img {
    width: 300px;
    height: 300px;
    padding: 2px;
    object-fit: cover;
    border-radius: 10%;
    border: ${(props) => (props.isActive ? `10px solid purple` : `none`)};
  }
`;
