import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Container } from "react-bootstrap";


import HomeScreen from "./screens/HomeScreen";
import ShopScreen from "./screens/ShopScreen";
import ProductScreen from "./screens/ProductScreen";
import MegajewScreen from "./screens/MegajewScreen";
import CartScreen from "./screens/CartScreen";
import JewsCartScreen from "./screens/JewsCartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ProfileScreen from "./screens/ProfileScreen";
import AdminScreen from "./screens/AdminScreen";
import MyAccountScreen from "./screens/MyAccountScreen";
import ShippingScreen from "./screens/ShippingScreen";
import JewsShippingScreen from "./screens/JewsShippingScreen";
import PaymentScreen from "./screens/PaymentScreen";
import JewPaymentScreen from "./screens/JewPaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import JewPlaceOrderScreen from "./screens/JewPlaceOrderScreen";
import OrderScreen from "./screens/OrderScreen";
import JewsOrderScreen from "./screens/JewsOrderScreen";
import UserListScreen from "./screens/UserListScreen";
import UserEditScreen from "./screens/UserEditScreen";
import ProductListScreen from "./screens/ProductListScreen";
import MegajewListScreen from "./screens/MegajewListScreen";
import ProductEditScreen from "./screens/ProductEditScreen";
import MegajewEditScreen from "./screens/MegajewEditScreen";
import OrderListScreen from "./screens/OrderListScreen";
import AccountListScreen from "./screens/AccountListScreen";
import JewOrderListScreen from "./screens/JewOrderListScreen";
import HousingScreen from "./screens/HousingScreen";
import ContractScreen from "./screens/ContractScreen";
import MegajouleAxisScreen from "./screens/MegajouleAxisScreen";
import GrowthSeekingScreen from "./screens/GrowthSeekingScreen";
import BankingHallScreen from "./screens/BankingHallScreen";
import AgroPowerScreen from "./screens/AgroPowerScreen";
import PlansApprovalScreen from "./screens/PlansApprovalScreen";
import DashboardPScreen from "./screens/DashboardPScreen";
import MyProfileScreen from "./screens/MyProfileScreen";
import AuthenticationScreen from "./screens/AuthenticationScreen";
import SyndicateScreen from "./screens/SyndicateScreen";
import MapScreen from "./screens/MapScreen";
import BazaarScreen from "./screens/BazaarScreen";
import { SyndicateProvider } from "./context/syndicateContext";
import "./App.css";
import TradingHallScreen from "./screens/TradingHallScreen";
import PaymentResultPage from "./screens/PaymentResultPage";
import PortalScreen from "./screens/PortalScreen";
import DocusignScreen from "./screens/DocusignScreen";
import ProtectedRoute from "./screens/ProtectedRoute";

const App = () => {
  return (
    <SyndicateProvider>
      <Router>
        <Switch>
          <Route path="/portal" component={PortalScreen} />
          <Route path="/order/:id" component={OrderScreen} />
          <Route path="/jeworders/:id" component={JewsOrderScreen} />
          <Route path="/paymentresult" component={PaymentResultPage} />
          <Route path="/shipping" component={PaymentResultPage} />
          <Route path="/jewshipping" component={JewsShippingScreen} />
          <Route path="/paynowresult" component={PaymentScreen} />
          <Route path="/jewpayment" component={JewPaymentScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/jewplaceorder" component={JewPlaceOrderScreen} />
          <Route path="/syndicatePage" component={SyndicateScreen} />
          <Route path="/bazaar/page" component={BazaarScreen} />
          <Route path="/login" component={AuthenticationScreen} />
          <Route path="/register" component={RegisterScreen} />
          <Route path="/profile" component={DashboardPScreen} />
          <Route path="/myprofile" component={MyProfileScreen} />
          <Route path="/tradinghall" component={TradingHallScreen} />
          <Route path="/partner" component={AdminScreen} />
          <Route path="/myaccount" component={MyAccountScreen} />
          <Route path="/commodity/agropower" component={AgroPowerScreen} />
          <Route path="/commodity/megajouleaxis" component={MegajouleAxisScreen} />

          <Route path="/commodity/garlpex" component={GrowthSeekingScreen} />
          <Route path="/docusign" component={DocusignScreen} />
          <Route path="/product/:id" component={ProductScreen} />
          <Route path="/product" component={ProductScreen} />
          <Route path="/megajew/:id" component={MegajewScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
          <Route path="/cart" component={CartScreen} />
          <Route path="/jewscart/:id?" component={JewsCartScreen} />
          <Route path="/admin/userlist" component={UserListScreen} />
          <Route path="/admin/user/:id/edit" component={UserEditScreen} />
          <Route path="/admin/productlist" component={ProductListScreen} exact />

          <Route path="/admin/megajewlist" component={MegajewListScreen} exact />

          <Route
            path="/admin/productlist/:pageNumber"
            component={ProductListScreen}
            exact
          />

          <Route path="/admin/product/:id/edit" component={ProductEditScreen} />
          <Route path="/admin/megajew/:id/edit" component={MegajewEditScreen} />
          <Route path="/admin/orderlist" component={OrderListScreen} />
          <Route path="/admin/listAccounts" component={AccountListScreen} />
          <Route path="/admin/jeworderlist" component={JewOrderListScreen} />
          <Route path="/search/:keyword" component={HomeScreen} exact />
          <Route path="/page/:pageNumber" component={HomeScreen} exact />
          <Route path="/shopping" component={ShopScreen} exact />
          <Route
            path="/search/:keyword/page/:pageNumber"
            component={HomeScreen}
            exact
          />
          <Route path="/" component={HomeScreen} exact />
          <Route path="/housing" component={HousingScreen} exact />
          <Route path="/contract" component={ContractScreen} exact />
          <Route path="/compliance" component={ContractScreen} exact />
          {/* <Route path="/banking" component={BankingHallScreen} exact /> */}
          <ProtectedRoute exact path="/banking" component={BankingHallScreen} />
          <Route path="/maping" component={MapScreen} exact />
          {/* BankingHallScreen */}
          <Route path="/uploadplan" component={PlansApprovalScreen} exact />
        </Switch>
      </Router>
    </SyndicateProvider>
  );
};

export default App;
