// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
 
import {getAuth} from'firebase/auth'
import {getFirestore} from '@firebase/firestore'
import {getStorage} from 'firebase/storage'
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB99O9SkAdWCCxieIuCD_gPaM7Zi2p_oiE",
  authDomain: "miami-auth-58a82.firebaseapp.com",
  projectId: "miami-auth-58a82",
  storageBucket: "miami-auth-58a82.appspot.com",
  messagingSenderId: "521157926307",
  appId: "1:521157926307:web:e43fe466439a1cba7e0e16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
export const auth = getAuth(app);
export const db = getFirestore(app)
export const storage=getStorage(app)