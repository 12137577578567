import React, { createContext, useState } from "react";
import { create } from 'zustand'
import axios from "axios";


export const useAccount = create((set) => ({
    assets: [],
    explode: [],
    consumedAssets: [],
    loans: [],
    recoveredAssets: [],
    loading: false,
    error: null,

    getAssets: async ({ accountNumber }) => {
        set({ loading: true });
        set({ error: null });
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(`/api/wallet`, { phone: accountNumber }, config);
            if (data) {
                set({ loading: false });
                set({ assets: data.assets })
                set({ explode: data.decompose })
                set({ consumedAssets: data.consumedAssets })
                set({ loans: data.loans })
                set({ recoveredAssets: data.recoveredAssets })
            } else {
                set({ loading: false });
                set({ loading: false });
                set({ assets: [] })
                set({ explode: [] })
                set({ consumedAssets: [] })
                set({ loans: [] })
                set({ recoveredAssets: [] })
            }
        } catch (error) {
            set({ error: error })
        }
    },
    addAsset: async ({ userPhone, product, itemCategory, quantity, description, urll, megajoule_equivalnce }) => {
        set({ loading: true });
        set({ error: null });
        const config = {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
            },
        };

        try {
            const { data } = await axios.post(`/api/wallet/addAsset`, {
                phone: userPhone,
                asset: product,
                assetCategory: itemCategory,
                quantity: quantity,
                description: description,
                imageUrl: urll,
                megajoule_equivalnce: megajoule_equivalnce
            }, config);
            
            if (data) {
                set({ loading: false });
                set({ assets: data.assets })
                set({ explode: data.decompose })
                set({ consumedAssets: data.consumedAssets })
                set({ loans: data.loans })
                set({ recoveredAssets: data.recoveredAssets })
            } else {
                set({ loading: false });
                set({ loading: false });
                set({ assets: [] })
                set({ explode: [] })
                set({ consumedAssets: [] })
                set({ loans: [] })
                set({ recoveredAssets: [] })
            }
        } catch (error) {
            set({ error: error })
        }
    }
}));
