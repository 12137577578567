import React, { useState, useEffect } from "react";
import axios from "axios";
import { PayPalButton } from "react-paypal-button-v2";
import { Link } from "react-router-dom";
import { Row, Col, ListGroup, Image, Card, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { Footer, Possibility, Features, Header, Products } from "../containers";
import { CTA, Brand, Navbar, Slider } from "../components";
import {
  getOrderDetails,
  payOrder,
  deliverOrder,
} from "../actions/orderActions";
import {
  ORDER_PAY_RESET,
  ORDER_DELIVER_RESET,
} from "../constants/orderConstants";

const OrderScreen = ({ match, history }) => {
  const orderId = match.params.id;

  const [sdkReady, setSdkReady] = useState(false);

  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderDeliver = useSelector((state) => state.orderDeliver);
  const { loading: loadingDeliver, success: successDeliver } = orderDeliver;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * item.qty, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    // const addPayPalScript = async () => {
    //   const { data: clientId } = await axios.get("/api/config/paypal");
    //   const script = document.createElement("script");
    //   script.type = "text/javascript";
    //   script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
    //   script.async = true;
    //   script.onload = () => {
    //     setSdkReady(true);
    //   };
    //   document.body.appendChild(script);
    // };

    if (!order || successPay || successDeliver || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });
      dispatch({ type: ORDER_DELIVER_RESET });
      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      // if (!window.paypal) {
      //   // addPayPalScript();
      // } else {
      //   setSdkReady(true);
      // }
    }
  }, [dispatch, orderId, successPay, successDeliver, order]);

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult);
    dispatch(payOrder(orderId, paymentResult));
  };

  const deliverHandler = () => {
    dispatch(deliverOrder(order));
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <Navbar />
      <div classNameName="order">
        <h1 style={{ color: "orange", margin: "2rem" }}>
          Order Number :<span style={{ color: "lightgrey" }}>{order._id}</span>
        </h1>
        <Row>
          <Col md={8}>
            <ListGroup variant="flush">
              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2 style={{ color: "orange", padding: "1rem" }}>
                  Personal Details
                </h2>
                <p style={{ padding: "1rem" }}>
                  <strong>Name: </strong> {order.user.name}
                </p>
                <p style={{ padding: "1rem" }}>
                  <strong>Email: </strong>{" "}
                  <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
                </p>
                <p style={{ padding: "1rem" }}>
                  <strong>Address:</strong>
                  {order.shippingAddress.address}, {order.shippingAddress.city}{" "}
                  {order.shippingAddress.postalCode},{" "}
                  {order.shippingAddress.country}
                </p>
                {order.isDelivered ? (
                  <Message variant="success">
                    Delivered on {order.deliveredAt}
                  </Message>
                ) : (
                  <Message variant="danger">Not Delivered</Message>
                )}
              </ListGroup.Item>

              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2 style={{ color: "orange", padding: "1rem" }}>
                  Payment Method
                </h2>
                <p style={{ padding: "1rem" }}>
                  <strong>Method: </strong>
                  {order.paymentMethod}
                </p>
                {order.isPaid ? (
                  <Message variant="success">Paid on {order.paidAt}</Message>
                ) : (
                  <Message variant="danger">Not Paid</Message>
                )}
              </ListGroup.Item>

              <ListGroup.Item classNameName="detail-card bounce-in-top">
                <h2 style={{ color: "orange", padding: "1rem" }}>
                  Order Items
                </h2>
                {order.orderItems.length === 0 ? (
                  <Message>Order is empty</Message>
                ) : (
                  <ListGroup variant="flush">
                    {order.orderItems.map((item, index) => (
                      <ListGroup.Item key={index}>
                        <Row>
                          <Col md={1}>
                            <Image
                              src={item.image}
                              alt={item.name}
                              fluid
                              rounded
                            />
                          </Col>
                          <Col>
                            <Link to={`/product/${item.product}`}>
                              {item.name}
                            </Link>
                          </Col>
                          <Col md={4}>
                            {item.qty} x{" "}
                            <span style={{ color: "green", fontSize: "small" }}>
                              MJT :
                            </span>
                            &nbsp;{item.price} ={" "}
                            <img
                              src="/images/coins.png"
                              alt="gold icon"
                              style={{
                                width: "10%",
                              }}
                            />{" "}
                            &nbsp;{" "}
                            <span style={{ color: "green", fontSize: "small" }}>
                              MJT :
                            </span>
                            &nbsp;{item.qty * item.price}
                          </Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <Card classNameName="detail-card bounce-in-top">
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2 style={{ padding: "1rem" }}>Order Summary</h2>
                </ListGroup.Item>
                <ListGroup.Item classNameName="detail-card bounce-in-top">
                  <Row>
                    <Col>Items</Col>
                    <Col>
                      {" "}
                      <img
                        src="/images/coins.png"
                        alt="gold icon"
                        style={{
                          width: "10%",
                        }}
                      />{" "}
                      &nbsp;{" "}
                      <span style={{ color: "green", fontSize: "small" }}>
                        MJT :
                      </span>
                      &nbsp;
                      {order.itemsPrice}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Shipping</Col>
                    <Col>
                      <img
                        src="/images/coins.png"
                        alt="gold icon"
                        style={{
                          width: "10%",
                        }}
                      />{" "}
                      &nbsp;{" "}
                      <span style={{ color: "green", fontSize: "small" }}>
                        MJT :
                      </span>
                      &nbsp;{order.shippingPrice}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Tax</Col>
                    <Col>
                      <img
                        src="/images/coins.png"
                        alt="gold icon"
                        style={{
                          width: "10%",
                        }}
                      />{" "}
                      &nbsp;{" "}
                      <span style={{ color: "green", fontSize: "small" }}>
                        MJT :
                      </span>
                      &nbsp;{order.taxPrice}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Total</Col>
                    <Col>
                      <img
                        src="/images/coins.png"
                        alt="gold icon"
                        style={{
                          width: "10%",
                        }}
                      />{" "}
                      &nbsp;{" "}
                      <span style={{ color: "green", fontSize: "small" }}>
                        MJT :
                      </span>
                      &nbsp;{order.totalPrice}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {!order.isPaid && (
                  <ListGroup.Item>
                    {loadingPay && <Loader />}
                    <Loader />
                    <p>
                      To pay <br />
                      Send a transction code to :{" "}
                      <span
                        style={{
                          color: "green",
                        }}
                      >
                        +263 714 928 516
                      </span>{" "}
                    </p>

                    <p>
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "green",
                        }}
                      >
                        #
                      </span>
                      phoneNumber
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "green",
                        }}
                      >
                        ,
                      </span>
                      amount or Total MJT of Order
                      <span
                        style={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          color: "green",
                        }}
                      >
                        ,
                      </span>
                      last 5 characters of Order Number
                    </p>

                    <p>
                      Example :{" "}
                      <span
                        style={{
                          color: "green",
                        }}
                      >
                        #+263712345678,1000.6733,ef854723
                      </span>
                    </p>
                  </ListGroup.Item>
                )}
                {loadingDeliver && <Loader />}
                {userInfo &&
                  userInfo.isAdmin &&
                  order.isPaid &&
                  !order.isDelivered && (
                    <ListGroup.Item>
                      <Button
                        type="button"
                        classNameName="btn btn-block"
                        onClick={deliverHandler}
                      >
                        Mark As Delivered
                      </Button>
                    </ListGroup.Item>
                  )}
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default OrderScreen;
